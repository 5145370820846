import "styled-components/macro";
import { Form } from "../../../components/Form";
import UploadAvatar from "../../../components/UploadAvatar";
import { useAuthors } from "../../report/components/EditCard";
import Select from "react-select";

const BasicInfo = ({ companyProfile, setCompanyProfile }) => {
  const handleChange = event => {
    let key = event.target.name;
    let value = event.target.value;
    setCompanyProfile({ ...companyProfile, [key]: value });
  };
  const handleSelectChange = e => {
    setCompanyProfile({ ...companyProfile, customerManager: e.value });
  };

  const authors = useAuthors();
  if (typeof authors === "undefined") return <></>;
  const datalist = authors.map(i => {
    return { label: `${i.name} <${i.email}>`, value: i.email };
  });

  const handleSubmit = event => {
    event.preventDefault();
    setCompanyProfile(companyProfile);
  };

  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
        .card {
          box-shadow: 0px 4px 12px rgba(181, 190, 207, 0.5);
          border-radius: 8px;
          background: #fff;
          margin-top: 20px;
          margin-bottom: 20px;
          > div {
            display: flex;
            padding-left: 15px;
            padding-right: 15px;
          }
        }
        .cardHeader {
          height: 64px;
          background: #fff;
          align-items: center;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          border-bottom: 1px solid rgba(181, 190, 207, 0.5);
          h2 {
            font-size: 18px;
            font-weight: 500;
          }
        }
        .cardContent {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          padding-top: 40px;
          padding-bottom: 100px;
          padding-left: 10px;
          padding-right: 10px;
          background: rgba(181, 190, 207, 0.15);
        }
      `}
    >
      <div className="card">
        <div className="cardHeader">
          <h2>Yleiset tiedot</h2>
        </div>
        <Form onSubmit={handleSubmit}>
          <div className="cardContent">
            <div
              css={`
                display: grid;
                grid-template-columns: 1fr 2fr 1fr;
                width: 100%;
                max-width: 1000px;
                grid-gap: 16px;
                margin: 0 auto;
                > div {
                  display: flex;
                  align-items: center;
                }
                .logo {
                  margin-top: 20px;
                  margin-bottom: 30px;
                  img {
                    max-width: 160px;
                    max-height: 100%;
                  }
                  .FormDropzone {
                    width: 200px;
                  }
                  .FormDropzone.imgUploaded {
                    background: none;
                    border: none;
                  }
                  .FormDropzone .removeBtn {
                    margin-top: 10px;
                    color: orange;
                    background: none;
                  }
                }
                span {
                  display: flex;
                  align-items: center;
                }
                input {
                  color: #3b4a68;
                }
                .defaultBtn {
                  width: 200px;
                }
                .select-container .select__control {
                  width: 100%;
                }
              `}
            >
              <div>Logo</div>
              <div className="logo">
                <img src={companyProfile.logoUrl} alt="" />
                <UploadAvatar
                  Key={companyProfile.logoUrl}
                  onChange={logoUrl => {
                    setCompanyProfile({ ...companyProfile, logoUrl });
                  }}
                />
              </div>
              <div />
              <span>Nimi</span>
              <input
                type="text"
                name="name"
                onChange={handleChange}
                value={companyProfile.name}
              />
              <span />

              <span>Polku</span>
              <input
                type="text"
                name="slug"
                onChange={handleChange}
                value={companyProfile.slug}
              />
              <span />
              <span>Markkinointistrategia</span>
              <input
                type="text"
                name="linkMarkkinointistrategia"
                placeholder="https://"
                onChange={handleChange}
                value={companyProfile.linkMarkkinointistrategia}
              />
              <span />
              <span>Asiakasvastaava</span>
              <Select
                className="select-container"
                classNamePrefix="select"
                options={datalist}
                value={datalist.find(
                  ({ value }) => value === companyProfile.customerManager
                )}
                onChange={e => handleSelectChange(e)}
                placeholder="Valitse..."
              />
              <span />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default BasicInfo;
