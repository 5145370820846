import React from "react";
import Quill from "quill";
import Delta from "quill-delta";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { createGlobalStyle, css } from "styled-components/macro";
import deepEqual from "deep-equal";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";

export const QuillContent: React.FC<{ delta: string | any[] | undefined }> = ({
  delta,
}) => {
  const __html = React.useMemo(
    () =>
      new QuillDeltaToHtmlConverter(
        typeof delta === "string" ? [{ insert: delta }] : delta || [],
        {}
      ).convert(),
    [delta]
  );
  return (
    <div
      css={`
        word-break: break-word;
      `}
      dangerouslySetInnerHTML={{ __html }}
    />
  );
};

const CustomQuillStyles = createGlobalStyle`${css`
  .ql-toolbar.ql-snow {
    border: none;
    padding: 8px 0;
  }
  .ql-container.ql-snow {
    border: none;
  }
  .ql-container {
    box-shadow: none;
    > .ql-editor {
      padding: 0.5em 0.5em;
      min-height: 6em;
      border: 1px solid var(--bc, var(--border));
      border-radius: 4px;
      &:focus {
        border-color: var(--active);
        box-shadow: 0 0 0 1px var(--active);
        transition: 180ms box-shadow ease-in-out;
        outline: 3px solid transparent;
      }
      &:disabled {
        --input-border: #ccc;
        background-color: #eee;
        cursor: not-allowed;
      }
    }
    outline: none;
    font-size: inherit;
    font-family: inherit;
    background-color: #fff;
  }
  .ql-editor.ql-blank::before {
    font-style: normal;
    left: 10px;
  }
`}`;

const QuillEditor: React.FC<{
  placeholder?: string;
  value: string | any[];
  onChange: (arg0: { target: { value: any[] | undefined } }) => void;
}> = ({ placeholder, value, onChange }) => {
  const divRef = React.useRef<HTMLDivElement>(null);

  const editorRef = React.useRef<any>(null);
  React.useLayoutEffect(() => {
    if (editorRef.current !== null) return;
    const editor = new Quill(divRef.current!, {
      placeholder,
      theme: "snow",
    });
    if (typeof value === "string")
      editor.setContents(new Delta([{ insert: value }]));
    else editor.setContents(new Delta(value));
    editor.root.setAttribute("spellcheck", "false");
    editor.on("text-change", () => {
      if (deepEqual(editor.getContents().ops, [{ insert: "\n" }])) {
        onChange({ target: { value: undefined } });
        return;
      }
      onChange({ target: { value: editor.getContents().ops } });
    });
    editorRef.current = editor;
    return () => {};
  }, [value, onChange, placeholder]);

  return (
    <>
      <div>
        <div ref={divRef} />
      </div>
      <CustomQuillStyles />
    </>
  );
};

export default QuillEditor;
