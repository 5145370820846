import React, { useEffect } from "react";
import "styled-components/macro";
import theme from "../../../styles/theme";

const MenuContents = props => {
  useEffect(() => {
    const entries = entries => {
      entries.forEach(entry => {
        const id = entry.target.getAttribute("id");

        document
          .querySelector(`a[href="#${id}"]`)
          .parentElement.classList.toggle(
            "active",
            entry.intersectionRatio > 0
          );
      });
    };

    let intersectionObserver = new IntersectionObserver(entries);

    document.querySelectorAll("section[id]").forEach(section => {
      intersectionObserver.observe(section);
    });

    return () => {
      intersectionObserver.disconnect();
    };
  }, []);

  return (
    <aside
      css={`
        flex: 2;
        min-width: 220px;
        height: 100%;
        padding-left: 20px;
        > ul {
          position: sticky;
          top: 40px;
        }
        ${theme.tablet} {
          display: none;
        }
      `}
    >
      <ul
        css={`
          padding-left: 0;
          list-style: none;
          > li {
            border-left: 2px solid #b5becf;
            transition: all 0.2s ease-in-out;
          }
          > li.active {
            border-color: #3b4a68;
            transition: all 0.5s ease-in-out;
          }
          > li.active a {
            transition: all 0.5s ease-in-out;
            opacity: 1;
          }
          a {
            padding: 9px 5px;
            padding-left: 12px;
            display: block;
            font-family: hero-new, sans-serif;
            font-size: 14px;
            line-height: 21px;
            text-decoration: none;
            color: #3b4a68;
            opacity: 0.95s;
          }
        `}
      >
        {props.allLinks.map((item, index) => (
          <React.Fragment key={index}>
            <li>
              <a href={`#${item}`}>{item}</a>
            </li>
          </React.Fragment>
        ))}
      </ul>
    </aside>
  );
};

export default MenuContents;
