import "styled-components/macro";
import { Form } from "../../components/Form";

const ReadPermissions = ({ readPermissions, setReadPermissions }) => {
  const handleRemove = item => {
    if (!window.confirm("Haluatko varmasti poistaa lukuoikeuden?")) return;
    setReadPermissions(readPermissions.filter(link => link !== item));
  };

  const handleNewItem = e => {
    e.preventDefault();
    setReadPermissions([
      ...readPermissions,
      {
        email: "",
      },
    ]);
  };

  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
        .card {
          box-shadow: 0px 4px 12px rgba(181, 190, 207, 0.5);
          border-radius: 8px;
          background: #fff;
          margin-top: 20px;
          margin-bottom: 20px;
          background: #f4f5f8;

          > div {
            display: flex;
            padding-left: 15px;
            padding-right: 15px;
          }
        }
        .cardHeader {
          height: 64px;
          background: #fff;
          align-items: center;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          border-bottom: 1px solid rgba(181, 190, 207, 0.5);
          h2 {
            font-size: 18px;
            font-weight: 500;
          }
        }
        .cardContent {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          padding-top: 40px;
          padding-bottom: 100px;
          padding-left: 10px;
          padding-right: 10px;
          max-width: 1000px;
          margin: 0 auto;
        }
        .defaultBtn {
          margin-top: 10px;
        }
      `}
    >
      <div className="card">
        <div className="cardHeader">
          <h2>Katseluoikeudet</h2>
        </div>
        <Form>
          <div className="cardContent">
            <Form>
              <div
                css={`
                  display: flex;
                  flex-direction: column;
                  border-radius: 8px;
                  .row {
                    display: flex;
                    align-items: center;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    line-height: 20px;
                    > div {
                      padding-right: 10px;
                    }
                    > div:nth-child(1) {
                      flex-basis: 420px;
                    }
                    > div:nth-child(2) {
                      flex-basis: 30px;
                    }
                  }
                  .row:first-child {
                    font-weight: 400;
                    span {
                      font-size: 15px;
                    }
                  }
                  .row > div:first-child {
                    padding-left: 0;
                  }
                  .item {
                    padding-top: 8px;
                    padding-bottom: 8px;
                    input {
                      width: 100%;
                      color: #3b4a68;
                    }
                  }

                  .remove {
                    width: 34px;
                    height: 34px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                  .remove:hover {
                    background: rgba(181, 190, 207, 0.3);
                    cursor: pointer;
                  }
                `}
              >
                {readPermissions.length > 0 && (
                  <div className="row">
                    <div>
                      <span>Sähköposti</span>
                    </div>
                    <div></div>
                  </div>
                )}
                {readPermissions.map((item, index) => {
                  return (
                    <div className="row item">
                      <div>
                        <input
                          type="text"
                          name="email"
                          value={item.email}
                          autoFocus={item.email === "" ? true : false}
                          onChange={e => {
                            setReadPermissions(
                              readPermissions.map(v =>
                                v === item
                                  ? { ...v, [e.target.name]: e.target.value }
                                  : v
                              )
                            );
                          }}
                        />
                      </div>

                      <div>
                        <div
                          className="remove"
                          onClick={() => handleRemove(item)}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z"
                              fill="#3B4A68"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div>
                <button
                  type="submit"
                  className="defaultBtn blueOutlines"
                  onClick={e => handleNewItem(e)}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
                      fill="white"
                    />
                  </svg>
                  Lisää sähköposti
                </button>
              </div>
            </Form>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ReadPermissions;
