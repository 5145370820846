import React from "react";
import "styled-components/macro";
import styled from "styled-components";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

const MarkkinointiIndeksiWidget = ({
  data,
  markIndexDescription,
  globalData,
}) => {
  // Get Active questions
  const activeAnswers = data.filter(item => item.isActive);

  const questions = activeAnswers.map(item => {
    const globalQuestions = globalData.questions.find(
      globalItem => globalItem.id === item.id
    );

    return { ...globalQuestions, ...item };
  });

  const maxPoints = questions.reduce((a, b) => a + (+b.maxPoints || 0), 0);

  const pointsOfUndefinedFields = questions.reduce(
    (a, b) =>
      a + (b.value === "" || typeof b.value === "undefined" ? +b.maxPoints : 0),
    0
  );

  const totalPoints = questions.reduce((a, b) => a + (+b.value || 0), 0);
  const value = totalPoints / (maxPoints - pointsOfUndefinedFields);
  let indicatorColor = "";
  if (value >= 0.66) {
    indicatorColor = "0, 234, 157";
  } else if (value < 0.66 && value >= 0.33) {
    indicatorColor = "252, 184, 22";
  } else {
    indicatorColor = "255, 59, 74";
  }

  return (
    <>
      <div
        css={`
          background: #3b4a68;
          margin-top: 30px;
          box-shadow: 0px 4px 12px rgba(59, 74, 104, 0.5);
          border-radius: 8px;
          padding: 30px 20px;
          p {
            margin-top: 30px;
            text-align: center;
          }
        `}
      >
        <ProgressBar
          value={isNaN(value) ? 0 : value}
          color={isNaN(value) ? "192,192,192" : indicatorColor}
        >
          <div className="progressBarActive"></div>
          {!isNaN(value) && <h3>{Math.round(value * 100)} %</h3>}
        </ProgressBar>
        <p>{markIndexDescription?.description}</p>
      </div>
      <MarkkinointiIndexContent>
        <Accordion
          className="accordion"
          allowMultipleExpanded={true}
          allowZeroExpanded={true}
        >
          {globalData.sections.map(section => {
            const questionsForSection = questions.filter(
              question => question.parent?.id === section.id
            );
            const maxPointsForSection = questionsForSection.reduce(
              (a, b) => a + (+b.maxPoints || 0),
              0
            );
            const pointsForSection = questionsForSection.reduce(
              (a, b) => a + (+b.value || 0),
              0
            );

            let colorForSection = "";
            const ratio = pointsForSection / maxPointsForSection;
            if (ratio >= 0.66) {
              colorForSection = "0, 234, 157";
            } else if (ratio < 0.66 && ratio >= 0.33) {
              colorForSection = "252, 184, 22";
            } else {
              colorForSection = "255, 59, 74";
            }

            const sectionHasUndefinedQuestions = questionsForSection.some(
              i => i.value === "" || typeof i.value === "undefined"
            );

            if (sectionHasUndefinedQuestions) colorForSection = "192, 192, 192";
            if (questionsForSection.length === 0) return null;

            return (
              <AccordionItem key={section.id} className="accordionItem">
                <AccordionItemHeading className="accordionHeading">
                  <AccordionItemButton className="accordionButton">
                    <div
                      className="colorIndicator"
                      style={{ background: `rgb(${colorForSection})` }}
                    />
                    {section.title}
                    <div className="arrow">
                      <AccordionItemState>
                        {state => {
                          return state.expanded ? (
                            <ArrowDown />
                          ) : (
                            <ArrowRight />
                          );
                        }}
                      </AccordionItemState>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="accordionPanel">
                  {questionsForSection.map(question => {
                    let colorForQuestion = "";
                    const ratio = question.value / question.maxPoints;
                    if (ratio >= 0.66) {
                      colorForQuestion = "0, 234, 157";
                    } else if (ratio < 0.66 && ratio >= 0.33) {
                      colorForQuestion = "252, 184, 22";
                    } else {
                      colorForQuestion = "255, 59, 74";
                    }

                    const questionIsUndefined =
                      question.value === "" ||
                      typeof question.value === "undefined"
                        ? true
                        : false;
                    if (questionIsUndefined) colorForQuestion = "192, 192, 192";

                    return (
                      <React.Fragment key={question.question}>
                        <div className="questionRow">
                          <div
                            className="colorIndicator"
                            style={{ background: `rgb(${colorForQuestion})` }}
                          />
                          {question.question}
                          <span className="points">
                            {question.value || "– "}/{question.maxPoints}
                          </span>
                          <br />
                        </div>
                        {question.comment && (
                          <span className="comment">{question.comment}</span>
                        )}
                      </React.Fragment>
                    );
                  })}
                </AccordionItemPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </MarkkinointiIndexContent>
    </>
  );
};

export default MarkkinointiIndeksiWidget;

const MarkkinointiIndexContent = styled.div`
  margin-top: 20px;
  background: #3b4a68;
  box-shadow: 0px 4px 12px rgba(59, 74, 104, 0.5);
  font-family: hero-new, sans-serif;
  border-radius: 8px;
  overflow: hidden;

  .accordion {
    //border-radius: 8px;
  }
  .accordionItem:not(:last-child) {
    border-bottom: 1px solid rgba(189, 190, 207, 0.4);
  }
  .colorIndicator {
    display: inline-flex;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 15px;
  }
  .questionRow {
    width: 100%;
    display: inline-flex;
    align-items: center;
    padding: 10px;
    font-size: 14px;
  }
  .comment {
    display: inline-flex;
    padding-left: 41px;
    padding-bottom: 10px;
    opacity: 0.6;
    font-size: 14px;
  }
  .questionRow .colorIndicator {
    width: 16px;
    height: 16px;
  }
  .points {
    opacity: 0.4;
    font-size: 12px;
    margin-left: auto;
  }
  .accordionHeading {
    display: inline-flex;
    align-items: center;
    width: 100%;
    font-weight: 500;
  }
  .arrow {
    margin-left: auto;
  }
  .accordionButton {
    padding: 10px;
    display: inline-flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    outline: none;
  }
  .accordionPanel {
    background: #53607f;
  }
`;

const ProgressBar = styled.div`
  background: rgba(${p => p.color}, 0.25);
  border-radius: 8px;
  overflow: hidden;
  height: 40px;
  position: relative;
  h3 {
    color: #fff;
    position: absolute;
    top: 8px;
    right: 16px;
    margin: 0;
    font-size: 24px;
  }
  .progressBarActive {
    height: 40px;
    background: rgb(${p => p.color});
    ${p =>
      p.value === 1
        ? "border-radius: 8px;"
        : "border-radius: 8px 20px 20px 8px;"}
    width: ${p => p.value * 100}%;
  }
`;

const ArrowDown = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3004 9.3C17.7004 9.7 17.7004 10.3 17.3004 10.7L12.7004 15.3C12.3004 15.7 11.7004 15.7 11.3004 15.3L6.70039 10.7C6.30039 10.3 6.30039 9.7 6.70039 9.3C7.10039 8.9 7.70039 8.9 8.10039 9.3L12.0004 13.2L15.9004 9.3C16.3004 8.9 16.9004 8.9 17.3004 9.3Z"
        fill="white"
      />
    </svg>
  );
};

const ArrowRight = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.3 6.69961C9.7 6.29961 10.3 6.29961 10.7 6.69961L15.3 11.2996C15.7 11.6996 15.7 12.2996 15.3 12.6996L10.7 17.2996C10.3 17.6996 9.7 17.6996 9.3 17.2996C8.9 16.8996 8.9 16.2996 9.3 15.8996L13.2 11.9996L9.3 8.09961C8.9 7.69961 8.9 7.09961 9.3 6.69961Z"
        fill="white"
      />
    </svg>
  );
};
