import React, { useState } from "react";
import "styled-components/macro";
import theme from "../../../../styles/theme";
import ModalImage from "react-modal-image";
import Modal from "../../../components/Modal";
import EditCard from "./EditCard";
import { UploadedImageKeyToURL } from "./EditCard";
import Discussion from "./Discussion";

const CardPicture = ({
  isSplitted,
  data,
  dragHandleProps,
  index,
  removeHandler,
  saveChanges,
  customerSlug,
  readOnly,
}) => {
  const [showModal, setShowModal] = useState(false);
  function onClose() {
    setShowModal(false);
  }

  return (
    <>
      <UploadedImageKeyToURL Key={data.pictures[0].Key}>
        {({ url, width, height }) => {
          const ratio = width / height;
          let padding;

          if (ratio < 0.8) {
            padding = "24vw";
          } else if (ratio < 1.2) {
            padding = "18vw";
          } else if (ratio < 2.5) {
            padding = "10vw";
          } else {
            padding = "0";
          }
          return (
            <>
              <div
                {...dragHandleProps}
                css={`
                  margin: 0 auto;
                  display: flex;
                  max-width: 984px;
                  flex-direction: column;
                  text-align: center;
                  background: #ffffff;
                  box-shadow: 0px 4px 12px rgba(59, 74, 104, 0.5);
                  border-radius: 8px;
                `}
              >
                <div
                  onClick={e => {
                    e.preventDefault();
                    if (readOnly) return;
                    if (
                      e.target.tagName === "A" ||
                      e.target.classList.contains("preventEditOnClick") ||
                      e.target.classList.contains(
                        "__react_modal_image__medium_img"
                      )
                    ) {
                      return;
                    } else {
                      {
                        let el = e.target;
                        while (el !== null) {
                          if (el.classList?.contains("preventEditOnClick"))
                            return;
                          el = el.parentNode;
                        }
                      }
                      setShowModal(true);
                    }
                  }}
                  css={`
                    cursor: ${readOnly ? "auto" : "pointer !important"};
                  `}
                >
                  <div
                    css={`
                      display: flex;
                      padding-right: 20px;
                      padding-top: 6px;
                      padding-bottom: 6px;
                      align-items: center;
                      background: #f4f5f8;
                      border-radius: 8px 8px 0px 0px;
                      flex-wrap: wrap;
                    `}
                  >
                    <h3
                      css={`
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 20px;
                        color: #3b4a68;
                        margin-left: 20px;
                      `}
                    >
                      {data.title}
                    </h3>
                    {data.pictures[0].url && (
                      <a
                        className="preventEditOnClick"
                        css={`
                          margin-left: auto;
                          padding-left: 10px;
                          padding-right: 10px;
                          background: #ebecf1;
                          border-radius: 8px;
                          height: 32px;
                          display: flex;
                          align-items: center;
                          text-decoration: none;
                          &:hover {
                            background: #ffffff;
                            box-shadow: 0px 3px 6px rgba(59, 74, 104, 0.2);
                            transition: all 0.2s ease-in-out;
                          }
                        `}
                        href={data.pictures[0].url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span
                          className="preventEditOnClick"
                          css={`
                            font-family: "hero-new", sans-serif;
                            font-weight: 600;
                            font-size: 14px;
                            color: ${theme.WDSDarkBlueGrey};
                            margin-right: 10px;
                          `}
                        >
                          {data.pictures[0].title}
                        </span>
                        <svg
                          className="preventEditOnClick"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19 18V13C19 12.5 19.5 12 20 12C20.5 12 21 12.5 21 13V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3H11C11.5 3 12 3.5 12 4C12 4.6 11.5 5 11 5H6C5.4 5 5 5.4 5 6V18C5 18.6 5.4 19 6 19H18C18.6 19 19 18.6 19 18ZM15 3H20C20.5 3 21 3.5 21 4V9C21 9.6 20.5 10 20 10C19.4 10 19 9.6 19 9V6.4L9.9 15.5C9.5 15.9 8.9 15.9 8.4 15.5C8 15.1 8 14.5 8.4 14L17.6 5H15C14.4 5 14 4.5 14 4C14 3.5 14.4 3 15 3Z"
                            fill="#3B4A68"
                          />
                        </svg>
                      </a>
                    )}
                  </div>
                  <div
                    css={`
                      padding-left: ${padding};
                      padding-right: ${padding};
                      ${theme.mobile} {
                        padding-left: 0;
                        padding-right: 0;
                      }
                    `}
                  >
                    <ModalImage
                      className="preventEditOnClick"
                      small={url}
                      large={url}
                    />
                  </div>
                  <div style={{ height: 24 }} />
                  <Discussion
                    customerSlug={customerSlug}
                    id={`card-${data.id}`}
                  />
                </div>
              </div>
              {showModal && (
                <Modal onClose={onClose}>
                  <EditCard
                    intention="editCard"
                    data={data}
                    index={index}
                    saveChanges={saveChanges}
                    removeHandler={removeHandler}
                    closeModal={onClose}
                    customerSlug={customerSlug}
                  />
                </Modal>
              )}
            </>
          );
        }}
      </UploadedImageKeyToURL>
    </>
  );
};

export default CardPicture;
