import React, { useState } from "react";
import { css } from "styled-components/macro";
import theme from "../../../../styles/theme";
import Modal from "../../../components/Modal";
import { QuillContent } from "../../../components/Quill";
import EditCard, { UploadedImageKeyToURL } from "./EditCard";
import { LoadAuthor } from "../../../account/components/DataLoaders";
import Discussion from "./Discussion";

const Card = ({
  isSplitted,
  data,
  dragHandleProps,
  index,
  removeHandler,
  saveChanges,
  customerSlug,
  readOnly,
}) => {
  const [showModal, setShowModal] = useState(false);
  function onClose() {
    setShowModal(false);
  }

  return (
    <>
      <div
        {...dragHandleProps}
        css={`
          margin: 0 auto;
          display: flex;
          max-width: 984px;
          flex-direction: column;
          text-align: left;
          background: #ffffff;
          box-shadow: 0px 4px 12px rgba(59, 74, 104, 0.5);
          border-radius: 8px;
        `}
      >
        <div
          onClick={e => {
            if (readOnly) return;
            if (
              e.target.tagName === "A" ||
              e.target.classList.contains("preventEditOnClick")
            ) {
              return;
            }
            {
              let el = e.target;
              while (el !== null) {
                if (el.classList?.contains("preventEditOnClick")) return;
                el = el.parentNode;
              }
            }
            e.preventDefault();
            setShowModal(true);
          }}
          css={`
            cursor: ${readOnly ? "auto" : "pointer !important"};
          `}
        >
          <div
            css={`
              display: flex;
              align-items: center;
              background: #f4f5f8;
              height: 64px;
              border-radius: 8px 8px 0px 0px;
            `}
          >
            <h3
              css={`
                font-weight: 600;
                font-size: 18px;
                line-height: 20px;
                color: #3b4a68;
                margin-left: 20px;
              `}
            >
              {data.title}
            </h3>
          </div>
          {typeof data.author !== "undefined" &&
            typeof data.author.email === "undefined" && (
              <div
                css={`
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  padding: 20px 0 6px;
                  font-family: hero-new;
                `}
              >
                <div
                  css={`
                    min-width: 88px;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                  `}
                >
                  <img
                    css={`
                      border-radius: 50%;
                      width: 48px;
                    `}
                    src={data.author.picture?.url}
                    alt={data.author.name}
                  />
                </div>
                <div
                  css={`
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                  `}
                >
                  <span
                    css={`
                      display: inline-block;
                      width: 100%;
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 20px;
                      color: #3b4a68;
                    `}
                  >
                    {data.author.name}
                  </span>
                  <span
                    css={`
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 20px;
                      color: #3b4a68;
                      opacity: 0.6;
                    `}
                  >
                    {data.author.title}
                  </span>
                </div>
              </div>
            )}
          {typeof data.author !== "undefined" &&
            typeof data.author.email !== "undefined" && (
              <LoadAuthor
                authorEmail={data.author.email}
                customerSlug={customerSlug}
              >
                {({ name, title, avatarUrl }) => (
                  <>
                    <div
                      css={`
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        padding: 20px 0 6px;
                        font-family: hero-new;
                      `}
                    >
                      <div
                        css={`
                          min-width: 88px;
                          display: flex;
                          align-items: flex-start;
                          justify-content: center;
                        `}
                      >
                        {avatarUrl !== null ? (
                          <UploadedImageKeyToURL Key={avatarUrl}>
                            {({ url }) => (
                              <div
                                css={`
                                  display: block;
                                  width: 48px;
                                  height: 48px;
                                  background: url(${url});
                                  background-position: 50% 50%;
                                  background-size: cover;
                                  background-repeat: no-repeat;
                                  margin-left: 8px;
                                  margin-right: 8px;
                                  border-radius: 50%;
                                `}
                              />
                            )}
                          </UploadedImageKeyToURL>
                        ) : (
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M16 8C16 10.21 14.21 12 12 12C9.79004 12 8 10.21 8 8C8 5.78998 9.79004 4 12 4C14.21 4 16 5.78998 16 8ZM4 18C4 15.34 9.33008 14 12 14C14.6699 14 20 15.34 20 18V20H4V18Z"
                              fill={theme.blue}
                              fillOpacity="0.34"
                            />
                          </svg>
                        )}
                      </div>

                      <div
                        css={`
                          display: flex;
                          justify-content: center;
                          flex-direction: column;
                        `}
                      >
                        <span
                          css={`
                            display: inline-block;
                            width: 100%;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 20px;
                            color: #3b4a68;
                          `}
                        >
                          {name}
                        </span>
                        <span
                          css={`
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 20px;
                            color: #3b4a68;
                            opacity: 0.6;
                          `}
                        >
                          {title}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </LoadAuthor>
            )}
          <div
            css={`
              width: 100%;
              text-align: left;
            `}
          >
            <div
              css={`
                font-family: Roboto;
                font-style: normal;
                margin-left: 88px;
                padding-right: 20px;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                color: #3b4a68;
                transition: all 0.2s ease-in-out;
                ${theme.mobile} {
                  margin-left: 20px;
                }
                ${isSplitted &&
                css`
                  ${theme.splitScreen} {
                    margin-left: 20px;
                  }
                `}
              `}
            >
              <QuillContent delta={data.content} />
              <div style={{ marginBottom: 32 }} />
            </div>
            <Discussion customerSlug={customerSlug} id={`card-${data.id}`} />
          </div>
        </div>
      </div>
      {showModal && (
        <Modal onClose={onClose}>
          <EditCard
            intention="editCard"
            data={data}
            index={index}
            saveChanges={saveChanges}
            removeHandler={removeHandler}
            closeModal={onClose}
            customerSlug={customerSlug}
          />
        </Modal>
      )}
    </>
  );
};

export default Card;
