import "styled-components/macro";
import { Form } from "../../../components/Form";
import Select from "react-select";
import short from "short-uuid";
import { Link } from "react-router-dom";

const Gauges = ({ gauges, setGauges, mittaritGlobal }) => {
  const typeOptions = mittaritGlobal
    .map(i => {
      return { value: i.id, label: `${i.title}: ${i.unit} | ${i.type}` };
    })
    .reverse();

  const handleChange = (event, item) => {
    let key = event.target.name;
    let value = event.target.value;
    setGauges(
      gauges.map(gauge => (gauge === item ? { ...gauge, [key]: value } : gauge))
    );
  };

  const handleTypeChange = (selection, item) => {
    const typeId = selection.value;

    setGauges(
      gauges.map(gauge =>
        gauge === item ? { ...item, useGlobalGauge: typeId } : gauge
      )
    );
  };

  const handleRemove = item => {
    if (!window.confirm("Haluatko varmasti poistaa mittarin?")) return;
    let arr = gauges.filter(gauge => gauge !== item);
    setGauges(arr);
  };

  const handleNewItem = e => {
    e.preventDefault();
    const id = short.generate();
    setGauges([
      ...gauges,
      {
        id,
        useGlobalGauge: mittaritGlobal[mittaritGlobal.length - 1].id,
        otsikko: "",
        min: "",
        max: "",
        alateksti: "",
      },
    ]);
  };

  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
        .card {
          box-shadow: 0px 4px 12px rgba(181, 190, 207, 0.5);
          border-radius: 8px;
          background: #fff;
          margin-top: 20px;
          margin-bottom: 20px;
          background: #f4f5f8;

          > div {
            display: flex;
            padding-left: 15px;
            padding-right: 15px;
          }
        }
        .cardHeader {
          height: 64px;
          background: #fff;
          align-items: center;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          border-bottom: 1px solid rgba(181, 190, 207, 0.5);
          h2 {
            font-size: 18px;
            font-weight: 500;
          }
        }
        .cardContent {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          padding-top: 40px;
          padding-bottom: 40px;
          padding-left: 10px;
          padding-right: 10px;
          max-width: 1000px;
          margin: 0 auto;
        }
        .defaultBtn {
          margin-top: 10px;
        }
      `}
    >
      <div className="card">
        <div className="cardHeader">
          <h2>Mittarit</h2>
        </div>
        <Form>
          <div className="cardContent">
            <Form>
              <div
                css={`
                  display: flex;
                  flex-direction: column;
                  border-radius: 8px;
                  .row {
                    display: flex;
                    align-items: center;
                    padding-top: 8px;
                    line-height: 20px;
                    margin-bottom: ${gauges.length === 0 ? "54px" : "0"};
                    > div {
                      box-sizing: border-box;
                    }
                    > div:nth-child(1) {
                      flex-basis: 220px;
                    }
                    > div:nth-child(2) {
                      flex: 1;
                    }
                    > div:nth-child(3) {
                      flex: 1;
                    }
                    > div:nth-child(4) {
                      flex-basis: 110px;
                    }
                    > div:nth-child(5) {
                      flex-basis: 110px;
                    }
                    > div:nth-child(6) {
                      flex-basis: 30px;
                    }
                  }
                  .row:first-child {
                    font-weight: 400;
                    border-bottom: 1px solid rgba(59, 74, 104, 0.1);
                    padding-bottom: 4px;
                    span {
                      font-size: 15px;
                    }
                  }
                  .row > div:first-child {
                    padding-left: 0;
                  }
                  .item {
                    padding-top: 8px;
                    padding-bottom: 8px;
                    > div {
                      padding-left: 5px;
                    }
                    input {
                      width: 100%;
                      color: #3b4a68;
                    }
                  }
                  .remove {
                    width: 34px;
                    height: 34px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                  .remove:hover {
                    background: rgba(181, 190, 207, 0.3);
                    cursor: pointer;
                  }
                `}
              >
                <div className="row">
                  <div>
                    <span>Tyyppi</span>
                  </div>
                  <div>
                    <span>Otsikko</span>
                  </div>
                  <div>
                    <span>Alaotsikko</span>
                  </div>
                  <div>
                    <span>Min</span>
                  </div>
                  <div>
                    <span>Max</span>
                  </div>

                  <div></div>
                </div>
                {gauges.map(item => {
                  const obj = mittaritGlobal.find(
                    i => i.id === item.useGlobalGauge
                  );

                  return (
                    <>
                      <div className="row item">
                        <div>
                          <Select
                            name="type"
                            className="select-container"
                            classNamePrefix="select"
                            isClearable={false}
                            onChange={e => handleTypeChange(e, item)}
                            defaultValue={{
                              value: obj.id,
                              label: `${obj.title}: ${obj.unit} | ${obj.type}`,
                            }}
                            options={typeOptions}
                            placeholder="Valitse..."
                          />
                        </div>
                        <div>
                          <input
                            type="text"
                            name="otsikko"
                            autoFocus={item.otsikko === "" ? true : false}
                            onChange={e => handleChange(e, item)}
                            value={item.otsikko}
                          />
                        </div>
                        <div>
                          <input
                            type="text"
                            name="alateksti"
                            onChange={e => handleChange(e, item)}
                            value={item.alateksti}
                          />
                        </div>
                        <div>
                          <input
                            type="number"
                            name="min"
                            onChange={e => handleChange(e, item)}
                            value={item.min}
                          />
                        </div>
                        <div>
                          <input
                            type="number"
                            name="max"
                            onChange={e => handleChange(e, item)}
                            value={item.max}
                          />
                        </div>

                        <div>
                          <div
                            className="remove"
                            onClick={() => handleRemove(item)}
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z"
                                fill="#3B4A68"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div>
                        <span
                          css={`
                            padding-bottom: 10px;
                            font-size: 13px;
                            opacity: 0.7;
                            display: inline-block;
                            strong {
                              margin-right: 10px;
                            }
                          `}
                        >
                          Yksikkö: <strong>{obj.unit}</strong> Yhteenlaskukaava:{" "}
                          <strong>{obj.type}</strong>
                        </span>
                      </div>
                    </>
                  );
                })}
                <div>
                  <button
                    type="submit"
                    className="defaultBtn blueOutlines"
                    onClick={e => handleNewItem(e)}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
                        fill="white"
                      />
                    </svg>
                    Lisää mittari
                  </button>
                </div>
              </div>
            </Form>
            <Link to="/dashboard/yrityksen-tiedot/mittarit">
              <span
                css={`
                  font-weight: 400;
                  font-size: 14px;
                  text-decoration: underline;
                  margin-top: 20px;
                  display: inline-flex;
                  margin-top: 80px;
                  svg {
                    margin-left: 5px;
                  }
                `}
              >
                Lisää ja muokkaa mittarityyppejä
              </span>
            </Link>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Gauges;
