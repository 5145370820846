import React from "react";
import "styled-components/macro";
import {
  LoadAuthor,
  LoadDiscussion,
  LoadProfile,
} from "../../../account/components/DataLoaders";
import { UploadedImageKeyToURL } from "./EditCard";
import { RoundedSquareChildDiv } from "../../../components/UploadAvatar";
import moment from "moment";
import "moment/locale/fi";
import { useMutation, gql } from "@apollo/client";
moment.locale("fi");

const Discussion: React.FC<{
  customerSlug: string;
  id: string;
}> = ({ customerSlug, id }) => {
  const [notifyCustomerManager] = useMutation(gql`
    mutation NotifyCustomerManager($customerSlug: String!, $message: String!) {
      notifyCustomerManager(customerSlug: $customerSlug, message: $message)
    }
  `);
  return (
    <LoadDiscussion customerSlug={customerSlug} id={id}>
      {({ discussion, setDiscussion }) => (
        <LoadProfile>
          {({ user }) => (
            <>
              {user.avatarUrl !== null && (
                <UploadedImageKeyToURL Key={user.avatarUrl}>
                  {({ url }: any) => (
                    <>
                      <div
                        className="preventEditOnClick"
                        css={`
                          margin: 16px;
                          margin-top: -16px;
                          position: relative;
                        `}
                      >
                        <textarea
                          css={`
                            &&& {
                              border: 1px solid rgba(181, 190, 207, 0.5);
                              box-sizing: border-box;
                              border-radius: 8px;
                              resize: none;
                              width: 100%;
                              display: block;
                              padding-top: 8px;
                              padding-right: 8px;
                              padding-left: 48px;
                              padding-bottom: 32px;
                              font-family: "hero-new", sans-serif;
                              font-size: 14px;
                              line-height: 24px;
                              color: #3b4a68;
                              outline: none;
                            }
                          `}
                          spellCheck="false"
                          autoCapitalize="no"
                          autoCorrect="no"
                          placeholder="Kirjoita kommentti"
                          rows={1}
                          onKeyDown={e => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              setDiscussion([
                                ...discussion,
                                {
                                  createdAt: Date.now(),
                                  email: user.email,
                                  message: e.currentTarget.value,
                                },
                              ]);
                              notifyCustomerManager({
                                variables: {
                                  customerSlug,
                                  message: `${user.email} jätti kommentin, ${customerSlug}.`,
                                },
                              });
                            }
                          }}
                        ></textarea>
                        <span
                          css={`
                            display: inline-block;
                            width: 32px;
                            position: absolute;
                            top: 8px;
                            left: 8px;
                            pointer-events: none;
                          `}
                        >
                          <RoundedSquareChildDiv>
                            <div
                              css={`
                                background: url(${url});
                                background-size: cover;
                                background-position: 50% 50%;
                                background-repeat: no-repeat;
                              `}
                            />
                          </RoundedSquareChildDiv>
                        </span>
                      </div>
                    </>
                  )}
                </UploadedImageKeyToURL>
              )}
              {user.avatarUrl === null && (
                <>
                  <div
                    className="preventEditOnClick"
                    css={`
                      margin: 16px;
                      margin-top: -16px;
                    `}
                  >
                    <textarea
                      css={`
                        border: 1px solid rgba(181, 190, 207, 0.5);
                        box-sizing: border-box;
                        border-radius: 8px;
                        resize: none;
                        width: 100%;
                        display: block;
                        padding-top: 8px;
                        padding-right: 8px;
                        padding-left: 8px;
                        padding-bottom: 32px;
                        font-family: "hero-new", sans-serif;
                        font-size: 14px;
                        line-height: 24px;
                        color: #3b4a68;
                        outline: none;
                      `}
                      spellCheck="false"
                      autoCapitalize="no"
                      autoCorrect="no"
                      placeholder="Kirjoita kommentti"
                      rows={1}
                      onKeyDown={e => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          setDiscussion([
                            ...discussion,
                            {
                              createdAt: Date.now(),
                              email: user.email,
                              message: e.currentTarget.value,
                            },
                          ]);
                          notifyCustomerManager({
                            variables: {
                              customerSlug,
                              message: `${user.email} jätti kommentin, ${customerSlug}.`,
                            },
                          });
                        }
                      }}
                    ></textarea>
                  </div>
                </>
              )}
              <div
                css={`
                  margin: 16px;
                `}
                className="preventEditOnClick"
              >
                {discussion
                  .slice()
                  .reverse()
                  .map(v => (
                    <>
                      <div
                        css={`
                          display: flex;
                          margin-bottom: 8px;
                        `}
                      >
                        <LoadAuthor
                          authorEmail={v.email}
                          customerSlug={customerSlug}
                        >
                          {({ name, title, avatarUrl }) => (
                            <>
                              {avatarUrl !== null ? (
                                <div>
                                  <UploadedImageKeyToURL Key={avatarUrl}>
                                    {({ url }: any) => (
                                      <div
                                        css={`
                                          display: block;
                                          width: 32px;
                                          height: 32px;
                                          background: url(${url});
                                          background-position: 50% 50%;
                                          background-size: cover;
                                          background-repeat: no-repeat;
                                          margin-left: 8px;
                                          margin-right: 8px;
                                          border-radius: 50%;
                                        `}
                                      />
                                    )}
                                  </UploadedImageKeyToURL>
                                </div>
                              ) : (
                                <div
                                  css={`
                                    margin-left: 8px;
                                    margin-right: 8px;
                                  `}
                                >
                                  <svg
                                    width="32"
                                    height="32"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M16 8C16 10.21 14.21 12 12 12C9.79004 12 8 10.21 8 8C8 5.78998 9.79004 4 12 4C14.21 4 16 5.78998 16 8ZM4 18C4 15.34 9.33008 14 12 14C14.6699 14 20 15.34 20 18V20H4V18Z"
                                      fill="#3b4a68"
                                      fillOpacity="0.34"
                                    />
                                  </svg>
                                </div>
                              )}
                              <div
                                css={`
                                  font-family: "hero-new", sans-serif;
                                  font-size: 14px;
                                  font-weight: 500;
                                  line-height: 24px;
                                  display: flex;
                                  align-items: center;
                                  color: #3b4a68;
                                  flex: 1;
                                `}
                              >
                                {name}
                              </div>
                            </>
                          )}
                        </LoadAuthor>
                        {v.email === user.email && (
                          <div
                            css={`
                              align-self: center;
                              margin-right: 8px;
                            `}
                          >
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a
                              href=""
                              onClick={e => {
                                e.preventDefault();
                                setDiscussion(
                                  discussion.filter(v2 => v2 !== v)
                                );
                              }}
                            >
                              &times;
                            </a>
                          </div>
                        )}
                        <div
                          css={`
                            font-family: "hero-new", sans-serif;
                            font-size: 14px;
                            line-height: 24px;
                            display: flex;
                            align-items: center;
                            text-align: right;
                            color: #3b4a68;
                            opacity: 0.6;
                          `}
                        >
                          {moment(v.createdAt).format("D.M.YYYY [klo] HH:mm")}
                        </div>
                      </div>
                      <div
                        css={`
                          margin-left: 48px;
                          font-family: "hero-new", sans-serif;
                          font-style: normal;
                          font-weight: normal;
                          font-size: 14px;
                          line-height: 24px;
                          color: #3b4a68;
                          margin-bottom: 16px;
                        `}
                      >
                        {v.message}
                      </div>
                    </>
                  ))}
              </div>
            </>
          )}
        </LoadProfile>
      )}
    </LoadDiscussion>
  );
};

export default Discussion;
