import "styled-components/macro";
import { Form } from "../../../components/Form";
import Select from "react-select";

const iconOptions = [
  {
    value: "Link",
    label: "Link",
  },
  {
    value: "Chess",
    label: "Chess",
  },
  {
    value: "Chart",
    label: "Chart",
  },
  {
    value: "Document",
    label: "Document",
  },
];

const Links = ({ companyLinks, setCompanyLinks, setInputsAreValid }) => {
  const handleChange = (e, item) => {
    let key = e.target.name;
    let value = e.target.value;
    setCompanyLinks(
      companyLinks.map(link =>
        link === item ? { ...item, [key]: value } : link
      )
    );
  };

  const handleIconChange = (selection, item) => {
    let icon = selection.value;
    setCompanyLinks(
      companyLinks.map(link => (link === item ? { ...item, icon } : link))
    );
  };

  const handleRemove = item => {
    if (!window.confirm("Haluatko varmasti poistaa linkin?")) return;
    setCompanyLinks(companyLinks.filter(link => link !== item));
  };

  const handleNewItem = e => {
    e.preventDefault();
    setCompanyLinks([
      ...companyLinks,
      {
        title: "",
        url: "",
        icon: "Link",
      },
    ]);
  };

  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
        .card {
          box-shadow: 0px 4px 12px rgba(181, 190, 207, 0.5);
          border-radius: 8px;
          background: #fff;
          margin-top: 20px;
          margin-bottom: 20px;
          background: #f4f5f8;

          > div {
            display: flex;
            padding-left: 15px;
            padding-right: 15px;
          }
        }
        .cardHeader {
          height: 64px;
          background: #fff;
          align-items: center;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          border-bottom: 1px solid rgba(181, 190, 207, 0.5);
          h2 {
            font-size: 18px;
            font-weight: 500;
          }
        }
        .cardContent {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          padding-top: 40px;
          padding-bottom: 100px;
          padding-left: 10px;
          padding-right: 10px;
          max-width: 1000px;
          margin: 0 auto;
        }
        .defaultBtn {
          margin-top: 10px;
        }
      `}
    >
      <div className="card">
        <div className="cardHeader">
          <h2>Linkit</h2>
        </div>
        <Form>
          <div className="cardContent">
            <Form>
              <div
                css={`
                  display: flex;
                  flex-direction: column;
                  border-radius: 8px;
                  .row {
                    display: flex;
                    align-items: center;
                    padding-top: 8px;
                    line-height: 20px;
                    margin-bottom: ${companyLinks.length === 0 ? "54px" : "0"};
                    > div {
                      box-sizing: border-box;
                    }
                    > div:nth-child(1) {
                      flex: 2;
                    }
                    > div:nth-child(2) {
                      flex: 3;
                    }
                    > div:nth-child(3) {
                      flex-basis: 200px;
                    }
                    > div:nth-child(4) {
                      flex-basis: 30px;
                    }
                  }
                  .row:first-child {
                    font-weight: 400;
                    border-bottom: 1px solid rgba(59, 74, 104, 0.1);
                    padding-bottom: 4px;
                    span {
                      font-size: 15px;
                    }
                  }
                  .row > div:first-child {
                    padding-left: 0;
                  }
                  .link {
                    padding-top: 8px;
                    padding-bottom: 8px;
                    > div {
                      padding-left: 5px;
                    }
                    input {
                      width: 100%;
                      color: #3b4a68;
                    }
                  }
                  .remove {
                    width: 34px;
                    height: 34px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                  .remove:hover {
                    background: rgba(181, 190, 207, 0.3);
                    cursor: pointer;
                  }
                  .defaultBtn {
                    margin-top: 10px;
                  }
                `}
              >
                <div className="row">
                  <div>
                    <span>Linkin nimi</span>
                  </div>
                  <div>
                    <span>URL</span>
                  </div>
                  <div>
                    <span>Ikoni</span>
                  </div>
                  <div>
                    <span></span>
                  </div>
                </div>
                {companyLinks.map((item, index) => {
                  return (
                    <div className="row link">
                      <div>
                        <input
                          type="text"
                          name="title"
                          autoFocus={item.title === "" ? true : false}
                          onChange={e => handleChange(e, item)}
                          value={item.title}
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          name="url"
                          onChange={e => handleChange(e, item)}
                          value={item.url}
                          placeholder="https://"
                        />
                      </div>
                      <div>
                        <Select
                          name="icon"
                          className="select-container"
                          classNamePrefix="select"
                          isClearable={false}
                          onChange={e => handleIconChange(e, item)}
                          defaultValue={{
                            value: item.icon,
                            label: item.icon,
                          }}
                          options={iconOptions}
                          placeholder="Valitse..."
                        />
                      </div>
                      <div>
                        <div
                          className="remove"
                          onClick={() => handleRemove(item)}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z"
                              fill="#3B4A68"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div>
                  <button
                    type="submit"
                    className="defaultBtn blueOutlines"
                    onClick={e => handleNewItem(e)}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
                        fill="white"
                      />
                    </svg>
                    Lisää linkki
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Links;
