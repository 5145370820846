import React from "react";
import { useState } from "react";
import "styled-components/macro";

// TODO: vertailuCumulative jatka siitä

const getRangeIndexes = (range, monthAndValue) => {
  const indexStart = monthAndValue.findIndex(obj => {
    return obj.month === range.startMonth;
  });
  const indexEnd = monthAndValue.findIndex(obj => {
    return obj.month === range.endMonth;
  });

  return [indexStart, indexEnd];
};

const getVertailuRangeIndexes = (range, monthAndValue) => {
  const indexStart = monthAndValue.findIndex(obj => {
    return obj.month === range.startMonth_compare;
  });
  const indexEnd = monthAndValue.findIndex(obj => {
    return obj.month === range.endMonth_compare;
  });

  return [indexStart, indexEnd];
};

const getAvarageOfRange = (start, end, monthAndValue, unit) => {
  if (start === -1 || end === -1) {
    return {
      precise: 0,
      friendly: "N/A",
      comment: "Aloitus- ja/tai päättymiskuukauden raporttia ei löydy.",
    };
  }
  const arrMonths = monthAndValue.slice(start, end + 1);

  if (arrMonths.some(month => month.value === undefined)) {
    return {
      precise: 0,
      friendly: "N/A",
      comment:
        "Aikajakso sisältää yhden tai useamman raportin, jossa tämän mittarin arvoa ei ole määritelty.",
    };
  }

  const averagePrecise =
    arrMonths.reduce((a, b) => a + b.value, 0) / arrMonths.length;

  let friendly;
  if (unit === "%") {
    friendly = numberWithSpaces(Math.round(averagePrecise * 100) / 100).replace(
      ".",
      ","
    );
  } else {
    friendly = numberWithSpaces(parseInt(averagePrecise));
  }

  return {
    precise: averagePrecise,
    friendly: friendly,
    comment: null,
    monthsTotal: arrMonths.length,
  };
};

const getCumulativeOfRange = (start, end, monthAndValue, unit) => {
  if (start === -1 || end === -1) {
    return {
      precise: 0,
      friendly: "N/A",
      monthsTotal: 1,
      comment: "Aloitus- ja/tai päättymiskuukauden raporttia ei löydy.",
    };
  }
  const arrMonths = monthAndValue.slice(start, end + 1);

  if (arrMonths.some(month => month.value === undefined)) {
    return {
      precise: 0,
      friendly: "N/A",
      monthsTotal: 1,
      comment:
        "Aikajakso sisältää yhden tai useamman raportin, jossa tämän mittarin arvoa ei ole määritelty.",
    };
  }

  const averagePrecise = arrMonths.reduce((a, b) => a + b.value, 0);

  let friendly;
  if (unit === "%") {
    friendly = numberWithSpaces(Math.round(averagePrecise * 100) / 100).replace(
      ".",
      ","
    );
  } else {
    friendly = numberWithSpaces(parseInt(averagePrecise));
  }

  return {
    precise: averagePrecise,
    friendly: friendly,
    comment: null,
    monthsTotal: arrMonths.length,
  };
};

const GaugeHome = ({
  data,
  globalData,
  gaugesMode,
  range,
  allMonthsData,
  selectedMonthData,
  reportedMonths,
}) => {
  console.log(
    { data },
    { range },
    { globalData },
    { selectedMonthData },
    { reportedMonths },
    { allMonthsData }
  );

  const [formula, setFormula] = useState(globalData.type);

  const monthAndValue = coupleMonthNameAndGaugeValue(
    reportedMonths,
    allMonthsData
  );

  const [startMonth, endMonth] = getRangeIndexes(range, monthAndValue);

  const average = getAvarageOfRange(
    startMonth,
    endMonth,
    monthAndValue,
    globalData.unit
  );

  const cumulative = getCumulativeOfRange(
    startMonth,
    endMonth,
    monthAndValue,
    globalData.unit
  );

  console.log({ cumulative });

  /* Is Vertailujakso */
  const isVertailujakso =
    range.endMonth_compare &&
    range.startMonth_compare &&
    gaugesMode === "selected_range";
  let vertailuAverage = 0;
  let vertailuCumulative = 0;
  let areAllSame = true;

  if (isVertailujakso) {
    const [startMonthCompare, endMonthCompare] = getVertailuRangeIndexes(
      range,
      monthAndValue
    );

    console.log(range);

    vertailuAverage = getAvarageOfRange(
      startMonthCompare,
      endMonthCompare,
      monthAndValue,
      globalData.unit
    );
    vertailuCumulative = getCumulativeOfRange(
      startMonthCompare,
      endMonthCompare,
      monthAndValue,
      globalData.unit
    );

    areAllSame =
      new Set([
        average.monthsTotal,
        vertailuAverage.monthsTotal,
        cumulative.monthsTotal,
        vertailuCumulative.monthsTotal,
      ]).size === 1;
  }

  /* SVG Gauge Layout and Animation Etc. */
  const path = "M42 181 A 100 100 0 1 1 182 182";
  let t,
    t2,
    delta = 0;

  // Count T1
  if (gaugesMode === "this_month") {
    t = (selectedMonthData?.luku - data.min) / (data.max - data.min);
  }
  if (gaugesMode === "selected_range" && formula === "Keskiarvo") {
    t = (average.precise - data.min) / (data.max - data.min);
    console.log("KESKIARVO", average.precise);
    console.log("KESKIARVO", t);
  }
  if (gaugesMode === "selected_range" && formula === "Kumulatiivinen") {
    t =
      (cumulative.precise - cumulative.monthsTotal * data.min) /
      (cumulative.monthsTotal * data.max - cumulative.monthsTotal * data.min);
    console.log("KUMUKATIVE", t);
    console.log("KUMUKATIVE", cumulative.precise);
  }

  // Count T2
  if (isVertailujakso && formula === "Keskiarvo") {
    t2 = (vertailuAverage.precise - data.min) / (data.max - data.min);
    delta = t - t2;
    console.log("DELTA KESKIARVO", delta);
  }
  if (isVertailujakso && formula === "Kumulatiivinen") {
    t2 =
      (vertailuCumulative.precise - vertailuCumulative.monthsTotal * data.min) /
      (vertailuCumulative.monthsTotal * data.max -
        vertailuCumulative.monthsTotal * data.min);
    delta = t - t2;
    console.log("DELTA KUMULATIIVINEN", delta);
  }

  const deltaPositive = delta >= 0;

  // Decide gauge color
  let color, decimal;
  if (gaugesMode === "this_month") {
    color = selectedMonthData?.color;
  }
  if (gaugesMode === "selected_range" && formula === "Keskiarvo") {
    decimal = (average.precise - data.min) / (data.max - data.min);
  }
  if (gaugesMode === "selected_range" && formula === "Kumulatiivinen") {
    decimal =
      (cumulative.precise - cumulative.monthsTotal * data.min) /
      (cumulative.monthsTotal * data.max - cumulative.monthsTotal * data.min);
  }
  if (decimal <= 0.333) {
    color = "#FE7A30";
  }
  if (decimal > 0.333 && decimal <= 0.666) {
    color = "#FCB816";
  }
  if (decimal > 0.666) {
    color = "#00EA9D";
  }
  console.log("descimal", decimal);

  const [anim, setAnim] = React.useState(50);
  React.useEffect(() => {
    setAnim(0);
  }, []);

  const valueThisMonth = selectedMonthData?.luku
    ? numberWithSpaces(Math.round(selectedMonthData?.luku * 100) / 100).replace(
        ".",
        ","
      )
    : "-";
  let showValue;
  if (gaugesMode === "this_month") showValue = valueThisMonth;
  if (gaugesMode === "selected_range")
    showValue =
      formula === "Kumulatiivinen" ? cumulative.friendly : average.friendly;

  let fontSize;
  const characters = showValue.length;
  switch (characters) {
    case characters > 9:
      fontSize = "20px";
      break;
    case 9:
      fontSize = "24px";
      break;
    case 8:
      fontSize = "26px";
      break;
    case 7:
      fontSize = "28px";
      break;
    case 6:
      fontSize = "32px";
      break;
    case 5:
      fontSize = "36px";
      break;
    default:
      fontSize = "40px";
      break;
  }

  return (
    <div
      css={`
        flex: 1;
        margin-top: 18px;
        margin-left: 10px;
        margin-right: 10px;
        position: relative;
        padding: 32px 10px;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        text-align: center;
        background: #3b4a68;
        box-shadow: 0px 4px 12px rgba(59, 74, 104, 0.5);
        border-radius: 8px;
        font-family: "hero-new", sans-serif;
        color: #ffffff;

        .editBtn {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(59, 74, 104, 0.25);
          border-radius: 50%;
          cursor: pointer;
          position: absolute;
          top: 10px;
          right: 10px;
          opacity: 0;
          &:hover {
            background: rgba(255, 255, 255, 0.15);
            transition: all 0.4s;
          }
          svg path {
            fill: rgba(255, 255, 255, 0.2);
          }
        }
        &:hover .editBtn {
          opacity: 1;
          transition: opacity 0.4s;
        }
      `}
    >
      <div
        css={`
          margin-left: auto;
          margin-right: auto;
          display: flex;
          align-items: center;
          font-size: 22px;
          max-width: 268px;
          font-weight: 700;
          line-height: 24px;
          margin-bottom: 25px;
        `}
      >
        <span>{data.otsikko}</span>
      </div>

      <div
        css={`
          display: inline-block;
          position: relative;
          margin-left: -1px;
          ${isVertailujakso &&
          !deltaPositive &&
          `
          .mainStroke {
            color: #FF3B4A;
            opacity: 0.3;
          }
          `}
          ${isVertailujakso &&
          deltaPositive &&
          `
          .mainStroke {
            color: #00EA9D;
            opacity: 0.3;
          }
          `}
        `}
      >
        {gaugesMode === "selected_range" && !areAllSame && (
          <span>Aikajaksoissa eri määrä mittariarvoja</span>
        )}
        <svg
          width="228"
          height="195"
          viewBox="0 0 223 195"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            color,
            visibility:
              gaugesMode === "selected_range" && average.comment
                ? "hidden"
                : "visible",
          }}
          css={`
            ${gaugesMode === "selected_range" && !areAllSame && "opacity: 0;"}
          `}
        >
          <path
            opacity="0.2"
            d={path}
            stroke="#B5BECF"
            strokeWidth="20"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            opacity="0.5"
            strokeMiterlimit="10"
            d={path}
            stroke="#3B4A68"
            strokeWidth="26"
            strokeLinecap="round"
            strokeDasharray={`${t * 470} 10000`}
            strokeDashoffset={anim}
            style={{ transition: "stroke-dashoffset 500ms" }}
          />
          <path
            d={path}
            stroke="currentColor"
            className="mainStroke"
            strokeWidth="20"
            strokeLinecap="round"
            strokeDasharray={`${t * 470} 10000`}
            strokeDashoffset={anim}
            style={{ transition: "stroke-dashoffset 500ms" }}
          />
          {isVertailujakso && deltaPositive && (
            <>
              <path
                d={path}
                stroke="#00EA9D"
                strokeWidth="20"
                strokeLinecap="round"
                strokeDasharray={`${delta * 470} 10000`}
                strokeDashoffset={`${-t - t2 * 470}`}
              />
              {delta > 0 && (
                <path
                  d={path}
                  stroke="#00EA9D"
                  strokeWidth="20"
                  strokeLinecap="square"
                  strokeDasharray={`${delta * 470 - 10} 10000`}
                  strokeDashoffset={`${-t - t2 * 470}`}
                />
              )}
            </>
          )}
          {isVertailujakso && !deltaPositive && (
            <>
              <path
                d={path}
                stroke="#FF3B4A"
                strokeWidth="20"
                strokeLinecap="round"
                strokeDasharray={`${-1 * delta * 470} 10000`}
                strokeDashoffset={`${t - t2 * 470 - delta * 470}`}
              />
              <path
                d={path}
                stroke="#FF3B4A"
                strokeWidth="20"
                strokeLinecap="square"
                strokeDasharray={`${-1 * delta * 470 - 10} 10000`}
                strokeDashoffset={`${t - t2 * 470 - delta * 470 - 10}`}
              />
            </>
          )}
        </svg>
        <div
          css={`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            width: 100%;
            ${isVertailujakso && "margin-top: 30px;"}
            svg {
              width: 100%;
              fill: #fff;
              text {
                text-align: center;
              }
            }
          `}
        >
          <>
            <span
              className="value"
              css={`
                font-weight: 700;
                font-size: ${fontSize};
              `}
              style={{
                visibility:
                  gaugesMode === "selected_range" && average.comment
                    ? "hidden"
                    : "visible",
              }}
            >
              {showValue} {globalData.unit}
            </span>
            {isVertailujakso && (
              <div
                css={`
                  margin-top: 10px;
                  span {
                    opacity: 0.5;
                  }
                  span:first-child {
                    font-size: 18px;
                    line-height: 25px;
                  }
                  span:last-child {
                    font-size: 14px;
                    font-weight: 500;
                  }
                `}
              >
                <span>
                  {formula === "Keskiarvo" &&
                    vertailuAverage.friendly + " " + globalData.unit}
                  {formula === "Kumulatiivinen" &&
                    vertailuCumulative.friendly + " " + globalData.unit}
                </span>
                <br />
                <span>
                  {range.startMonth_compare.split("-")[1] +
                    "/" +
                    range.startMonth_compare.split("-")[0]}
                  –
                  {range.endMonth_compare.split("-")[1] +
                    "/" +
                    range.endMonth_compare.split("-")[0]}
                </span>
              </div>
            )}
          </>

          {gaugesMode === "selected_range" && average.comment && (
            <span
              css={`
                font-size: 15px;
                padding: 0 20px;
              `}
            >
              {average.comment}
            </span>
          )}
        </div>
      </div>
      <div
        css={`
          margin: 10px 10px;
          button {
            border-radius: 5px;
            margin: 0 5px;
            background: none;
            color: #fff;
            opacity: 0.5;
            &.active {
              opacity: 0.9;
              &:hover {
                cursor: default;
              }
            }
          }
        `}
      >
        <>
          <button
            className={formula === globalData.type ? "active" : ""}
            onClick={() => setFormula(globalData.type)}
          >
            {globalData.type}
          </button>
          {globalData.secondary_type !== null && (
            <button
              className={formula === globalData.secondary_type ? "active" : ""}
              onClick={() => setFormula(globalData.secondary_type)}
            >
              {globalData.secondary_type}
            </button>
          )}
        </>
      </div>
      <span
        css={`
          margin-top: 18px;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
        `}
      >
        {data.alateksti}
      </span>
    </div>
  );
};

export default GaugeHome;

const numberWithSpaces = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const monthToDigits = slug => {
  const splitted = slug.split("-");
  const year = splitted[1];
  let monthDigit = null;
  if (splitted[0] === "tammikuu") monthDigit = "01";
  if (splitted[0] === "helmikuu") monthDigit = "02";
  if (splitted[0] === "maaliskuu") monthDigit = "03";
  if (splitted[0] === "huhtikuu") monthDigit = "04";
  if (splitted[0] === "toukokuu") monthDigit = "05";
  if (splitted[0] === "kesakuu") monthDigit = "06";
  if (splitted[0] === "heinakuu") monthDigit = "07";
  if (splitted[0] === "elokuu") monthDigit = "08";
  if (splitted[0] === "syyskuu") monthDigit = "09";
  if (splitted[0] === "lokakuu") monthDigit = "10";
  if (splitted[0] === "marraskuu") monthDigit = "11";
  if (splitted[0] === "joulukuu") monthDigit = "12";

  return `${year}-${monthDigit}`;
};

const coupleMonthNameAndGaugeValue = (reportedMonths, allMonthsData) => {
  const months = reportedMonths.map(month => {
    return monthToDigits(month.slug);
  });

  return months.map((name, index) => {
    return {
      month: name,
      value: allMonthsData[index],
    };
  });
};
