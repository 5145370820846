import "styled-components/macro";
import theme from "../../../../styles/theme";

const SubHeading = ({
  data,
  setData,
  dragHandleProps,
  index,
  removeHandler,
  readOnly,
}) => {
  const headingId = data.title;

  return (
    <div
      id={headingId}
      css={`
        margin-top: -5px;
        position: relative;
        &:hover .removeHeading {
          display: flex;
        }
        div:nth-child(2) {
          margin-top: 0;
          position: relative;
          display: flex;
          justify-content: center;
          width: 100%;
          align-items: center;
          h2 {
            cursor: ${readOnly ? "auto" : "pointer"};
            margin-top: 0;
            margin-bottom: 0;
            padding: 10px;
            font-size: 30px;
            font-weight: 600;
            line-height: 42px;
            align-items: center;
            text-align: center;
            color: #3b4a68;
            display: inline-flex;
            ${theme.mobile} {
              font-size: 24px;
            }
          }
        }
        .removeHeading {
          cursor: pointer;
          left: 0;
          bottom: 6px;
          position: absolute;
          display: none;
          align-items: center;
          padding: 10px;
          color: #697da2;
          transition: all 0.3s;
          opacity: 0.5;
          svg {
            margin-right: 6px;
          }
          &:hover {
            opacity: 1;
          }
        }
      `}
    >
      <div>
        <svg
          width="2"
          height="64"
          viewBox="0 0 2 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="1"
            y1="1"
            x2="1"
            y2="63"
            stroke="#B5BECF"
            strokeWidth="2"
            strokeMiterlimit="16"
            strokeLinecap="round"
            strokeDasharray="1 6"
          />
        </svg>
      </div>
      <div {...dragHandleProps}>
        <h2
          contentEditable={!readOnly}
          onBlur={e => {
            setData({ ...data, title: e.target.innerText });
          }}
        >
          {data.title}
        </h2>
      </div>
      {!readOnly && (
        <div
          className="removeHeading"
          onClick={() => {
            removeHandler(index);
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 10.5H9V3.5H3V10.5ZM9.5 2H7.75L7.25 1.5H4.75L4.25 2H2.5V3H9.5V2Z"
              fill="#697da2"
            />
          </svg>
          Poista otsikko
        </div>
      )}
    </div>
  );
};

export default SubHeading;
