import React from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import deepEqual from "deep-equal";
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { createGlobalStyle, css } from "styled-components/macro";
import { QuillContent } from "../../../components/Quill";

const CustomQuillStyles = createGlobalStyle`
.ql-toolbar.ql-snow {
  border: none;
  border-top 1px solid rgba(59,74,104,0.1); 
}
.ql-container.ql-snow {
  border: none;
  border-top 1px solid rgba(59,74,104,0.1);
}
.ql-container {
  > .ql-editor {
    padding: 16px;
  }
  outline: none;
  font-size: inherit;
  font-family: inherit;
  box-shadow: inset 5px 5px 6px 0px rgba(0, 0, 0, 0.09);
  background: #f4f5f8;
}
`;

const EditTextarea: React.FC<{ customer: string; type: string }> = ({
  customer,
  type,
}) => {
  const [serverObject, setServerObject] = React.useState<undefined | any>(
    undefined
  );
  const [value, setValue] = React.useState<undefined | string>(undefined);

  const loadResult = useQuery(
    gql`
      query ($customer: String!, $type: String!) {
        loadCustomerMemo(customer: $customer, type: $type)
      }
    `,
    {
      variables: {
        customer,
        type,
      },
    }
  );

  const [save, saveResult] = useMutation(
    gql`
      mutation ($customer: String!, $oldDoc: Object!, $newDoc: Object!) {
        saveCustomerMemo(customer: $customer, oldDoc: $oldDoc, newDoc: $newDoc)
      }
    `
  );

  React.useLayoutEffect(() => {
    if (saveResult.error) alert(saveResult.error);
  }, [saveResult]);

  const shouldSaveSomething = React.useMemo(() => {
    if (typeof serverObject === "undefined") return false;
    const oldDoc = serverObject;
    const newDoc = { ...serverObject, value };
    return !deepEqual(oldDoc, newDoc);
  }, [serverObject, value]);

  React.useLayoutEffect(() => {
    if (!shouldSaveSomething) return;
    const cb = () => {
      return true;
    };
    window.onbeforeunload = cb;
    return () => {
      window.onbeforeunload = null;
    };
  }, [shouldSaveSomething]);

  React.useLayoutEffect(() => {
    if (typeof value === "undefined") return;
    if (typeof serverObject === "undefined") return;
    const oldDoc = serverObject;
    const newDoc = { ...serverObject, value };
    if (deepEqual(oldDoc, newDoc)) return;
    const tid = setTimeout(async () => {
      const result = await save({
        variables: {
          customer,
          oldDoc,
          newDoc,
        },
      });
      setServerObject(result.data.saveCustomerMemo);
    }, 1000);
    return () => {
      clearTimeout(tid);
    };
  }, [value, save, customer, serverObject]);

  React.useLayoutEffect(() => {
    if (typeof loadResult.data === "undefined") return;
    setServerObject(loadResult.data.loadCustomerMemo);
    setValue(loadResult.data.loadCustomerMemo.value);
  }, [loadResult]);

  const divRef = React.useRef<HTMLDivElement>(null);

  const editorRef = React.useRef<any>(null);
  React.useLayoutEffect(() => {
    if (typeof value === "undefined") return;
    if (editorRef.current !== null) return;
    const editor = new Quill(divRef.current!, {
      theme: "snow",
    });
    try {
      editor.setContents(JSON.parse(value));
    } catch (err) {}
    editor.root.setAttribute("spellcheck", "false");
    editor.on("text-change", () => {
      setValue(JSON.stringify(editor.getContents()));
    });
    editorRef.current = editor;
    return () => {};
  }, [value]);

  if (typeof value === "undefined") return null;

  return (
    <>
      <div
        css={`
          ${shouldSaveSomething &&
          css`
            .ql-container {
              background: #fff;
            }
          `}
        `}
      >
        <div ref={divRef} />
      </div>
      <CustomQuillStyles />
    </>
  );
};

export const ShowQuillContent: React.FC<{ customer: string; type: string }> = ({
  customer,
  type,
}) => {
  const loadResult = useQuery(
    gql`
      query ($customer: String!, $type: String!) {
        loadCustomerMemo(customer: $customer, type: $type)
      }
    `,
    {
      variables: {
        customer,
        type,
      },
    }
  );
  const delta = React.useMemo(() => {
    try {
      return JSON.parse(loadResult.data.loadCustomerMemo.value).ops;
    } catch (err) {
      return undefined;
    }
  }, [loadResult.data]);
  if (typeof loadResult.data === "undefined") return <></>;
  return (
    <>
      <QuillContent delta={delta} />
    </>
  );
};

export default EditTextarea;
