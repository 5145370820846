import React, { useCallback } from "react";
import "styled-components/macro";
import { Form } from "../../components/Form";

const Gauge = ({
  data,
  data2: initialData2,
  setData2,
  readOnly,
  allMonths,
  gaugesMode,
  lastColor,
  setGaugesThisMonth,
  globalGauge,
}) => {
  const [data2, setdata2] = React.useState(
    initialData2 || { luku: 0, color: "#00EA9D" }
  );
  const [editField, setEditField] = React.useState(false);
  const [isSaveable, setIsSaveable] = React.useState(false);

  React.useLayoutEffect(() => {
    setdata2(initialData2 || { luku: 0, color: "#00EA9D" });
  }, [initialData2]);

  const handleChange = event => {
    let key = event.target.name;
    let value = parseFloat(event.target.value);
    setdata2({ ...data2, [key]: value });
    // Next 4 lines are duplicated to colorHandler
    if (value <= data.max && value >= data.min) {
      setIsSaveable(true);
    } else {
      setIsSaveable(false);
    }
  };

  const colorHandler = event => {
    setdata2({ ...data2, color: event.target.value });
    if (data2.luku <= data.max && data2.luku >= data.min) {
      setIsSaveable(true);
    } else {
      setIsSaveable(false);
    }
  };

  // Handle All months Mode

  const allLuku = allMonths.filter(luku => typeof luku === "number");

  let showOnAllMonths, cumulative, average;
  if (allLuku.length > 0) {
    cumulative = allLuku.reduce((a, b) => a + b, 0);
    average = cumulative / allLuku.length;
  }

  if (globalGauge.type === "Kumulatiivinen") showOnAllMonths = cumulative;
  if (globalGauge.type === "Keskiarvo") showOnAllMonths = average;

  const onSubmit = e => {
    e.preventDefault();
    setData2(data2);
    setEditField(false);
  };

  const path = "M42 181 A 100 100 0 1 1 182 182";
  const t =
    gaugesMode === "this_month"
      ? (data2?.luku - data.min) / (data.max - data.min)
      : (showOnAllMonths - data.min) / (data.max - data.min);
  let color = gaugesMode === "this_month" ? initialData2?.color : lastColor;

  const numberWithSpaces = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const [anim, setAnim] = React.useState(50);
  React.useEffect(() => {
    setAnim(0);
  }, []);

  const callbackRef = useCallback(inputElement => {
    if (inputElement) {
      setTimeout(() => {
        inputElement.focus();
      }, 0);
    }
  }, []);

  // Font Size on total value

  let numberLengthMonth = `${Math.round(showOnAllMonths * 100) / 100} ${
    globalGauge.unit
  }`;

  let numberLengthAll = `${Math.round(showOnAllMonths * 100) / 100} ${
    globalGauge.unit
  }`;

  let fontSize;
  switch (numberLengthMonth.toString().length) {
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
    case 15:
      fontSize = "20px";
      break;
    case 9:
      fontSize = "24px";
      break;
    case 8:
      fontSize = "26px";
      break;
    case 7:
      fontSize = "28px";
      break;
    case 6:
      fontSize = "32px";
      break;
    case 5:
      fontSize = "36px";
      break;
    default:
      fontSize = "40px";
      break;
  }

  let fontSizeAllMonths;
  switch (numberLengthAll?.toString().length) {
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
    case 15:
      fontSizeAllMonths = "20px";
      break;
    case 9:
      fontSizeAllMonths = "24px";
      break;
    case 8:
      fontSizeAllMonths = "26px";
      break;
    case 7:
      fontSizeAllMonths = "28px";
      break;
    case 6:
      fontSizeAllMonths = "32px";
      break;
    case 5:
      fontSizeAllMonths = "36px";
      break;
    default:
      fontSizeAllMonths = "40px";
      break;
  }

  console.log("LUKU", initialData2?.luku);

  return (
    <div
      css={`
        flex: 1;
        margin-top: 18px;
        margin-left: 10px;
        margin-right: 10px;
        position: relative;
        padding: 32px 0;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        text-align: center;
        background: #3b4a68;
        box-shadow: 0px 4px 12px rgba(59, 74, 104, 0.5);
        border-radius: 8px;
        font-family: "hero-new", sans-serif;
        color: #ffffff;

        .editBtn {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(59, 74, 104, 0.25);
          border-radius: 50%;
          cursor: pointer;
          position: absolute;
          top: 10px;
          right: 10px;
          opacity: 0;
          &:hover {
            background: rgba(255, 255, 255, 0.15);
            transition: all 0.4s;
          }
          svg path {
            fill: rgba(255, 255, 255, 0.2);
          }
        }
        &:hover .editBtn {
          opacity: 1;
          transition: opacity 0.4s;
        }
      `}
    >
      <div
        css={`
          margin-left: auto;
          margin-right: auto;
          height: 48px;
          margin-bottom: 18px;
          display: flex;
          align-items: center;
          font-size: 22px;
          max-width: 268px;
          font-weight: 700;
          line-height: 24px;
        `}
      >
        <span>{data.otsikko}</span>
      </div>
      <div
        css={`
          display: inline-block;
          position: relative;
          margin-left: -1px;
        `}
      >
        <svg
          width="228"
          height="195"
          viewBox="0 0 223 195"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ color }}
        >
          <path
            opacity="0.2"
            d={path}
            stroke="#B5BECF"
            strokeWidth="20"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            opacity="0.5"
            strokeMiterlimit="10"
            d={path}
            stroke="#3B4A68"
            strokeWidth="26"
            strokeLinecap="round"
            strokeDasharray={`${t * 470} 10000`}
            strokeDashoffset={anim}
            style={{ transition: "stroke-dashoffset 500ms" }}
          />
          <path
            d={path}
            stroke="currentColor"
            strokeWidth="20"
            strokeLinecap="round"
            strokeDasharray={`${t * 470} 10000`}
            strokeDashoffset={anim}
            style={{ transition: "stroke-dashoffset 500ms" }}
          />
        </svg>
        <div
          css={`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          {gaugesMode === "this_month" && (
            <span
              className="value"
              css={`
                font-weight: 700;
                font-size: ${fontSize};
              `}
            >
              {initialData2?.luku &&
                numberWithSpaces(
                  Math.round(initialData2?.luku * 100) / 100
                ).replace(".", ",")}{" "}
              {globalGauge.unit}
            </span>
          )}

          {gaugesMode === "all_months" && (
            <span
              className="value"
              css={`
                font-weight: 700;
                font-size: ${fontSizeAllMonths};
              `}
            >
              {showOnAllMonths &&
                numberWithSpaces(
                  Math.round(showOnAllMonths * 100) / 100
                ).replace(".", ",")}{" "}
              {globalGauge.unit}
            </span>
          )}
        </div>
        <div
          css={`
            position: absolute;
            left: 0;
            right: 0;
            bottom: 3px;
            display: flex;
            justify-content: center;
          `}
        >
          <span
            css={`
              font-weight: 600;
              font-size: 18px;
            `}
          >
            {/* 1/22 */}
          </span>
        </div>
      </div>
      <span
        css={`
          margin-top: 0px;
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          height: 20px;
          opacity: 0.5;
        `}
      >
        {gaugesMode === "all_months" && globalGauge.type}
      </span>
      <span
        css={`
          margin-top: 18px;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
        `}
      >
        {data.alateksti}
      </span>

      {!readOnly && (
        <div
          className="editBtn"
          onClick={() => {
            setGaugesThisMonth();
            setEditField(true);
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.9">
              <path
                d="M3 17.4601V20.5001C3 20.7801 3.22 21.0001 3.5 21.0001H6.54C6.67 21.0001 6.8 20.9501 6.89 20.8501L17.81 9.94006L14.06 6.19006L3.15 17.1001C3.05 17.2001 3 17.3201 3 17.4601ZM20.71 7.04006C21.1 6.65006 21.1 6.02006 20.71 5.63006L18.37 3.29006C17.98 2.90006 17.35 2.90006 16.96 3.29006L15.13 5.12006L18.88 8.87006L20.71 7.04006Z"
                fill="white"
              />
            </g>
          </svg>
        </div>
      )}

      {editField && (
        <div
          css={`
            display: flex;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            position: absolute;
            justify-content: center;
            align-items: center;
          `}
        >
          <div
            className="layerContent"
            css={`
              display: flex;
              position: relative;
              justify-content: flex-end;
              padding-right: 10px;
              align-items: center;
              width: 90%;
              background: rgba(255, 255, 255, 0.2);
              border-radius: 8px;
              height: 70px;
              max-width: 400px;

              .editMode {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                max-width: 400px;
                top: 0;
                left: 0;
                width: 100%;
                height: 200px;
                margin-top: -70px;
                background: #fff;
                border-radius: 8px;
                form {
                  display: flex;
                  justify-content: center;
                  input {
                    font-size: 30px;
                    text-align: center;
                    width: 100%;
                    padding: 5px;
                    margin: 14px auto;
                    color: #3b4a68;
                    font-weight: 600;
                  }
                }
                .defaultBtn {
                  width: 180px;
                  margin: 0 auto;
                }
              }
            `}
          >
            <div
              className="editMode"
              css={`
                position: relative;
                height: 300px;
                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
                .closeBtn {
                  position: absolute;
                  top: 10px;
                  right: 10px;
                  cursor: pointer;
                }
                .colorPicker {
                  width: 60px;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  z-index: 10;
                  padding-top: 0px;
                  width: 100%;
                  margin-bottom: 5px;
                  p {
                    display: block;
                    padding: 1px;
                    text-align: center;
                    margin: 0;
                  }
                }
                form {
                  background: #fff;
                  padding: 20px;
                  border-radius: 8px;
                  max-width: 400px;
                  width: 100%;
                  flex-direction: column;
                  input[type="radio"]:checked,
                  input[type="radio"]:not(:checked) {
                    position: absolute;
                    left: -9999px;
                  }
                  input[type="radio"]:checked + label,
                  input[type="radio"]:not(:checked) + label {
                    position: relative;
                    padding-left: 28px;
                    cursor: pointer;
                    line-height: 10px;
                    display: inline-block;
                    color: #666;
                  }
                  input[type="radio"]:checked + label:before,
                  input[type="radio"]:not(:checked) + label:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 18px;
                    height: 18px;
                    border-radius: 3px;
                    background: #fff;
                  }
                  input[type="radio"]:checked + label:after,
                  input[type="radio"]:not(:checked) + label:after {
                    content: "";
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 4px;
                    left: 4px;
                    border-radius: 3px;
                    -webkit-transition: all 0.2s ease;
                    transition: all 0.2s ease;
                  }
                  .green input[type="radio"] + label:after {
                    background: #00ea9d;
                  }
                  .green input[type="radio"]:checked + label:after {
                    box-shadow: 0 0 7px 1px #00ea9d;
                  }
                  .yellow input[type="radio"] + label:after {
                    background: #fcb816;
                  }
                  .yellow input[type="radio"]:checked + label:after {
                    box-shadow: 0 0 7px 1px #fcb816;
                  }
                  .orange input[type="radio"] + label:after {
                    background: #fe7a30;
                    border: 2px solid #fe7a30;
                  }
                  .orange input[type="radio"]:checked + label:after {
                    box-shadow: 0 0 7px 1px #fe7a30;
                  }
                  button:disabled {
                    opacity: 0.4;
                    cursor: default;
                  }
                }
              `}
            >
              <div className="closeBtn" onClick={() => setEditField(false)}>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4008 2.10002L8.40078 7.00002L13.3008 11.9C13.7008 12.3 13.7008 12.9 13.3008 13.3C12.9008 13.7 12.3008 13.7 11.9008 13.3L7.00078 8.40002L2.10078 13.3C1.70078 13.7 1.10078 13.7 0.700781 13.3C0.300781 12.9 0.300781 12.3 0.700781 11.9L5.60078 7.00002L0.600781 2.10002C0.200781 1.70002 0.200781 1.10002 0.600781 0.700024C1.00078 0.300024 1.60078 0.300024 2.00078 0.700024L6.90078 5.60003L11.8008 0.700024C12.2008 0.300024 12.8008 0.300024 13.2008 0.700024C13.8008 1.00002 13.8008 1.70002 13.4008 2.10002Z"
                    fill="#3B4A68"
                  />
                </svg>
              </div>
              <Form onSubmit={onSubmit}>
                <div className="colorPicker">
                  <p className="orange">
                    <input
                      type="radio"
                      id={`color-${data.otsikko}-3`}
                      name={`color-${data.otsikko}`}
                      value="#FE7A30"
                      checked={data2?.color === "#FE7A30"}
                      onChange={colorHandler}
                    />
                    <label htmlFor={`color-${data.otsikko}-3`}></label>
                  </p>
                  <p className="yellow">
                    <input
                      type="radio"
                      id={`color-${data.otsikko}-2`}
                      name={`color-${data.otsikko}`}
                      value="#FCB816"
                      checked={data2?.color === "#FCB816"}
                      onChange={colorHandler}
                    />
                    <label htmlFor={`color-${data.otsikko}-2`}></label>
                  </p>
                  <p className="green">
                    <input
                      type="radio"
                      id={`color-${data.otsikko}-1`}
                      name={`color-${data.otsikko}`}
                      value="#00EA9D"
                      checked={data2?.color === "#00EA9D"}
                      onChange={colorHandler}
                    />
                    <label htmlFor={`color-${data.otsikko}-1`}></label>
                  </p>
                </div>
                <input
                  type="number"
                  name="luku"
                  ref={callbackRef}
                  value={data2?.luku}
                  onChange={handleChange}
                />
                <span
                  css={`
                    font-size: 13px;
                    font-family: roboto, sans-serif;
                    color: #474747;
                    margin-bottom: 10px;
                  `}
                >
                  Min: {data.min} Max: {data.max}
                </span>
                <button
                  type="submit"
                  disabled={!isSaveable}
                  className="defaultBtn saveBtn"
                >
                  Tallenna
                </button>
              </Form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gauge;
