import React, { useState } from "react";
import { css } from "styled-components/macro";
import theme from "../../../../styles/theme";
import ModalImage from "react-modal-image";
import Modal from "../../../components/Modal";
import EditCard from "./EditCard";
import { QuillContent } from "../../../components/Quill";
import { UploadedImageKeyToURL } from "./EditCard";
import { LoadAuthor } from "../../../account/components/DataLoaders";
import Discussion from "./Discussion";

const CardSplitted = ({
  isSplitted,
  data,
  dragHandleProps,
  index,
  removeHandler,
  saveChanges,
  customerSlug,
  readOnly,
}) => {
  const [showModal, setShowModal] = useState(false);
  function onClose() {
    setShowModal(false);
  }

  return (
    <>
      <div
        {...dragHandleProps}
        css={`
          margin: 0 auto;
          padding-bottom: 20px;
          display: flex;
          max-width: 984px;
          flex-direction: column;
          background: #ffffff;
          text-align: left;
          box-shadow: 0px 4px 12px rgba(59, 74, 104, 0.5);
          border-radius: 8px;
        `}
      >
        <div
          onClick={e => {
            if (readOnly) return;
            if (
              e.target.tagName === "A" ||
              e.target.classList.contains("preventEditOnClick") ||
              e.target.classList.contains("__react_modal_image__medium_img")
            ) {
              return;
            }
            {
              let el = e.target;
              while (el !== null) {
                if (el.classList?.contains("preventEditOnClick")) return;
                el = el.parentNode;
              }
            }
            e.preventDefault();
            setShowModal(true);
          }}
          css={`
            cursor: ${readOnly ? "auto" : "pointer !important"};
          `}
        >
          <div
            css={`
              display: flex;
              align-items: center;
              background: #f4f5f8;
              height: 64px;
              border-radius: 8px 8px 0px 0px;
            `}
          >
            <h3
              css={`
                font-weight: 600;
                font-size: 18px;
                line-height: 20px;
                color: #3b4a68;
                margin-left: 20px;
              `}
            >
              {data.title}
            </h3>
          </div>
          {typeof data.author !== "undefined" &&
            typeof data.author.email === "undefined" && (
              <div
                css={`
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  padding: 20px 0 6px;
                  font-family: hero-new;
                `}
              >
                <div
                  css={`
                    min-width: 88px;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                  `}
                >
                  <img
                    css={`
                      border-radius: 50%;
                      width: 48px;
                    `}
                    src={data.author.picture.url}
                    alt={data.author.name}
                  />
                </div>
                <div
                  css={`
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                  `}
                >
                  <span
                    css={`
                      display: inline-block;
                      width: 100%;
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 20px;
                      color: #3b4a68;
                    `}
                  >
                    {data.author.name}
                  </span>
                  <span
                    css={`
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 20px;
                      color: #3b4a68;
                      opacity: 0.6;
                    `}
                  >
                    {data.author.title}
                  </span>
                </div>
              </div>
            )}
          {typeof data.author !== "undefined" &&
            typeof data.author.email !== "undefined" && (
              <LoadAuthor
                authorEmail={data.author.email}
                customerSlug={customerSlug}
              >
                {({ name, title, avatarUrl }) => (
                  <>
                    <div
                      css={`
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        padding: 20px 0 6px;
                        font-family: hero-new;
                      `}
                    >
                      <div
                        css={`
                          min-width: 88px;
                          display: flex;
                          align-items: flex-start;
                          justify-content: center;
                        `}
                      >
                        {avatarUrl !== null ? (
                          <UploadedImageKeyToURL Key={avatarUrl}>
                            {({ url }) => (
                              <div
                                css={`
                                  display: block;
                                  width: 48px;
                                  height: 48px;
                                  background: url(${url});
                                  background-position: 50% 50%;
                                  background-size: cover;
                                  background-repeat: no-repeat;
                                  margin-left: 8px;
                                  margin-right: 8px;
                                  border-radius: 50%;
                                `}
                              />
                            )}
                          </UploadedImageKeyToURL>
                        ) : (
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M16 8C16 10.21 14.21 12 12 12C9.79004 12 8 10.21 8 8C8 5.78998 9.79004 4 12 4C14.21 4 16 5.78998 16 8ZM4 18C4 15.34 9.33008 14 12 14C14.6699 14 20 15.34 20 18V20H4V18Z"
                              fill={theme.blue}
                              fillOpacity="0.34"
                            />
                          </svg>
                        )}
                      </div>
                      <div
                        css={`
                          display: flex;
                          justify-content: center;
                          flex-direction: column;
                        `}
                      >
                        <span
                          css={`
                            display: inline-block;
                            width: 100%;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 20px;
                            color: #3b4a68;
                          `}
                        >
                          {name}
                        </span>
                        <span
                          css={`
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 20px;
                            color: #3b4a68;
                            opacity: 0.6;
                          `}
                        >
                          {title}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </LoadAuthor>
            )}
          <div
            css={`
              width: 100%;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
            `}
          >
            <div
              css={`
                width: 88px;
                ${theme.mobile} {
                  display: none;
                }
                ${isSplitted &&
                css`
                  ${theme.splitScreen} {
                    display: none;
                  }
                `}
              `}
            />
            <div
              css={`
                flex: 1;
                font-family: Roboto;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                color: #3b4a68;
                ${theme.mobile} {
                  flex-basis: 100%;
                  padding-left: 20px;
                  padding-right: 20px;
                }
                ${isSplitted &&
                css`
                  ${theme.splitScreen} {
                    flex-basis: 100%;
                    padding-left: 20px;
                    padding-right: 20px;
                  }
                `}
              `}
            >
              <QuillContent delta={data.content} />
              <div style={{ marginBottom: 32 }} />
            </div>

            <div
              css={`
                flex: 1;
                padding: 16px 15px 15px 32px;
                ${theme.mobile} {
                  padding: 20px;
                }
                ${isSplitted &&
                css`
                  ${theme.splitScreen} {
                    padding: 20px;
                  }
                `}
              `}
            >
              {data.pictures.map((item, index) => (
                <React.Fragment key={index}>
                  {typeof item.Key !== "undefined" && (
                    <div
                      css={`
                        border: 1px solid #ebebeb;
                        box-sizing: border-box;
                        border-radius: 8px;
                        padding: 12px;
                        margin-bottom: 20px;
                        img {
                          border-radius: 8px;
                        }
                      `}
                    >
                      <UploadedImageKeyToURL Key={item.Key}>
                        {({ url }) => (
                          <>
                            <ModalImage
                              className="preventEditOnClick"
                              small={url}
                              large={url}
                            />
                          </>
                        )}
                      </UploadedImageKeyToURL>
                      {item.url && (
                        <div
                          css={`
                            display: flex;
                            align-items: center;
                            padding-top: 10px;
                            border-radius: 0px 0px 8px 8px;
                          `}
                        >
                          <a
                            className="preventEditOnClick"
                            css={`
                              margin-left: auto;
                              margin-right: 0;
                              padding-left: 10px;
                              padding-right: 10px;
                              background: #ebecf1;
                              border-radius: 8px;
                              height: 32px;
                              display: flex;
                              align-items: center;
                              text-decoration: none;
                              &:hover {
                                background: #ffffff;
                                box-shadow: 0px 3px 6px rgba(59, 74, 104, 0.2);
                                transition: all 0.2s ease-in-out;
                              }
                            `}
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.title && (
                              <span
                                className="preventEditOnClick"
                                css={`
                                  font-family: "hero-new", sans-serif;
                                  font-weight: 600;
                                  font-size: 14px;
                                  color: ${theme.WDSDarkBlueGrey};
                                  margin-right: 10px;
                                `}
                              >
                                {item.title}
                              </span>
                            )}

                            <svg
                              className="preventEditOnClick"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19 18V13C19 12.5 19.5 12 20 12C20.5 12 21 12.5 21 13V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3H11C11.5 3 12 3.5 12 4C12 4.6 11.5 5 11 5H6C5.4 5 5 5.4 5 6V18C5 18.6 5.4 19 6 19H18C18.6 19 19 18.6 19 18ZM15 3H20C20.5 3 21 3.5 21 4V9C21 9.6 20.5 10 20 10C19.4 10 19 9.6 19 9V6.4L9.9 15.5C9.5 15.9 8.9 15.9 8.4 15.5C8 15.1 8 14.5 8.4 14L17.6 5H15C14.4 5 14 4.5 14 4C14 3.5 14.4 3 15 3Z"
                                fill="#3B4A68"
                              />
                            </svg>
                          </a>
                        </div>
                      )}
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
          <Discussion customerSlug={customerSlug} id={`card-${data.id}`} />
        </div>
      </div>
      {showModal && (
        <Modal onClose={onClose}>
          <EditCard
            intention="editCard"
            data={data}
            index={index}
            saveChanges={saveChanges}
            removeHandler={removeHandler}
            closeModal={onClose}
            customerSlug={customerSlug}
          />
        </Modal>
      )}
    </>
  );
};

export default CardSplitted;
