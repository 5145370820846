import "styled-components/macro";
import { Form } from "../../../components/Form";
import { Link } from "react-router-dom";

const MarkkinointiIndexAdmin = ({
  data,
  setMarkIndex,
  markIndexDescription,
  setMarkIndexDescription,
  globalData,
}) => {
  const handleChange = (e, questionId) => {
    let key = e.target.name;
    let value = e.target.value;

    if (data?.find(question => question.id === questionId)) {
      setMarkIndex(
        data?.map(question =>
          question.id === questionId ? { ...question, [key]: value } : question
        )
      );
    } else {
      if (!data) {
        setMarkIndex([{ id: questionId, [key]: value }]);
        return;
      }
      setMarkIndex([...data, { id: questionId, [key]: value }]);
    }
  };

  const handleDescriptionChange = e => {
    let value = e.target.value;
    setMarkIndexDescription({ description: value });
  };

  const handleIsActive = (e, questionId, isActive) => {
    if (data?.find(question => question.id === questionId)) {
      setMarkIndex(
        data?.map(question =>
          question.id === questionId
            ? { ...question, isActive: !isActive }
            : question
        )
      );
    } else {
      if (!data) {
        setMarkIndex([{ id: questionId, isActive: !isActive }]);
        return;
      }
      setMarkIndex([...data, { id: questionId, isActive: !isActive }]);
    }
  };

  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
        span {
          font-family: hero-new, sans-serif;
          font-size: 15px;
        }
        .card {
          box-shadow: 0px 4px 12px rgba(181, 190, 207, 0.5);
          border-radius: 8px;
          background: #fff;
          margin-top: 20px;
          margin-bottom: 20px;
          background: #f4f5f8;

          > div {
            display: flex;
            padding-left: 15px;
            padding-right: 15px;
          }
        }
        .cardHeader {
          height: 64px;
          background: #fff;
          align-items: center;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          border-bottom: 1px solid rgba(181, 190, 207, 0.5);
          h2 {
            font-size: 18px;
            font-weight: 500;
          }
        }
        .cardContent {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          padding-top: 40px;
          padding-bottom: 40px;
          padding-left: 10px;
          padding-right: 10px;
          max-width: 1000px;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          &: ;
        }
        .defaultBtn {
          margin-top: 10px;
        }
      `}
    >
      <div className="card">
        <div className="cardHeader">
          <h2>Markkinointi-indeksi</h2>
        </div>
        <div className="cardContent">
          <Form>
            <div
              css={`
                padding-top: 40px;
                padding-bottom: 20px;
                padding-left: 0;
                padding-right: 0;
                margin: 0 auto;
                max-width: 1000px;
                display: flex;
                flex-direction: column;
                input {
                  margin-top: 6px;
                }
              `}
            >
              <span>Kuvaus</span>

              <input
                type="text"
                name="description"
                onChange={e => handleDescriptionChange(e)}
                value={markIndexDescription?.description || ""}
              />
            </div>

            {globalData.sections.map((section, index) => {
              return (
                <div
                  css={`
                    display: flex;
                    flex-direction: column;
                    border-radius: 8px;
                    margin-bottom: 40px;
                    .row {
                      display: flex;
                      align-items: center;
                      padding-top: 8px;
                      line-height: 20px;
                      gap: 5px;
                      > div {
                        box-sizing: border-box;
                      }
                      > div:nth-child(1) {
                        flex: 1;
                      }
                      > div:nth-child(2) {
                        flex-basis: 90px;
                      }
                      > div:nth-child(3) {
                        flex: 1;
                      }
                      > div:nth-child(4) {
                        flex-basis: 60px;
                      }
                    }
                    .row:first-child {
                      font-weight: 400;
                      border-bottom: 1px solid rgba(59, 74, 104, 0.1);
                      padding-bottom: 4px;
                      span {
                        font-size: 15px;
                      }
                    }
                    .row > div:first-child {
                      padding-left: 0;
                    }
                    input.readOnly {
                      background: #f4f5f8;
                      &:focus {
                        box-shadow: none;
                        border-color: #bbc1e1;
                      }
                    }
                  `}
                >
                  <div className="row">
                    <div>
                      <span>
                        <strong>{section.title}</strong>
                      </span>
                    </div>
                    <div>
                      <span>Arvo</span>
                    </div>
                    <div>
                      <span>Asiakaskohtainen huomio</span>
                    </div>
                    <div></div>
                  </div>

                  {globalData.questions
                    .filter(question => question.parent?.id === section.id)
                    .map((filteredQuestion, index) => {
                      // Get customer values for the questions

                      const questionAnswer = data?.find(
                        item => filteredQuestion.id === item.id
                      );

                      return (
                        <div key={filteredQuestion.id} className="row item">
                          <div>
                            <input
                              className="readOnly"
                              type="text"
                              value={filteredQuestion.question}
                              readOnly
                            />
                          </div>
                          <div>
                            <input
                              type="number"
                              name="value"
                              min="0"
                              max={filteredQuestion.maxPoints}
                              onChange={e =>
                                handleChange(e, filteredQuestion.id)
                              }
                              placeholder={`0–${filteredQuestion.maxPoints}`}
                              value={questionAnswer?.value || ""}
                            />
                          </div>
                          <div>
                            <input
                              type="text"
                              name="comment"
                              onChange={e =>
                                handleChange(e, filteredQuestion.id)
                              }
                              value={questionAnswer?.comment || ""}
                            />
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              className="switch"
                              name="isActive"
                              onChange={e =>
                                handleIsActive(
                                  e,
                                  filteredQuestion.id,
                                  questionAnswer?.isActive
                                )
                              }
                              checked={questionAnswer?.isActive}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              );
            })}
          </Form>
          <Link
            to="/dashboard/yrityksen-tiedot/markkinointi-indeksi"
            css={`
              font-weight: 400;
              font-size: 14px !important;
              text-decoration: underline !important;
              display: inline-flex;
              margin-top: 60px;
              svg {
                margin-left: 5px;
              }
            `}
          >
            Lisää ja muokkaa kysmyksiä
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MarkkinointiIndexAdmin;
