import "styled-components/macro";
import { useAuthors } from "../../report/components/EditCard";

const Markkinointitiimi: React.FC<{
  team: Array<any>;
  setTeam: (arg0: Array<any>) => void;
}> = ({ team, setTeam }) => {
  const authors:
    | Array<{ name: string; email: string; title: string }>
    | undefined = useAuthors();
  if (typeof authors === "undefined") return <></>;
  return (
    <div
      id="tiimi"
      css={`
        display: flex;
        flex-direction: column;
        .card {
          box-shadow: 0px 4px 12px rgba(181, 190, 207, 0.5);
          border-radius: 8px;
          background: #fff;
          margin-top: 20px;
          margin-bottom: 20px;
          background: #f4f5f8;

          > div {
            display: flex;
            padding-left: 15px;
            padding-right: 15px;
          }
        }
        .cardHeader {
          height: 64px;
          background: #fff;
          align-items: center;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          border-bottom: 1px solid rgba(181, 190, 207, 0.5);
          h2 {
            font-size: 18px;
            font-weight: 500;
          }
        }
        .cardContent {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          padding-top: 40px;
          padding-bottom: 100px;
          padding-left: 10px;
          padding-right: 10px;
          max-width: 1000px;
          margin: 0 auto;
        }
        .defaultBtn {
          margin-top: 10px;
        }
      `}
    >
      <div className="card">
        <div className="cardHeader">
          <h2>Markkinointitiimi</h2>
        </div>
        <div className="cardContent">
          <div
            css={`
              flex: 1;
              display: grid;
              grid-gap: 24px 16px;
              grid-template-columns: repeat(2, 1fr);
              > a {
                background: #ffffff;
                border: 1px solid #b5becf;
                box-sizing: border-box;
                border-radius: 6px;
                padding: 16px;
              }
            `}
          >
            {authors.map(({ name, email, title }) => (
              /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
              <a
                href=""
                css={`
                  display: grid;
                  grid-gap: 8px 16px;
                  grid-template: "kuva nimi valinta" "titteli titteli titteli";
                  grid-template-columns: min-content 1fr min-content;
                `}
                onClick={e => {
                  let node: HTMLElement | null = e.target as HTMLElement;

                  for (;;) {
                    if (node.classList.contains("titteli")) {
                      e.preventDefault();
                      return;
                    }
                    node = node.parentElement;
                    if (node === null) break;
                  }
                  e.preventDefault();
                  if (
                    typeof team.find(v => v.email === email) !== "undefined"
                  ) {
                    setTeam(team.filter(v => v.email !== email));
                  } else {
                    setTeam([...team, { email }]);
                  }
                }}
              >
                <div style={{ gridArea: "kuva" }}>
                  <span
                    css={`
                      display: block;
                      width: 40px;
                      height: 40px;
                      background: #3b4a68;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      border-radius: 50%;
                      margin-top: -8px;
                    `}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16 8C16 10.21 14.21 12 12 12C9.79004 12 8 10.21 8 8C8 5.78998 9.79004 4 12 4C14.21 4 16 5.78998 16 8ZM4 18C4 15.34 9.33008 14 12 14C14.6699 14 20 15.34 20 18V20H4V18Z"
                        fill="#fff"
                      />
                    </svg>
                  </span>
                </div>
                <div style={{ gridArea: "nimi" }}>
                  <p
                    css={`
                      font-family: hero-new;
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 22px;
                      color: #324755;
                      margin: 0;
                      margin-top: 2px;
                    `}
                  >
                    {name}
                  </p>
                </div>
                <div style={{ gridArea: "valinta" }}>
                  {typeof team.find(v => v.email === email) === "undefined" && (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="1"
                        y="1"
                        width="22"
                        height="22"
                        rx="3"
                        stroke="#B5BECF"
                        stroke-width="2"
                      />
                    </svg>
                  )}
                  {typeof team.find(v => v.email === email) !== "undefined" && (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="24" height="24" rx="4" fill="#00EA9D" />
                      <path
                        d="M19.0698 5.64014L9.16977 15.5401L4.92977 11.2901L3.50977 12.7101L9.16977 18.3601L20.4898 7.05014L19.0698 5.64014Z"
                        fill="white"
                      />
                    </svg>
                  )}
                </div>
                {typeof team.find(v => v.email === email) !== "undefined" && (
                  <div style={{ gridArea: "titteli" }} className="titteli">
                    <input
                      css={`
                        display: block;
                        width: 100%;
                        background: rgba(181, 190, 207, 0.2);
                        background: rgba(181, 190, 207, 0.2)
                          url(data:image/svg+xml;base64,${btoa(
                            `
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.75">
<path d="M3 17.4601V20.5001C3 20.7801 3.22 21.0001 3.5 21.0001H6.54C6.67 21.0001 6.8 20.9501 6.89 20.8501L17.81 9.94006L14.06 6.19006L3.15 17.1001C3.05 17.2001 3 17.3201 3 17.4601ZM20.71 7.04006C21.1 6.65006 21.1 6.02006 20.71 5.63006L18.37 3.29006C17.98 2.90006 17.35 2.90006 16.96 3.29006L15.13 5.12006L18.88 8.87006L20.71 7.04006Z" fill="#3B4A68"/>
</g>
</svg>
`.trim()
                          )});
                        background-repeat: no-repeat;
                        background-position: right 8px center;
                        border: none;
                        border-radius: 4px;
                        font-family: hero-new;
                        font-size: 16px;
                        line-height: 22px;
                        display: flex;
                        align-items: center;
                        color: #3b4a68;
                        padding: 8px 16px;
                        &::placeholder {
                          color: #3b4a68;
                          opacity: 0.4;
                        }
                      `}
                      spellCheck="false"
                      autoCapitalize="no"
                      autoCorrect="no"
                      placeholder={title || "(ei oletustitteliä)"}
                      value={team.find(v => v.email === email).title || ""}
                      onChange={e => {
                        setTeam(
                          team.map(v =>
                            v.email === email
                              ? e.target.value === ""
                                ? { email }
                                : { email, title: e.target.value }
                              : v
                          )
                        );
                      }}
                    />
                  </div>
                )}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Markkinointitiimi;
