import React from "react";
import "styled-components/macro";
import Icon from "../icons";

const MenuBurger = props => {
  const [showMenu, setShowMenu] = React.useState(false);
  const onClick = () => setShowMenu(!showMenu);

  return (
    <div
      className="burgerMenu"
      css={`
        position: relative;
        margin-left: auto;
      `}
    >
      <div
        onClick={onClick}
        css={`
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          background: #fff;
          border-radius: 50%;
          cursor: pointer;
        `}
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 7H13V9H17C18.65 9 20 10.35 20 12C20 13.65 18.65 15 17 15H13V17H17C19.76 17 22 14.76 22 12C22 9.24 19.76 7 17 7Z"
            fill="#3E4A66"
            fillOpacity="1"
          />
          <path
            d="M11 15H7C5.35 15 4 13.65 4 12C4 10.35 5.35 9 7 9H11V7H7C4.24 7 2 9.24 2 12C2 14.76 4.24 17 7 17H11V15Z"
            fill="#3E4A66"
            fillOpacity="1"
          />
          <path d="M8 11H16V13H8V11Z" fill="#3E4A66" />
        </svg>
      </div>
      {showMenu && (
        <div
          css={`
            position: absolute;
            background: #fff;
            top: 0;
            right: 0;

            box-shadow: 0px 4px 12px rgba(59, 74, 104, 0.3);
            border-radius: 8px;
            z-index: 10;
          `}
        >
          <div
            css={`
              display: flex;
              justify-content: center;
              align-items: center;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin-left: auto;
              cursor: pointer;
            `}
            onClick={onClick}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.4008 2.10002L8.40078 7.00002L13.3008 11.9C13.7008 12.3 13.7008 12.9 13.3008 13.3C12.9008 13.7 12.3008 13.7 11.9008 13.3L7.00078 8.40002L2.10078 13.3C1.70078 13.7 1.10078 13.7 0.700781 13.3C0.300781 12.9 0.300781 12.3 0.700781 11.9L5.60078 7.00002L0.600781 2.10002C0.200781 1.70002 0.200781 1.10002 0.600781 0.700024C1.00078 0.300024 1.60078 0.300024 2.00078 0.700024L6.90078 5.60003L11.8008 0.700024C12.2008 0.300024 12.8008 0.300024 13.2008 0.700024C13.8008 1.00002 13.8008 1.70002 13.4008 2.10002Z"
                fill="#3B4A68"
              />
            </svg>
          </div>
          <Icon name="notes" />
          <ul
            css={`
              list-style: none;
              padding-inline-start: 0;
              margin-top: 24px;
              margin-left: 0;
              li {
                height: 54px;
                padding-left: 20px;
                padding-right: 20px;
              }
              a {
                display: flex;
                align-items: center;
                text-decoration: none;
              }
              a:hover {
                opacity: 0.8;
              }
              svg {
                margin-right: 16px;
              }
              span {
                vertical-align: 72%;
                font-family: Roboto;
                font-weight: 500;
                font-size: 18px;
                line-height: 20px;
                color: #3b4a68;
                white-space: nowrap;
              }
            `}
          >
            {props.data.map((item, index) => {
              return (
                <li>
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    <Icon name={item.icon} />
                    <span>{item.title}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MenuBurger;
