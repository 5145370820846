import React from "react";
import "styled-components/macro";
import theme from "../../../styles/theme";
import { gql, useMutation } from "@apollo/client";
import { SessionContext } from "../../public/login";

const ReadOnlyUserDropdown = () => {
  const [showMenu, setShowMenu] = React.useState(false);
  const onClick = () => setShowMenu(!showMenu);

  const { email } = React.useContext(SessionContext);

  const [logout] = useMutation(
    gql`
      mutation Logout {
        user_logout
      }
    `
  );

  return (
    <div
      css={`
        position: relative;
        margin-left: auto;
      `}
    >
      <div
        onClick={onClick}
        css={`
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          background: #3b4a68;
          color: #fff;
          font-weight: 500;
          border-radius: 50%;
          cursor: pointer;
        `}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 8C16 10.21 14.21 12 12 12C9.79004 12 8 10.21 8 8C8 5.78998 9.79004 4 12 4C14.21 4 16 5.78998 16 8ZM4 18C4 15.34 9.33008 14 12 14C14.6699 14 20 15.34 20 18V20H4V18Z"
            fill="#fff"
            fillOpacity="0.8"
          />
        </svg>
      </div>
      {showMenu && (
        <div
          css={`
            position: absolute;
            background: #fff;
            top: 0;
            right: 0;
            max-width: 310px;
            box-shadow: 0px 4px 12px rgba(59, 74, 104, 0.3);
            border-radius: 8px;
            ${theme.headingFont}
            color: ${theme.blue};
            font-size: 14px;
            z-index: 1000;
            .closeBtn {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin-left: auto;
              cursor: pointer;
            }
            .content {
              display: flex;
              flex-direction: column;
              align-items: center;
              p,
              span {
                padding-left: 15px;
                padding-right: 15px;
                line-height: 1.4;
                text-align: center;
              }
            }

            .email {
              margin: 6px 0 16px;
              opacity: 0.5;
            }
            .logoutBtn {
              display: flex;
              width: 100%;
              padding: 25px 15px;
              justify-content: center;
              align-items: center;
              border-radius-bottom-left: 8px;
              border-radius-bottom-right: 8px;
              background: none;
            }
            .logoutBtn:hover {
              background: rgba(234, 237, 242, 0.5);
              transition: background 0.2s;
            }
          `}
        >
          <div className="closeBtn" onClick={onClick}>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.4008 2.10002L8.40078 7.00002L13.3008 11.9C13.7008 12.3 13.7008 12.9 13.3008 13.3C12.9008 13.7 12.3008 13.7 11.9008 13.3L7.00078 8.40002L2.10078 13.3C1.70078 13.7 1.10078 13.7 0.700781 13.3C0.300781 12.9 0.300781 12.3 0.700781 11.9L5.60078 7.00002L0.600781 2.10002C0.200781 1.70002 0.200781 1.10002 0.600781 0.700024C1.00078 0.300024 1.60078 0.300024 2.00078 0.700024L6.90078 5.60003L11.8008 0.700024C12.2008 0.300024 12.8008 0.300024 13.2008 0.700024C13.8008 1.00002 13.8008 1.70002 13.4008 2.10002Z"
                fill="#3B4A68"
              />
            </svg>
          </div>
          <div className="content">
            <p>
              Olet kirjautunut ja sinulla on lukuoikeudet. Voit myös muokata
              muistiinpanoja.
            </p>
            <span className="email">{email}</span>

            <button
              className="logoutBtn"
              onClick={e => {
                e.preventDefault();
                (async () => {
                  const {
                    data: { user_logout },
                  } = await logout();
                  if (user_logout === 0) window.location.reload();
                })();
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.79 16.29C11.18 16.68 11.81 16.68 12.2 16.29L15.79 12.7C16.18 12.31 16.18 11.68 15.79 11.29L12.2 7.7C11.81 7.31 11.18 7.31 10.79 7.7C10.4 8.09 10.4 8.72 10.79 9.11L12.67 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13H12.67L10.79 14.88C10.4 15.27 10.41 15.91 10.79 16.29ZM19 3H5C3.89 3 3 3.9 3 5V8C3 8.55 3.45 9 4 9C4.55 9 5 8.55 5 8V6C5 5.45 5.45 5 6 5H18C18.55 5 19 5.45 19 6V18C19 18.55 18.55 19 18 19H6C5.45 19 5 18.55 5 18V16C5 15.45 4.55 15 4 15C3.45 15 3 15.45 3 16V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
                  fill="#3B4A68"
                />
              </svg>
              <span>Kirjaudu ulos</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReadOnlyUserDropdown;
