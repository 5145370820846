import React, { useState, useCallback } from "react";
import "styled-components/macro";
import { useDropzone } from "react-dropzone";
import { Form } from "../../../components/Form";
import Select from "react-select";
import { useQuery, useMutation, gql } from "@apollo/client";
import Quill from "../../../components/Quill";
import short from "short-uuid";

export const useAuthors = () => {
  const { loading, data } = useQuery(
    gql`
      query {
        allAuthors {
          email
          name
          title
        }
      }
    `,
    {
      variables: {},
    }
  );
  if (loading) return undefined;
  return data.allAuthors;
};

export const UploadedImageKeyToURL = ({ Key, children }) => {
  const { loading, error, data, refetch } = useQuery(
    gql`
      query ($Key: String!) {
        fileURL(Key: $Key)
      }
    `,
    {
      variables: {
        Key,
      },
    }
  );
  const [size, setSize] = React.useState(undefined);
  React.useLayoutEffect(() => {
    if (typeof data === "undefined") return;
    if (data === null) return;
    let cancel = false;
    const img = new Image();
    img.onload = function () {
      if (cancel) return;
      const { width, height } = this;
      setSize({ width, height });
    };
    img.src = data.fileURL;
    /* data.fileURL is signed and expires in 15 minutes. Renew url every 15min - 3s: */
    const tid = setTimeout(() => {
      refetch();
    }, 15 * 60 * 1000 - 3000);
    return () => {
      cancel = true;
      clearTimeout(tid);
    };
  }, [data, refetch]);
  if (loading) return null;
  if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;
  if (typeof size === "undefined") return null;
  return <>{children({ url: data.fileURL, ...size })}</>;
};

// Todo: Focus when open + Image data Cardiin + image preview editissä

// Validation todo: author check not working correctly +  content validation ei tajua jos täyttänyt ja tyhjentänyt kentänc

const EditCard = ({
  intention,
  data,
  index,
  saveChanges,
  removeHandler,
  addCard,
  closeModal,
  customerSlug,
}) => {
  const initialCard =
    intention === "editCard"
      ? data
      : { __typename: "CommentRecord", pictures: [] };

  const [card, setCard] = useState(initialCard);
  const [messages, setMessages] = useState({});

  const isEditing = intention !== "createCard";

  const handleChange = event => {
    let key = event.target.name;
    let value = event.target.value;
    setCard({ ...card, [key]: value });
  };

  const handleChangeSelect = (selection, action) => {
    if (selection === null) {
      setCard({ ...card, author: undefined });
      return;
    }
    setCard({ ...card, author: { email: selection.value } });
  };

  const handleSubmitNew = event => {
    event.preventDefault();

    card.id = short.generate();

    if (validate()) {
      closeModal();
      addCard(card);
    }
  };

  const handleSubmitEdit = event => {
    event.preventDefault();
    closeModal();
    saveChanges(card, index);
  };

  const callbackRef = useCallback(inputElement => {
    if (inputElement) {
      setTimeout(() => {
        inputElement.focus();
      }, 0);
    }
  }, []);

  // Validation

  const validate = () => {
    let isValid = true;
    let errMessages = {};

    if (card.title === "") {
      isValid = false;
      errMessages["title"] = "Lisää otsikko";
    } else {
      errMessages["title"] = null;
    }

    if (typeof card.author === "undefined") {
      isValid = false;
      errMessages["author"] = "Lisää kirjoittaja";
    } else {
      errMessages["author"] = null;
    }

    if (
      typeof card.content === "undefined" &&
      (card.pictures || []).length === 0
    ) {
      isValid = false;
      errMessages["textOrPicture"] = "Lisää kommentti ja/tai kuva";
    } else {
      errMessages["textOrPicture"] = null;
    }

    setMessages(errMessages);

    return isValid;
  };

  // Dropzone

  const removeImage = index => {
    let pictures = [...card.pictures];
    pictures.splice(index, 1);
    setCard({ ...card, pictures });
  };

  {
    const [uploadFile] = useMutation(
      gql`
        mutation ($customer: String!, $name: String!, $type: String!) {
          uploadFile(customer: $customer, name: $name, type: $type) {
            signedURL
            Key
          }
        }
      `
    );
    React.useLayoutEffect(() => {
      const uploads = [];
      const pictures = (card.pictures || []).map(v => {
        if (
          typeof v.Key !== "undefined" ||
          v.uploading ||
          typeof v.error !== "undefined"
        )
          return v;
        uploads.push(v);
        return { ...v, uploading: true };
      });
      if (uploads.length === 0) return;
      setCard({ ...card, pictures });
      uploads.forEach(async ({ file, preview }) => {
        const {
          data: {
            uploadFile: { signedURL, Key },
          },
        } = await uploadFile({
          variables: {
            customer: customerSlug,
            name: file.name,
            type: file.type,
          },
        });
        const uploadResponse = await fetch(signedURL, {
          method: "PUT",
          body: file,
        });
        if (uploadResponse.status !== 200) {
          setCard(card => ({
            ...card,
            pictures: card.pictures.map(v =>
              v.preview === preview
                ? { ...v, uploading: false, error: uploadResponse.statusText }
                : v
            ),
          }));
          return;
        }
        setCard(card => ({
          ...card,
          pictures: card.pictures.map(v =>
            v.preview === preview ? { Key } : v
          ),
        }));
      });
    }, [card, customerSlug, uploadFile]);
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".jpg", ".jpeg", ".png"],
    },
    onDrop: acceptedFiles => {
      setCard(card => ({
        ...card,
        pictures: [
          ...(card.pictures || []),
          ...acceptedFiles.map(file => ({
            preview: URL.createObjectURL(file),
            file,
          })),
        ],
      }));
    },
  });

  const authors = useAuthors();
  const authorOptions = React.useMemo(
    () =>
      typeof authors !== "undefined"
        ? authors.map(item => {
            return {
              value: item.email,
              label: item.name || item.email,
            };
          })
        : undefined,
    [authors]
  );
  if (typeof authors === "undefined") return <></>;

  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;

        background: rgba(255, 255, 255, 0.2);
        form {
          display: flex;
          flex-direction: column;
          label,
          .FormDropzone,
          .FormThumbsContainer {
            margin-top: 28px;
          }
          .formMessages {
            text-align: center;
            p {
              margin: 5px 0;
              opacity: 0.7;
              font-size: 15px;
            }
          }
        }
        aside {
          display: flex;
          flex-direction: column;
          .FormThumb:hover .layerImage {
            display: flex;
            transition: all 0.2s;
          }
          .layerImage {
            display: none;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.2);
            justify-content: center;
            align-items: center;
            transition: all 0.2s;
            > .removeImage {
              width: 90%;
              height: 32px;
              font-size: 0.75em;
              border-radius: 16px;
              justify-content: center;
              cursor: pointer;
              display: flex;
              align-items: center;
              background: #fff;
              box-shadow: 0px 4px 8px rgba(59, 74, 104, 0.2);
              span {
                opacity: 0.8;
              }
            }
          }
          .imgItem {
            display: flex;
            width: 100%;
            > div {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              margin-bottom: 8px;
            }
            > div:nth-child(2) {
              flex: 1;
              label {
                margin-top: 0;
              }
              input {
                width: calc(50% - 5px);
              }
              input:first-child {
                margin-right: 10px;
              }
            }
          }
        }
      `}
    >
      <h3>{isEditing ? "Muokkaa korttia" : "Uusi kortti"}</h3>

      <Form noValidate autoComplete="off">
        <label htmlFor="title">Otsikko</label>
        <input
          id="title"
          name="title"
          type="text"
          ref={callbackRef}
          onChange={handleChange}
          value={card.title}
        />
        <label htmlFor="author">Kirjoittaja</label>
        <Select
          id="author"
          name="author"
          className="select-container"
          classNamePrefix="select"
          options={authorOptions}
          onChange={handleChangeSelect}
          defaultValue={
            authorOptions.find(v => v.value === card.author?.email) ||
            authorOptions.find(v => v.label === card.author?.name)
          }
          placeholder="Valitse..."
          isClearable
        />
        <label></label>
        <Quill
          value={card.content}
          placeholder="Kommentti"
          onChange={e => {
            setCard(card => ({ ...card, content: e.target.value }));
          }}
        />
        <aside className="FormThumbsContainer">
          {card.pictures?.map((file, index) => {
            return (
              <div className="imgItem">
                <div className="FormThumb">
                  <div className="layerImage">
                    <div
                      className="removeImage"
                      onClick={() => removeImage(index)}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3 10.5H9V3.5H3V10.5ZM9.5 2H7.75L7.25 1.5H4.75L4.25 2H2.5V3H9.5V2Z"
                          fill="#B5BECF"
                        />
                      </svg>
                      <span>Poista</span>
                    </div>
                  </div>
                  <div className="FormThumbInner">
                    {typeof file.preview !== "undefined" && (
                      <img src={file.preview} alt="" />
                    )}
                    {typeof file.Key !== "undefined" && (
                      <UploadedImageKeyToURL Key={file.Key}>
                        {({ url }) => <img src={url} alt="" />}
                      </UploadedImageKeyToURL>
                    )}
                  </div>
                  {file.uploading && <p>Tallennetaan...</p>}
                  {file.error && <p>Virhe: {file.error}</p>}
                </div>
                <div>
                  <label htmlFor={`imgLinkUrl-${index}`}>Kuvan linkki</label>
                  <div>
                    <input
                      type="text"
                      placeholder="Otsikko"
                      value={file.title}
                      onChange={e => {
                        setCard({
                          ...card,
                          pictures: card.pictures.map(v =>
                            v === file ? { ...v, title: e.target.value } : v
                          ),
                        });
                      }}
                    />
                    <input
                      id={`imgLinkUrl-${index}`}
                      type="text"
                      placeholder="URL"
                      value={file.url}
                      onChange={e => {
                        setCard({
                          ...card,
                          pictures: card.pictures.map(v =>
                            v === file ? { ...v, url: e.target.value } : v
                          ),
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </aside>
        <div {...getRootProps({ className: "FormDropzone" })}>
          <input {...getInputProps()} />
          <svg
            width="59"
            height="59"
            viewBox="0 0 59 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M51.6742 12.2915H46.7083V7.32567C46.7083 5.99817 45.6267 4.9165 44.2992 4.9165H44.2254C42.8733 4.9165 41.7917 5.99817 41.7917 7.32567V12.2915H36.8504C35.5229 12.2915 34.4413 13.3732 34.4167 14.7007V14.7744C34.4167 16.1265 35.4983 17.2082 36.8504 17.2082H41.7917V22.1494C41.7917 23.4769 42.8733 24.5832 44.2254 24.5586H44.2992C45.6267 24.5586 46.7083 23.4769 46.7083 22.1494V17.2082H51.6742C53.0017 17.2082 54.0833 16.1265 54.0833 14.799V14.7007C54.0833 13.3732 53.0017 12.2915 51.6742 12.2915ZM39.3333 22.1494V19.6665H36.8504C35.5475 19.6665 34.3183 19.1503 33.3842 18.2407C32.4746 17.3065 31.9583 16.0773 31.9583 14.7007C31.9583 13.8157 32.2042 13.0044 32.6221 12.2915H12.2917C9.5875 12.2915 7.375 14.504 7.375 17.2082V46.7082C7.375 49.4123 9.5875 51.6248 12.2917 51.6248H41.7917C44.4958 51.6248 46.7083 49.4123 46.7083 46.7082V26.3532C45.9708 26.7711 45.135 27.0415 44.2008 27.0415C41.5213 27.0169 39.3333 24.829 39.3333 22.1494ZM39.235 46.7082H14.75C13.7421 46.7082 13.1521 45.5528 13.7667 44.7415L18.6342 38.2761C19.1504 37.5878 20.1583 37.6369 20.65 38.3253L24.5833 44.2498L30.9996 35.6948C31.4913 35.0557 32.45 35.0311 32.9417 35.6703L40.1938 44.7169C40.8329 45.5282 40.2675 46.7082 39.235 46.7082V46.7082Z"
              fill="#B5BECF"
            />
          </svg>
          <p>Lisää kuva</p>
        </div>
        {!isEditing && (
          <button
            onClick={handleSubmitNew}
            type="submit"
            className="primaryBtn"
            disabled={
              !(card.pictures || []).every(
                ({ Key }) => typeof Key !== "undefined"
              )
            }
          >
            Lisää kortti
          </button>
        )}

        {isEditing && (
          <div
            css={`
              display: flex;
              position: relative;
              justify-content: center;
              align-items: flex-end;
              padding: 20px 0;
              button {
                margin-bottom: 0;
              }
              .removeCard {
                cursor: pointer;
                position: absolute;
                display: flex;
                align-items: center;
                padding: 10px;
                color: #697da2;
                svg {
                  margin-right: 6px;
                }
                &:hover {
                  opacity: 0.8;
                }
              }
              .removeCard {
                left: 0;
              }
            `}
          >
            <div
              className="removeCard"
              onClick={() => {
                if (!window.confirm("Haluatko varmasti poistaa kortin?"))
                  return;
                closeModal();
                removeHandler(index);
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 10.5H9V3.5H3V10.5ZM9.5 2H7.75L7.25 1.5H4.75L4.25 2H2.5V3H9.5V2Z"
                  fill="#697da2"
                />
              </svg>
              Poista kortti
            </div>
            <button
              className="primaryBtn"
              onClick={handleSubmitEdit}
              type="submit"
            >
              Tallenna
            </button>
          </div>
        )}

        <div className="formMessages">
          {messages.title && <p>{messages.title}</p>}
          {messages.author && <p>{messages.author}</p>}
          {messages.textOrPicture && <p>{messages.textOrPicture}</p>}
        </div>
      </Form>
    </div>
  );
};

export default EditCard;
