import { css } from "styled-components/macro";

const theme = {
  WDSDarkBlueGrey: "#3B4A68",
  blue: "#3B4A68",
  white: "#FFFFFF",
  mobile: "@media(max-width: 600px)",
  tablet: "@media(max-width: 900px)",
  splitScreen: "@media(max-width: 1240px)",
  headingFont: css`
    font-family: hero-new, sans-serif;
    font-style: normal;
    font-weight: 400;
  `,
};

export default theme;
