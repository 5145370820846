import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import LoginProvider, { SessionContext } from "./views/public/login";
import Organisation from "./views/account/components/_organisationAccount";
import "./styles/normalize.css";
import "./styles/global.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { LoadCompleteCustomerObject } from "./views/account/components/DataLoaders";
import { ToastProvider } from "react-toast-notifications";

const Admin = React.lazy(() => import("./Admin"));
const Index = React.lazy(() => import("./views/account"));

const RoutesBasedOnUserPermissions: React.FC = () => {
  const { permissions } = React.useContext(SessionContext) as any;
  const [logout] = useMutation(
    gql`
      mutation Logout {
        user_logout
      }
    `
  );
  if (permissions.length === 0) {
    return (
      <>
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <p>
                <h2>403 no permissions</h2>
                <button
                  onClick={e => {
                    e.preventDefault();
                    (async () => {
                      const {
                        data: { user_logout },
                      } = await logout();
                      if (user_logout === 0) window.location.reload();
                    })();
                  }}
                >
                  Logout
                </button>
              </p>
            )}
          />
          <Redirect to="/" />
        </Switch>
      </>
    );
  }
  const justReadonlyPermissions =
    permissions.every((v: string) => v.match(/^(.*?)\.read$/)) &&
    permissions.length > 0;
  if (justReadonlyPermissions) {
    /* probably a user with readOnly access to just one customer */
    const m = permissions[0].match(/^(.*?)\.read$/);
    return (
      <>
        <LoadCompleteCustomerObject slug={m[1]}>
          {({ customer }) => (
            <>
              <Switch>
                <Route
                  exact
                  path={`/${customer.slug}`}
                  render={() => (
                    <App key={customer.slug} customer={customer.slug} />
                  )}
                />
                {customer.summaries.map(({ slug }) => (
                  <Route
                    exact
                    path={`/${customer.slug}/${slug}`}
                    render={() => (
                      <App
                        key={customer.slug}
                        customer={customer.slug}
                        month={slug}
                      />
                    )}
                  />
                ))}
                <Redirect to={`/${m[1]}`} />
                <Route render={() => <p>404</p>} />
              </Switch>
            </>
          )}
        </LoadCompleteCustomerObject>
      </>
    );
  }
  return (
    <>
      <Switch>
        <Route
          path="/admin"
          render={() => (
            <React.Suspense fallback={<></>}>
              <Admin />
            </React.Suspense>
          )}
        />
        <Route
          path="/dashboard"
          render={() => (
            <React.Suspense fallback={<></>}>
              <Index />
            </React.Suspense>
          )}
        />
        <Route
          exact
          path="/:customer"
          render={({
            match: {
              params: { customer },
            },
          }) => <App key={customer} customer={customer} />}
        />
        <Route
          exact
          path="/:customer/organisation"
          render={() => <Organisation />}
        />
        <Route
          exact
          path="/:customer/:month"
          render={({
            match: {
              params: { customer, month },
            },
          }) => <App key={customer} customer={customer} month={month} />}
        />

        <Redirect to="/dashboard" />
      </Switch>
    </>
  );
};

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <ToastProvider placement="bottom-left">
    <Router>
      <LoginProvider>
        <RoutesBasedOnUserPermissions />
      </LoginProvider>
    </Router>
  </ToastProvider>
);
