import React from "react";
import "styled-components/macro";

const Icon = props => {
  switch (props.name) {
    case "Chess":
      return (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.6662 25.3333H23.3329V24.6666C28.3996 12.2666 22.7996 8.79996 18.2662 5.99996C16.3996 4.93329 13.0662 3.46662 12.5329 2.13329C12.2662 1.59996 11.3329 1.73329 11.3329 2.39996L13.4662 6.53329C13.4662 6.93329 9.06622 10.6666 7.59955 12C6.39955 13.0666 6.39955 15.0666 7.33289 16C8.53289 17.2 10.1329 17.0666 10.7996 16.5333C10.9329 16.4 11.5996 16 11.7329 15.7333C12.3996 15.2 13.3329 15.3333 13.8662 15.8666C14.3996 16.5333 14.2662 17.3333 13.8662 17.8666C12.2662 19.7333 11.1996 21.7333 10.6662 24V25.3333H9.33289C8.93289 25.3333 8.66622 25.6 8.66622 26V28.6666C8.66622 29.0666 8.93289 29.3333 9.33289 29.3333H24.6662C25.0662 29.3333 25.3329 29.0666 25.3329 28.6666V26C25.3329 25.6 25.0662 25.3333 24.6662 25.3333ZM14.1329 9.73329C14.1329 8.93329 14.7996 8.26662 15.5996 8.26662C16.3996 8.26662 17.0662 8.93329 17.0662 9.73329C17.0662 10.5333 16.3996 11.2 15.5996 11.2C14.7996 11.2 14.1329 10.5333 14.1329 9.73329Z"
            fill="#3B4A68"
          />
        </svg>
      );
    case "Chart":
      return (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.3321 5.33337C9.06546 5.73337 4.26546 11.6 5.59879 18.2667C6.39879 22.2667 9.73212 25.6 13.7321 26.4C20.3988 27.7334 26.2655 22.9334 26.6655 16.6667C26.6655 16.2667 26.3988 16 25.9988 16H16.6655C16.2655 16 15.9988 15.7334 15.9988 15.3334V6.00004C15.9988 5.60004 15.7321 5.33337 15.3321 5.33337Z"
            fill="#3B4A68"
          />
          <path
            d="M18.667 4V13.3333H28.0003C28.0003 8.13333 23.867 4 18.667 4Z"
            fill="url(#paint0_linear)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="18.667"
              y1="8.66693"
              x2="28.0003"
              y2="8.66693"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#12DEB5" />
              <stop offset="0.25" stopColor="#12DEB5" />
              <stop offset="1" stopColor="#12DEB5" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      );
    case "Document":
      return (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24 26.6667H13.3333C12.5333 26.6667 12 26.1333 12 25.3333V9.33333C12 8.53333 12.5333 8 13.3333 8H24C24.8 8 25.3333 8.53333 25.3333 9.33333V25.3333C25.3333 26.1333 24.8 26.6667 24 26.6667Z"
            fill="url(#paint0_linear)"
          />
          <path
            d="M18.667 24H8.00033C7.20033 24 6.66699 23.4667 6.66699 22.6667V6.66671C6.66699 5.86671 7.20033 5.33337 8.00033 5.33337H18.667C19.467 5.33337 20.0003 5.86671 20.0003 6.66671V22.6667C20.0003 23.4667 19.467 24 18.667 24Z"
            fill="#3B4A68"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="12"
              y1="17.3333"
              x2="25.3333"
              y2="17.3333"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#12DEB5" />
              <stop offset="0.25" stopColor="#12DEB5" />
              <stop offset="1" stopColor="#12DEB5" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      );
    case "Link":
      return (
        <svg
          width="32"
          height="32"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 7H13V9H17C18.65 9 20 10.35 20 12C20 13.65 18.65 15 17 15H13V17H17C19.76 17 22 14.76 22 12C22 9.24 19.76 7 17 7Z"
            fill="#3E4A66"
            fillOpacity="1"
          />
          <path
            d="M11 15H7C5.35 15 4 13.65 4 12C4 10.35 5.35 9 7 9H11V7H7C4.24 7 2 9.24 2 12C2 14.76 4.24 17 7 17H11V15Z"
            fill="#3E4A66"
            fillOpacity="1"
          />
          <path d="M8 11H16V13H8V11Z" fill="#12DEB5" />
        </svg>
      );
    default:
      return null;
  }
};

export default Icon;
