import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { css } from "styled-components/macro";
import theme from "../../../styles/theme";
import Gauge from "../components/Gauge";
import CardPicture from "./components/Picture";
import Card from "./components/Simple";
import CardSplitted from "./components/Splitted";
import Burger from "../../components/Navigation/Burger";
import ContentsMenu from "../../components/Navigation/ContentsMenu";
import SubHeading from "./components/SubHeading";
import Modal from "../../components/Modal";
import EditCard from "./components/EditCard";
import { Form } from "../../components/Form";
import ShowNotesContext from "../../../context/showNotesContext";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { SessionContext } from "../../public/login";
import { Helmet } from "react-helmet";
import short from "short-uuid";
import { LoadMittaritGlobal } from "../../account/components/DataLoaders";
import { Range } from "../home/Home";
import GaugeHome from "../components/GaugeHome";
import moment from "moment";
import "moment/locale/fi";
moment.locale("fi");

const Summary = ({ data, setData, sitemap }) => {
  const { permissions } = React.useContext(SessionContext);
  const edit = true;
  const readOnly = (permissions || []).indexOf(`${sitemap.slug}.write`) === -1;
  const [showModalNewComment, setShowModalNewComment] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [showMonthMenu, setShowMonthMenu] = useState(false);
  const [gaugesMode, setGaugesMode] = useState("this_month");
  const [addCardToIndex, setAddCardToIndex] = useState(null);

  const location = useLocation();
  const lastSlug = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );

  const isProjectReport = data.type === "projectReport";

  const gaugesFormulaHandler = event => {
    setGaugesMode(event.target.value);
  };

  useEffect(() => {
    let hasMissingIds = false;
    const data2 = JSON.parse(JSON.stringify(data));

    data2.comments.forEach(item => {
      if (typeof item.id !== "undefined") return;
      item.id = short.generate();
      hasMissingIds = true;
    });

    if (!hasMissingIds) return;

    setData(data2);
  }, [data, setData]);

  function addCard(comment) {
    let comments = [...data.comments];
    if (addCardToIndex === null) {
      comments.push({
        id: short.generate(),
        ...comment,
      });
    } else {
      comments = [
        ...comments.slice(0, addCardToIndex + 1),
        {
          id: short.generate(),
          ...comment,
        },
        ...comments.slice(addCardToIndex + 1),
      ];
    }

    setData({ ...data, comments });
  }

  function addHeading() {
    let comments = [...data.comments];
    comments.push({
      id: short.generate(),
      title: `Otsikko`,
      __typename: "SubheadingRecord",
    });

    setData({ ...data, comments });

    // Focus on New Heading
    let newHeadingId = `heading-${comments.length - 1}`;

    setTimeout(function () {
      let el = document.getElementById(newHeadingId);
      el.focus();
    }, 0);
  }

  function addHeadingBetween(index) {
    let comments = [...data.comments];
    const newHeading = {
      id: short.generate(),
      title: `Otsikko`,
      __typename: "SubheadingRecord",
    };

    comments = [
      ...comments.slice(0, index + 1),
      newHeading,
      ...comments.slice(index + 1),
    ];

    setData({ ...data, comments });

    // TODO: Focus on New Heading
    /*
    let newHeadingId = `heading-${index}`;

    setTimeout(function () {
      let el = document.getElementById(newHeadingId);
      el.focus();
    }, 0);*/
  }

  function handleOnDragEnd(result) {
    setIsDragging(false);
    if (!result.destination) return;

    const items = Array.from(data.comments);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setData({ ...data, comments: items });
  }

  function removeItem(index) {
    let comments = [...data.comments];
    comments.splice(index, 1);
    setData({ ...data, comments });
  }

  function saveChanges(item, index, dontUpdateUpdatedAt) {
    let comments = [...data.comments];
    comments[index] = item;

    setData(
      {
        ...data,
        comments,
      },
      dontUpdateUpdatedAt
    );
  }

  const showNotes = useContext(ShowNotesContext);

  function newComment() {
    setAddCardToIndex(null);
    setShowModalNewComment(true);
  }

  function newCommentBetween(index) {
    setAddCardToIndex(index);
    setShowModalNewComment(true);
  }

  function onClose() {
    setShowModalNewComment(false);
  }

  const monthReports = sitemap.summaries.filter(
    ({ type }) => type === "monthReport"
  );

  const prevMonth = monthReports.find(
    (v, k, self) => k < self.length - 1 && self[k + 1].slug === data.slug
  );
  const nextMonth = monthReports.find(
    (v, k, self) => k > 0 && self[k - 1].slug === data.slug
  );

  const allSubhedings = data.comments
    .filter(item => item.__typename === "SubheadingRecord")
    .map(item => item.title);

  const capitalize = str =>
    str.substring(0, 1).toUpperCase() + str.substring(1);

  const [rangeSelectOpen, setRangeSelectOpen] = React.useState(false);
  const [range, setRange] = React.useState(() => ({
    startMonth: `${moment().format("YYYY")}-01`,
    endMonth: moment().format("YYYY-MM"),
  }));
  React.useEffect(() => {
    setRangeSelectOpen(gaugesMode === "selected_range");
  }, [gaugesMode]);

  const onlyMonthReports = sitemap.summaries.filter(
    ({ type }) => type === "monthReport"
  );
  const [activeMonthIndex] = useState(onlyMonthReports.length - 1);

  return (
    <>
      {sitemap.gauges.length > 0 && (
        <section
          css={`
            background: #b5becf;
            box-shadow: inset 0px 8px 24px rgba(59, 74, 104, 0.3);

            ${theme.mobile} {
              padding: 38px 0px;
            }
            ${showNotes &&
            css`
              padding: 38px 0px;
              transition: all 0.2s ease-in-out;
            `}
          `}
          onClick={e => setShowMonthMenu(e.target.className === "rectangle")}
        >
          <div
            css={`
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 30px;

              ${data.type !== "projectReport"
                ? "margin-bottom: 30px;"
                : "margin-bottom: 0;"}

              padding-left: 10px;
              padding-right: 10px;
              flex-wrap: wrap;
              > div {
                display: flex;
              }
              div,
              a {
                color: #3b4a68;
                font-family: hero-new, sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
              }
              a {
                padding: 0 0 2px;
                background: none;
                :hover .circle {
                  background: #fff;
                  box-shadow: 0px 3px 6px rgba(59, 74, 104, 0.2);
                  transition: all 0.3s ease-in-out;
                }
                :active,
                :focus {
                  border: none;
                  outline: none;
                }
                .circle {
                  background: rgba(59, 74, 104, 0.05);
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
              .rectangle {
                border-radius: 20px;
                position: relative;
                height: 40px;
                background: rgba(59, 74, 104, 0.05);
                display: flex;
                align-items: center;
                justify-content: center;
                width: 360px;
                cursor: pointer;
                z-index: 100;
                &:hover {
                  background: #fff;
                  box-shadow: 0px 3px 6px rgba(59, 74, 104, 0.2);
                  transition: all 0.3s ease-in-out;
                }
              }
              .monthMenu {
                display: flex;
                position: absolute;
                top: 0;
                left: 0;
                flex-direction: column;
                width: 360px;
                background: #fff;
                border-radius: 20px;
                a {
                  display: inline-flex;
                  box-sizing: border-box;
                  width: 100%;
                  height: 40px;
                  justify-content: center;
                  align-items: center;
                  margin: 0;
                  &:hover,
                  &.active {
                    background: ${theme.blue};
                    color: #fff;
                    transition: all 0.2s;
                    svg path {
                      fill: #fff;
                      transition: fill 0.2s;
                    }
                  }
                  &:hover {
                    background: rgba(59, 74, 104, 0.9);
                    transition: background 0.3s;
                  }
                }
                a:first-child {
                  border-top-left-radius: 20px;
                  border-top-right-radius: 20px;
                }
                a:last-child {
                  border-bottom-left-radius: 20px;
                  border-bottom-right-radius: 20px;
                }
              }
              a.prev {
                margin-right: 12px;
                ${(typeof prevMonth === "undefined" || isProjectReport) &&
                css`
                  visibility: hidden;
                  pointer-events: none;
                `}
              }
              a.next {
                margin-left: 12px;
                ${(typeof nextMonth === "undefined" || isProjectReport) &&
                css`
                  visibility: hidden;
                  pointer-events: none;
                `}
              }
              @media (max-width: 600px) {
                .center {
                  width: 100%;
                }
                .rectangle,
                .monthMenu {
                  width: 100%;
                }
                .right {
                  display: flex;
                  flex-basis: 100%;
                  .burgerMenu {
                    margin-left: auto;
                    margin-right: 12px;
                    margin-top: 20px;
                    ${theme.mobile} {
                      margin-right: 0;
                    }
                  }
                }
              }
            `}
          >
            <div className="left"></div>
            <div className="center">
              <Link className="prev" to={`/${sitemap.slug}/${prevMonth?.slug}`}>
                <div className="circle">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.4996 4.7L8.19961 12L15.4996 19.3C15.9996 19.8 15.9996 20.6 15.4996 21C14.9996 21.5 14.1996 21.5 13.6996 21L5.39961 12.7C4.99961 12.3 4.99961 11.7 5.39961 11.3L13.7996 3C14.2996 2.5 15.0996 2.5 15.5996 3C15.9996 3.4 15.9996 4.2 15.4996 4.7Z"
                      fill="#3B4A68"
                    />
                  </svg>
                </div>
              </Link>
              <div className="rectangle" onClick={() => setShowMonthMenu(true)}>
                {data.title}
                {showMonthMenu && (
                  <div
                    className="monthMenu"
                    onClick={() => setShowMonthMenu(false)}
                  >
                    {sitemap.summaries
                      .slice()
                      .reverse()
                      .map(item => {
                        return (
                          <Link
                            to={`/${sitemap.slug}/${item.slug}`}
                            className={lastSlug === item.slug && "active"}
                          >
                            {item.title}
                          </Link>
                        );
                      })}

                    {!readOnly && (
                      <Link to="/dashboard/raportit" className="newReport">
                        {" "}
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="1"
                            d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
                            fill="#3B4A68"
                          />
                        </svg>
                        Uusi raportti
                      </Link>
                    )}
                  </div>
                )}
              </div>
              <Link className="next" to={`/${sitemap.slug}/${nextMonth?.slug}`}>
                <div className="circle">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.50039 19.3L15.8004 12L8.50039 4.7C8.00039 4.2 8.00039 3.4 8.50039 3C9.00039 2.5 9.80039 2.5 10.3004 3L18.6004 11.3C19.0004 11.7 19.0004 12.3 18.6004 12.7L10.2004 21C9.70039 21.5 8.90039 21.5 8.40039 21C8.00039 20.6 8.00039 19.8 8.50039 19.3Z"
                      fill="#3B4A68"
                    />
                  </svg>
                </div>
              </Link>
            </div>
            <div className="right">
              {sitemap.menu.length > 0 && <Burger data={sitemap.menu} />}
            </div>
          </div>
          {sitemap.title && (
            <h1
              css={`
                padding-left: 10px;
                font-weight: bold;
                font-size: 30px;
                line-height: 42px;
                color: #3b4a68;
                margin: 0;
              `}
            >
              {sitemap.title}
            </h1>
          )}
          {sitemap.subtitle && (
            <span
              css={`
                padding-left: 10px;
                ${theme.headingFont}
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;
                color: #3b4a68;
              `}
            >
              {sitemap.subtitle}
            </span>
          )}

          <div
            css={`
              ${isProjectReport && "display: none;"}
              padding: 20px 10px 0;
              form.gaugeMode {
                label {
                  font-family: hero-new, sans-serif;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  color: #3b4a68;
                  line-height: 22px;
                  margin-left: 8px;
                  margin-right: 40px;
                }
              }
              .radioItem {
                display: inline-flex;
              }
              @media (max-width: 600px) {
                padding-top: 0;
                .radioItem {
                  flex-basis: 100%;
                  margin-bottom: 10px;
                }
              }
            `}
          >
            <Form className="gaugeMode">
              <div className="radioItem">
                <input
                  id="thisMonth"
                  type="radio"
                  name="showGauges"
                  value="this_month"
                  checked={gaugesMode === "this_month"}
                  onChange={gaugesFormulaHandler}
                />
                <label htmlFor="thisMonth">Tämä kuukausi</label>
              </div>
              <div className="radioItem">
                <input
                  id="allMonths"
                  type="radio"
                  name="showGauges"
                  value="selected_range"
                  checked={gaugesMode === "selected_range"}
                  onChange={gaugesFormulaHandler}
                  onClick={e => {
                    if (gaugesMode === "selected_range")
                      setRangeSelectOpen(rangeSelectOpen => !rangeSelectOpen);
                  }}
                />
                <label
                  htmlFor="allMonths"
                  css={`
                    user-select: none;
                  `}
                >
                  <div
                    css={`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <span>
                      {(() => {
                        if (range.startMonth === range.endMonth)
                          return capitalize(
                            moment(range.startMonth, "YYYY-MM").format(
                              "MMMM YYYY"
                            )
                          );
                        return (
                          <>
                            {capitalize(
                              moment(range.startMonth, "YYYY-MM").format(
                                "MMMM YYYY"
                              )
                            )}{" "}
                            -{" "}
                            {capitalize(
                              moment(range.endMonth, "YYYY-MM").format(
                                "MMMM YYYY"
                              )
                            )}
                          </>
                        );
                      })()}
                    </span>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      transform={rangeSelectOpen ? `rotate(180)` : undefined}
                    >
                      <g opacity="0.75">
                        <path
                          d="M15.8805 9.29006L12.0005 13.1701L8.12047 9.29006C7.73047 8.90006 7.10047 8.90006 6.71047 9.29006C6.32047 9.68006 6.32047 10.3101 6.71047 10.7001L11.3005 15.2901C11.6905 15.6801 12.3205 15.6801 12.7105 15.2901L17.3005 10.7001C17.6905 10.3101 17.6905 9.68006 17.3005 9.29006C16.9105 8.91006 16.2705 8.90006 15.8805 9.29006Z"
                          fill="#3B4A68"
                        />
                      </g>
                    </svg>
                  </div>
                </label>
              </div>
              {rangeSelectOpen && <Range range={range} setRange={setRange} />}
            </Form>
          </div>

          {data.type !== "projectReport" && (
            <LoadMittaritGlobal>
              {({ mittaritGlobal }) => (
                <div
                  css={`
                    display: flex;
                    margin-top: 20px;
                    flex-wrap: wrap;
                  `}
                >
                  {gaugesMode === "this_month" && (
                    <>
                      {sitemap.gauges.map((item, index) => (
                        <Gauge
                          key={index}
                          data={item}
                          globalGauge={mittaritGlobal.find(
                            v => v.id === item.useGlobalGauge
                          )}
                          readOnly={readOnly}
                          gaugesMode={gaugesMode}
                          data2={data.gauges.find(
                            ({ gaugeId }) => gaugeId === item.id
                          )}
                          setGaugesThisMonth={() => setGaugesMode("this_month")}
                          allMonths={sitemap.summaries
                            .filter(({ type }) => type === "monthReport")
                            .map(
                              month =>
                                month.gauges.find(
                                  ({ gaugeId }) => gaugeId === item.id
                                )?.luku
                            )}
                          lastColor={(() => {
                            const monthReports = sitemap.summaries.filter(
                              ({ type }) => type === "monthReport"
                            );
                            return monthReports[
                              monthReports.length - 1
                            ].gauges.find(({ gaugeId }) => gaugeId === item.id)
                              ?.color;
                          })()}
                          setData2={newData => {
                            const gauges = [...data.gauges];
                            for (let i = 0; i < gauges.length; i += 1) {
                              if (gauges[i].gaugeId !== item.id) continue;
                              gauges.splice(i, 1);
                              i -= 1;
                            }
                            gauges.push({
                              gaugeId: item.id,
                              ...newData,
                            });
                            setData({
                              ...data,
                              gauges,
                            });
                          }}
                        />
                      ))}
                    </>
                  )}
                  {gaugesMode === "selected_range" && (
                    <>
                      {sitemap.gauges.map((item, index) => (
                        <>
                          <GaugeHome
                            key={item.id}
                            data={item}
                            gaugesMode={gaugesMode}
                            range={range}
                            globalData={mittaritGlobal.find(
                              v => v.id === item.useGlobalGauge
                            )}
                            selectedMonthData={onlyMonthReports[
                              activeMonthIndex
                            ].gauges.find(({ gaugeId }) => gaugeId === item.id)}
                            allMonthsData={onlyMonthReports.map(
                              month =>
                                month.gauges.find(
                                  ({ gaugeId }) => gaugeId === item.id
                                )?.luku
                            )}
                            reportedMonths={onlyMonthReports}
                          />
                        </>
                      ))}
                    </>
                  )}
                </div>
              )}
            </LoadMittaritGlobal>
          )}

          {sitemap.linkMarkkinointistrategia && (
            <a
              css={`
                margin-left: auto;
                margin-right: 10px;
                margin-top: 20px;
                background: #afbbca;
                border-radius: 8px;
                width: 200px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                &:hover {
                  background: #ffffff;
                  box-shadow: 0px 3px 6px rgba(59, 74, 104, 0.2);
                  transition: all 0.2s ease-in-out;
                }
              `}
              href={sitemap.linkMarkkinointistrategia}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                css={`
                  font-family: "hero-new", sans-serif;
                  font-weight: 600;
                  font-size: 14px;
                  color: ${theme.WDSDarkBlueGrey};
                  margin-right: 10px;
                `}
              >
                Markkinointistrategia
              </span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 18V13C19 12.5 19.5 12 20 12C20.5 12 21 12.5 21 13V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3H11C11.5 3 12 3.5 12 4C12 4.6 11.5 5 11 5H6C5.4 5 5 5.4 5 6V18C5 18.6 5.4 19 6 19H18C18.6 19 19 18.6 19 18ZM15 3H20C20.5 3 21 3.5 21 4V9C21 9.6 20.5 10 20 10C19.4 10 19 9.6 19 9V6.4L9.9 15.5C9.5 15.9 8.9 15.9 8.4 15.5C8 15.1 8 14.5 8.4 14L17.6 5H15C14.4 5 14 4.5 14 4C14 3.5 14.4 3 15 3Z"
                  fill="#3B4A68"
                />
              </svg>
            </a>
          )}
        </section>
      )}

      <section
        css={`
          display: flex;
          padding-top: 72px;
          padding-bottom: 120px;
          max-width: 1400px;
          margin: 0 auto;
        `}
      >
        <main
          className="monthSummary"
          css={`
            flex: 10;
            text-align: center;
            padding-left: 10px;
            padding-right: 10px;
          `}
        >
          {sitemap.gauges.length === 0 && (
            <div
              css={`
                margin-left: auto;
                position: relative;
                margin-top: -40px;
                .burgerMenu > div {
                  margin-left: auto;
                  margin-top: 15x;
                  margin-right: 10px;
                  &:hover {
                    box-shadow: 0px 3px 4px rgba(59, 74, 104, 0.2);
                    transition: all 0.1s ease-in-out;
                  }
                }
              `}
            >
              {sitemap.linkMarkkinointistrategia && (
                <a
                  css={`
                    margin-left: auto;
                    margin-right: 10px;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    background: #fff;
                    border-radius: 8px;
                    width: 200px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    &:hover {
                      background: #ffffff;
                      box-shadow: 0px 3px 4px rgba(59, 74, 104, 0.2);
                      transition: all 0.1s ease-in-out;
                    }
                  `}
                  href={sitemap.linkMarkkinointistrategia}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span
                    css={`
                      font-family: "hero-new", sans-serif;
                      font-weight: 600;
                      font-size: 14px;
                      color: ${theme.WDSDarkBlueGrey};
                      margin-right: 10px;
                    `}
                  >
                    Markkinointistrategia
                  </span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 18V13C19 12.5 19.5 12 20 12C20.5 12 21 12.5 21 13V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3H11C11.5 3 12 3.5 12 4C12 4.6 11.5 5 11 5H6C5.4 5 5 5.4 5 6V18C5 18.6 5.4 19 6 19H18C18.6 19 19 18.6 19 18ZM15 3H20C20.5 3 21 3.5 21 4V9C21 9.6 20.5 10 20 10C19.4 10 19 9.6 19 9V6.4L9.9 15.5C9.5 15.9 8.9 15.9 8.4 15.5C8 15.1 8 14.5 8.4 14L17.6 5H15C14.4 5 14 4.5 14 4C14 3.5 14.4 3 15 3Z"
                      fill="#3B4A68"
                    />
                  </svg>
                </a>
              )}
              {sitemap.menu.length > 0 && <Burger data={sitemap.menu} />}
            </div>
          )}
          {data.title && (
            <div>
              <h1
                css={`
                  margin: 0;
                  font-weight: 900;
                  font-size: 48px;
                  line-height: 67px;
                  margin-bottom: 50px;
                  color: #3b4a68;
                  @media (max-width: 640px) {
                    font-size: 32px;
                  }
                `}
              >
                {data.title}
              </h1>
            </div>
          )}

          <DragDropContext
            onDragEnd={handleOnDragEnd}
            onDragStart={() => setIsDragging(true)}
          >
            <Droppable droppableId="comments">
              {provided => (
                <section
                  className="comments"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  css={`
                    ${edit &&
                    isDragging !== true &&
                    ".commentWrapper:hover .editBtnsWrapper { display: flex; transition: all 0.2s;}"}
                    margin: 0 auto;
                    padding-left: 0;
                    .commentWrapper {
                      margin: 0 auto;
                      position: relative;
                      ::after {
                        position: absolute;
                        right: -60px;
                        width: 60px;
                        height: 100%;
                        top: 0;
                        background-color: yellow;
                      }
                    }
                    .remove-btn {
                      margin-right: 0.5rem;
                    }

                    .item-enter {
                      opacity: 0;
                    }
                    .item-enter-active {
                      opacity: 1;
                      transition: opacity 500ms ease-in;
                    }
                    .item-exit {
                      opacity: 1;
                    }
                    .item-exit-active {
                      opacity: 0;
                      transition: opacity 500ms ease-in;
                    }
                    .todo-list {
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      margin: 0 auto;
                      max-width: 984px;
                      > div {
                        width: 100%;
                      }
                    }
                  `}
                >
                  <TransitionGroup className="todo-list">
                    {data.comments.map((item, index) => {
                      return (
                        <CSSTransition
                          key={item.id}
                          timeout={500}
                          classNames="item"
                        >
                          <Draggable
                            draggableId={item.id}
                            index={index}
                            isDragDisabled={readOnly}
                          >
                            {provided => {
                              switch (item.__typename) {
                                case "SubheadingRecord":
                                  return (
                                    <div
                                      className="commentWrapper"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <SubHeading
                                        data={item}
                                        setData={newItem => {
                                          setData({
                                            ...data,
                                            comments: data.comments.map(v =>
                                              v === item ? newItem : v
                                            ),
                                          });
                                        }}
                                        index={index}
                                        removeHandler={removeItem}
                                        dragHandleProps={
                                          provided.dragHandleProps
                                        }
                                        readOnly={readOnly}
                                      />
                                    </div>
                                  );
                                case "CommentRecord":
                                  return (
                                    <div
                                      css={`
                                        &:hover > .addCardBtn {
                                          opacity: 1;
                                          transition: all 0.5s;
                                        }
                                      `}
                                      className="commentWrapper"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                    >
                                      {item.pictures.length > 0 ? (
                                        <>
                                          {item.pictures.length === 1 &&
                                          typeof item.content ===
                                            "undefined" ? (
                                            <CardPicture
                                              data={item}
                                              index={index}
                                              removeHandler={removeItem}
                                              saveChanges={saveChanges}
                                              dragHandleProps={
                                                provided.dragHandleProps
                                              }
                                              customerSlug={sitemap.slug}
                                              readOnly={readOnly}
                                            />
                                          ) : (
                                            <CardSplitted
                                              isSplitted={showNotes}
                                              data={item}
                                              index={index}
                                              removeHandler={removeItem}
                                              saveChanges={saveChanges}
                                              dragHandleProps={
                                                provided.dragHandleProps
                                              }
                                              customerSlug={sitemap.slug}
                                              readOnly={readOnly}
                                            />
                                          )}
                                        </>
                                      ) : (
                                        <Card
                                          isSplitted={showNotes}
                                          data={item}
                                          index={index}
                                          removeHandler={removeItem}
                                          saveChanges={saveChanges}
                                          dragHandleProps={
                                            provided.dragHandleProps
                                          }
                                          customerSlug={sitemap.slug}
                                          readOnly={readOnly}
                                        />
                                      )}
                                      {!readOnly && (
                                        <div
                                          className="addCardBtn"
                                          css={`
                                            opacity: 0;
                                            width: 32px;
                                            height: 32px;
                                            background: none;
                                            padding: 2px;
                                            margin-top: 2px;
                                            transition: all 0.5s;
                                            position: relative;
                                            display: inline-flex;
                                            &:hover svg path {
                                              transition: all 0.2s;
                                              fill-opacity: 1;
                                            }
                                            &:hover > div {
                                              opacity: 1;
                                              visibility: visible;
                                              transition: visibility 0s,
                                                opacity 0.1s linear;
                                            }
                                          `}
                                        >
                                          <svg
                                            width="32"
                                            height="32"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM13 13H17V11H13V7H11V11H7V13H11V17H13V13Z"
                                              fill="#3b4a68"
                                              fillOpacity="0.2"
                                            />
                                          </svg>
                                          <div
                                            css={`
                                              display: flex;
                                              visibility: hidden;
                                              opacity: 0;
                                              position: absolute;
                                              flex-direction: column;
                                              width: 130px;
                                              top: 0;
                                              left: 0;
                                              border-radius: 16px;
                                              z-index: 1;
                                              overflow: hidden;
                                              background: #fff;
                                              box-shadow: 0px 4px 12px
                                                rgb(59 74 104 / 50%);
                                              transition: visibility 0s,
                                                opacity 0.1s linear;
                                              button {
                                                padding: 10px 5px;
                                                text-align: left;

                                                background: #fff;
                                                &:hover {
                                                  background: #f4f5f8;
                                                }
                                              }
                                            `}
                                          >
                                            <button
                                              onClick={() =>
                                                newCommentBetween(index)
                                              }
                                            >
                                              Kortti
                                            </button>
                                            <button
                                              onClick={() =>
                                                addHeadingBetween(index)
                                              }
                                            >
                                              Otsikko
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                      {readOnly && (
                                        <div style={{ height: 24 }} />
                                      )}
                                    </div>
                                  );
                                default:
                                  return null;
                              }
                            }}
                          </Draggable>
                        </CSSTransition>
                      );
                    })}
                    {provided.placeholder}
                  </TransitionGroup>
                </section>
              )}
            </Droppable>
          </DragDropContext>

          {!readOnly &&
            !data.comments.some(i => i.__typename !== "SubheadingRecord") && (
              <div
                css={`
                  margin: 80px 0 40px;
                  button {
                    width: 200px;
                    margin: 10px;
                  }
                `}
              >
                <button className="defaultBtn" onClick={newComment}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
                      fill="white"
                    />
                  </svg>
                  Uusi kortti
                </button>
                <button
                  className="defaultBtn blueOutlines"
                  onClick={addHeading}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
                      fill="white"
                    />
                  </svg>
                  Uusi otsikko
                </button>
              </div>
            )}

          {showModalNewComment && (
            <Modal onClose={onClose}>
              <EditCard
                intention="createCard"
                customerSlug={sitemap.slug}
                addCard={addCard}
                closeModal={onClose}
              />
            </Modal>
          )}
        </main>
        {data.comments.some(i => i.__typename === "SubheadingRecord") && (
          <aside className="contentsMenu">
            <ContentsMenu allLinks={allSubhedings} />
          </aside>
        )}
      </section>
      <Helmet>
        <title>
          {sitemap.name} • {data.title}
        </title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Helmet>
    </>
  );
};

export default Summary;
