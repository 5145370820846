import { gql, useQuery } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom";
import "styled-components/macro";
import { Link } from "react-router-dom";
import { LoadAuthor } from "../../../views/account/components/DataLoaders";
import { UploadedImageKeyToURL } from "../../customer/report/components/EditCard";
import moment from "moment";
import "moment/locale/fi";
moment.locale("fi");

const Discussions: React.FC<{ userEmail: string }> = ({ userEmail }) => {
  const [tick, setTick] = React.useState(0);
  const [show, setShow] = React.useState(false);
  const { data } = useQuery<{
    discussions: Array<{
      customerSlug: string;
      email: string;
      message: string;
      createdAt: number;
      seenBy: Array<{ email: string }>;
      numberOfCommentsInDiscussion: number;
      titleOfDiscussion: string;
      linkToDiscussion: string;
    }>;
  }>(
    gql`
      query Discussions($markAsSeen: Boolean) {
        discussions(markAsSeen: $markAsSeen) {
          customerSlug
          email
          message
          createdAt
          seenBy {
            email
          }
          numberOfCommentsInDiscussion
          titleOfDiscussion
          linkToDiscussion
        }
      }
    `,
    { variables: { markAsSeen: show, tick } }
  );
  const [el] = React.useState(() => document.createElement("div"));
  React.useLayoutEffect(() => {
    if (!show) return;
    document.body.appendChild(el);
    const { width } = document.body.getBoundingClientRect();
    document.body.style["overflow"] = "hidden";
    /* eslint-disable-next-line @typescript-eslint/no-unused-expressions */
    document.body.scrollTop;
    const { width: width2 } = document.body.getBoundingClientRect();
    document.body.style.paddingRight = `${width2 - width}px`;
    return () => {
      document.body.removeChild(el);
      document.body.style["overflow"] = "";
      document.body.style.paddingRight = ``;
    };
  }, [show, el]);
  return (
    <>
      <button
        onClick={e => {
          e.preventDefault();
          setShow(true);
        }}
        css={`
          border: none;
          cursor: pointer;
          padding: 0px;
          background: none;
          position: relative;
          > span {
            position: absolute;
            top: -4px;
            right -4px;
            width: 18px;
            height: 18px;
            overflow: hidden;
            background: #FF3B4A;
            border-radius: 50%;
            font-size: 12px;
            line-height: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFFFFF;
            > span { display: inline-block;}
          }
        `}
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle opacity="0.3" cx="20" cy="20" r="20" fill="#B5BECF" />
          <path
            d="M15 29V25H14C12.3 25 11 23.7 11 22V15C11 13.3 12.3 12 14 12H26C27.7 12 29 13.3 29 15V22C29 23.7 27.7 25 26 25H20.3L15 29ZM14 14C13.4 14 13 14.4 13 15V22C13 22.6 13.4 23 14 23H17V25L19.7 23H26C26.6 23 27 22.6 27 22V15C27 14.4 26.6 14 26 14H14Z"
            fill="#3B4A68"
          />
        </svg>
        {(() => {
          const unseen =
            typeof data !== "undefined"
              ? data.discussions.filter(
                  ({ seenBy }) =>
                    typeof seenBy.find(v => v.email === userEmail) ===
                    "undefined"
                ).length
              : undefined;
          if (typeof unseen === "undefined" || unseen === 0) return <></>;
          return (
            <span>
              <span>{unseen}</span>
            </span>
          );
        })()}
      </button>
      {show &&
        ReactDOM.createPortal(
          <div
            css={`
              position: fixed;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: rgba(255, 255, 255, 0.25);
              backdrop-filter: grayscale(75%);
              z-index: 500;
              display: flex;
              justify-content: flex-end;
            `}
            onClick={e => {
              if (e.currentTarget === e.target) {
                e.preventDefault();
                setShow(false);
                setTick(tick => tick + 1);
              }
            }}
          >
            <div
              css={`
                width: 672px;
                max-width: 100%;
                background: #eaedf2;
                box-shadow: -6px 0px 24px rgba(59, 74, 104, 0.5);
                padding: 16px;
                overflow-y: scroll;
                > h2 {
                  font-family: "hero-new", sans-serif;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 20px;
                  color: #3b4a68;
                  padding: 0;
                  margin: 0;
                  padding: 6px 0;
                }
                > button {
                  float: right;
                  padding: 0;
                  margin: 0;
                  background: none;
                  cursor: pointer;
                }
              `}
            >
              <button
                onClick={e => {
                  e.preventDefault();
                  setShow(false);
                  setTick(tick => tick + 1);
                }}
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="20" cy="20" r="20" fill="white" />
                  <path
                    d="M26.4008 15.1004L21.4008 20.0004L26.3008 24.9004C26.7008 25.3004 26.7008 25.9004 26.3008 26.3004C25.9008 26.7004 25.3008 26.7004 24.9008 26.3004L20.0008 21.4004L15.1008 26.3004C14.7008 26.7004 14.1008 26.7004 13.7008 26.3004C13.3008 25.9004 13.3008 25.3004 13.7008 24.9004L18.6008 20.0004L13.6008 15.1004C13.2008 14.7004 13.2008 14.1004 13.6008 13.7004C14.0008 13.3004 14.6008 13.3004 15.0008 13.7004L19.9008 18.6004L24.8008 13.7004C25.2008 13.3004 25.8008 13.3004 26.2008 13.7004C26.8008 14.0004 26.8008 14.7004 26.4008 15.1004Z"
                    fill="#3B4A68"
                  />
                </svg>
              </button>
              <h2>Keskustelut</h2>
              <div style={{ clear: "both", height: 16 }} />
              {typeof data !== "undefined" &&
                data.discussions
                  .slice()
                  .sort((a, b) => {
                    if (a.createdAt < b.createdAt) return 1;
                    if (a.createdAt > b.createdAt) return -1;
                    return 0;
                  })
                  .map(
                    ({
                      customerSlug,
                      email,
                      createdAt,
                      message,
                      numberOfCommentsInDiscussion,
                      titleOfDiscussion,
                      linkToDiscussion,
                      seenBy,
                    }) => (
                      <>
                        <div
                          css={`
                            background: #ffffff;
                            border-radius: 8px;
                            padding: 16px;
                            &.seen {
                              opacity: 0.65;
                            }
                          `}
                          className={
                            typeof seenBy.find(
                              ({ email }) => email === userEmail
                            ) !== "undefined"
                              ? "seen"
                              : undefined
                          }
                        >
                          <div
                            css={`
                              display: flex;
                              margin-bottom: 8px;
                            `}
                          >
                            <LoadAuthor
                              authorEmail={email}
                              customerSlug={customerSlug}
                            >
                              {({ name, title, avatarUrl }) => (
                                <>
                                  {avatarUrl !== null ? (
                                    <div>
                                      <UploadedImageKeyToURL Key={avatarUrl}>
                                        {({ url }: any) => (
                                          <div
                                            css={`
                                              display: block;
                                              width: 32px;
                                              height: 32px;
                                              background: url(${url});
                                              background-position: 50% 50%;
                                              background-size: cover;
                                              background-repeat: no-repeat;
                                              margin-left: 8px;
                                              margin-right: 8px;
                                              border-radius: 50%;
                                            `}
                                          />
                                        )}
                                      </UploadedImageKeyToURL>
                                    </div>
                                  ) : (
                                    <div
                                      css={`
                                        margin-left: 8px;
                                        margin-right: 8px;
                                      `}
                                    >
                                      <svg
                                        width="32"
                                        height="32"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M16 8C16 10.21 14.21 12 12 12C9.79004 12 8 10.21 8 8C8 5.78998 9.79004 4 12 4C14.21 4 16 5.78998 16 8ZM4 18C4 15.34 9.33008 14 12 14C14.6699 14 20 15.34 20 18V20H4V18Z"
                                          fill="#3b4a68"
                                          fillOpacity="0.34"
                                        />
                                      </svg>
                                    </div>
                                  )}
                                  <div
                                    css={`
                                      font-family: "hero-new", sans-serif;
                                      font-size: 14px;
                                      font-weight: 500;
                                      line-height: 24px;
                                      display: flex;
                                      align-items: center;
                                      color: #3b4a68;
                                      flex: 1;
                                    `}
                                  >
                                    {name}
                                  </div>
                                  <div
                                    css={`
                                      font-family: "hero-new", sans-serif;
                                      font-style: normal;
                                      font-weight: normal;
                                      font-size: 14px;
                                      line-height: 24px;
                                      display: flex;
                                      align-items: center;
                                      text-align: right;
                                      color: #3b4a68;
                                      opacity: 0.6;
                                    `}
                                  >
                                    {moment(createdAt).format(
                                      "D.M.YYYY [klo] HH:mm"
                                    )}
                                  </div>
                                </>
                              )}
                            </LoadAuthor>
                          </div>
                          <div
                            css={`
                              margin-left: 48px;
                              font-family: "hero-new", sans-serif;
                              font-style: normal;
                              font-weight: normal;
                              font-size: 14px;
                              line-height: 24px;
                              color: #3b4a68;
                              margin-bottom: 16px;
                            `}
                          >
                            {message}
                          </div>
                          <div
                            css={`
                              margin: -8px;
                              background: #f4f5f8;
                              border-radius: 4px;
                              padding: 8px 16px;
                              display: flex;
                            `}
                          >
                            <div
                              css={`
                                display: inline-grid;
                                grid-template-columns: repeat(2, auto);
                                grid-gap: 8px;
                              `}
                            >
                              <div
                                css={`
                                  display: flex;
                                  align-items: center;
                                `}
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7 21V17H6C4.3 17 3 15.7 3 14V7C3 5.3 4.3 4 6 4H18C19.7 4 21 5.3 21 7V14C21 15.7 19.7 17 18 17H12.3L7 21ZM6 6C5.4 6 5 6.4 5 7V14C5 14.6 5.4 15 6 15H9V17L11.7 15H18C18.6 15 19 14.6 19 14V7C19 6.4 18.6 6 18 6H6Z"
                                    fill="#3B4A68"
                                  />
                                </svg>
                              </div>
                              <span
                                css={`
                                  font-family: "hero-new", sans-serif;
                                  font-weight: 500;
                                  font-size: 14px;
                                  line-height: 20px;
                                  display: flex;
                                  align-items: center;
                                  color: #3b4a68;
                                `}
                              >
                                {numberOfCommentsInDiscussion}
                              </span>
                            </div>
                            <div
                              css={`
                                font-family: "hero-new";
                                font-size: 14px;
                                line-height: 20px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                text-align: center;
                                color: #3b4a68;
                                margin: 0 1ch;
                                > p {
                                  margin: 0;
                                }
                                flex: 1;
                              `}
                            >
                              <p>{titleOfDiscussion}</p>
                            </div>
                            <div
                              css={`
                                display: flex;
                                align-items: center;
                                text-align: right;
                                > a {
                                  font-family: "hero-new", sans-serif;
                                  font-style: normal;
                                  font-weight: 500;
                                  font-size: 14px;
                                  line-height: 20px;
                                  color: #3b4a68;
                                }
                              `}
                            >
                              <Link
                                to={linkToDiscussion}
                                onClick={() => {
                                  setShow(false);
                                }}
                              >
                                Näytä keskustelu
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div style={{ height: 16 }} />
                      </>
                    )
                  )}
            </div>
          </div>,
          el
        )}
    </>
  );
};

export default Discussions;
