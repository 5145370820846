import React, { useState } from "react";
import "styled-components/macro";
import theme from "../../../styles/theme";
import { Form } from "../../components/Form";
import { Panel } from "../../components/ui";
import Icon from "../../components/icons";
import GaugeHome from "../components/GaugeHome";
import { LoadMarkkinointiIndeksi } from "../../account/components/DataLoaders";
import MarkkinointiIndeksiWidget from "./components/MarkkinointiIndeksiWidget";
import { useQuery, gql } from "@apollo/client";
import { UploadedImageKeyToURL } from "../report/components/EditCard";
import { ShowQuillContent } from "../report/components/EditTextarea";
import { LoadMittaritGlobal } from "../../account/components/DataLoaders";
import Discussion from "../report/components/Discussion.tsx";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import fi from "date-fns/locale/fi";
import "react-datepicker/dist/react-datepicker.css";
import "moment/locale/fi";
registerLocale("fi", fi);
moment.locale("fi");

/*
  Disable Unavailable Months 

  
  */

export const useAuthors = () => {
  const { loading, data } = useQuery(
    gql`
      query {
        allAuthors {
          email
          name
          title
          avatarUrl
          phone
        }
      }
    `,
    {
      variables: {},
    }
  );
  if (loading) return undefined;
  return data.allAuthors;
};

const WithNavigation = ({ type, onlyMonthReports, initial, customerSlug }) => {
  const [selected, setSelected] = React.useState(() => initial);

  return (
    <Panel
      options={{
        title:
          `${type === "agenda" ? "Agenda" : ""}${
            type === "muistiinpanot" ? "Muistiinpanot" : ""
          }: ` + onlyMonthReports[selected].title,
      }}
      controls={() => (
        <>
          <div className="controls">
            <button
              className="btn prev"
              style={{ marginRight: 4 }}
              disabled={selected === 0}
              onClick={e => {
                e.preventDefault();
                setSelected(selected => selected - 1);
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.7105 6.70998C14.3205 6.31998 13.6905 6.31998 13.3005 6.70998L8.71047 11.3C8.32047 11.69 8.32047 12.32 8.71047 12.71L13.3005 17.3C13.6905 17.69 14.3205 17.69 14.7105 17.3C15.1005 16.91 15.1005 16.28 14.7105 15.89L10.8305 12L14.7105 8.11998C15.1005 7.72998 15.0905 7.08998 14.7105 6.70998Z"
                  fill="#3B4A68"
                />
              </svg>
            </button>
            <button
              className="btn next"
              disabled={selected >= onlyMonthReports.length - 1}
              onClick={e => {
                e.preventDefault();
                setSelected(selected => selected + 1);
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.29055 6.70978C8.90055 7.09978 8.90055 7.72978 9.29055 8.11978L13.1705 11.9998L9.29055 15.8798C8.90055 16.2698 8.90055 16.8998 9.29055 17.2898C9.68055 17.6798 10.3105 17.6798 10.7005 17.2898L15.2905 12.6998C15.6805 12.3098 15.6805 11.6798 15.2905 11.2898L10.7005 6.69978C10.3205 6.31978 9.68055 6.31978 9.29055 6.70978Z"
                  fill="#3B4A68"
                />
              </svg>
            </button>
          </div>
        </>
      )}
    >
      <div
        css={`
          display: flex;
          padding: 30px;
        `}
      >
        {(() => {
          if (onlyMonthReports.length === 0) return <></>;
          const slug = onlyMonthReports[selected].slug;
          return (
            <div
              css={`
                display: flex;
                width: 100%;
                padding-bottom: 32px;
                > div {
                  flex: 1;
                  > div {
                    width: 100%;
                  }
                }
              `}
            >
              <ShowQuillContent
                key={`${type}-${slug}`}
                customer={customerSlug}
                type={`${type}-${slug}`}
              />
            </div>
          );
        })()}
      </div>
      <div>
        <Discussion
          customerSlug={customerSlug}
          id={`${type}-${onlyMonthReports[selected].slug}`}
        />
        <div style={{ height: 1 }} />
      </div>
    </Panel>
  );
};

export const Range = ({ range, setRange }) => {
  /* Disable months where no report 
  useEffect(() => {
    reportedMonths.forEach(mm => {
      const [month, year] = mm.slug.split("-");

      const monthIndex = monthsFi.indexOf(month);

      const el = document.querySelector(
        `[aria-label='Choose ${monthsEn[monthIndex]} ${year}']`
      );
      console.log(el);
      if (el !== null) {
        el.style.color = "blue";
      }
    });
  }, [range]);
*/

  function Checkbox({ checked, onChange, children }) {
    return (
      <>
        <label
          css={`
            display: flex;
            align-items: center;
            user-select: none;
            > svg {
              margin-right: 8px;
            }
            cursor: pointer;
          `}
        >
          <input
            type="checkbox"
            checked={checked}
            style={{ display: "none" }}
            onChange={onChange}
          />
          {!checked && (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="1"
                y="1"
                width="22"
                height="22"
                rx="3"
                stroke="#B5BECF"
                strokeWidth="2"
              />
            </svg>
          )}
          {checked && (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="24" height="24" rx="4" fill="#00EA9D" />
              <path
                d="M19.0698 5.64014L9.16977 15.5401L4.92977 11.2901L3.50977 12.7101L9.16977 18.3601L20.4898 7.05014L19.0698 5.64014Z"
                fill="white"
              />
            </svg>
          )}
          <div>{children}</div>
        </label>
      </>
    );
  }
  function SelectDate({ date, setDate }) {
    return (
      <DatePicker
        inline
        className="datePicker"
        onChange={date => {
          setDate(date);
        }}
        locale="fi"
        dateFormat="MM/yyyy"
        selected={date}
        showMonthYearPicker
        showFullMonthYearPicker
      />
    );
  }
  return (
    <>
      <div
        css={`
          background: #ffffff;
          box-shadow: 0px 4px 12px rgba(59, 74, 104, 0.3);
          border-radius: 8px;
          padding: 8px;
          .react-datepicker__navigation-icon:before {
            top: 8px !important;
          }
          max-width: 548px;
        `}
      >
        <div
          css={`
            display: grid;
            grid-template-columns: 1fr 1fr;
            .head {
              font-family: hero-new, sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              color: #3b4a68;
              opacity: 0.8;
              line-height: 22px;
              text-align: center;
              padding: 6px;
            }
            > div:nth-child(3),
            > div:nth-child(4) {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          `}
        >
          <div className="head">Alkaa</div>
          <div className="head">Päättyy</div>
          <div>
            <SelectDate
              date={moment(range.startMonth, "YYYY-MM").toDate()}
              setDate={date => {
                setRange({
                  ...range,
                  startMonth: moment(date).format("YYYY-MM"),
                });
              }}
            />
          </div>
          <div>
            <SelectDate
              date={moment(range.endMonth, "YYYY-MM").toDate()}
              setDate={date => {
                setRange({
                  ...range,
                  endMonth: moment(date).format("YYYY-MM"),
                });
              }}
            />
          </div>
        </div>
        <div style={{ height: 8 }} />
        <Checkbox
          checked={
            typeof range.startMonth_compare !== "undefined" &&
            typeof range.endMonth_compare !== "undefined"
          }
          onChange={e => {
            if (e.currentTarget.checked) {
              setRange({
                ...range,
                ...(() => {
                  return {
                    startMonth_compare: moment(range.startMonth)
                      .subtract(1, "year")
                      .format("YYYY-MM"),
                    endMonth_compare: moment(range.endMonth)
                      .subtract(1, "year")
                      .format("YYYY-MM"),
                  };
                })(),
              });
            } else {
              setRange({
                startMonth: range.startMonth,
                endMonth: range.endMonth,
              });
            }
          }}
        >
          {"Vertailujakso"}
        </Checkbox>
        {typeof range.startMonth_compare !== "undefined" &&
          typeof range.endMonth_compare !== "undefined" && (
            <>
              <div
                css={`
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  > div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                `}
              >
                <div>
                  <SelectDate
                    date={moment(range.startMonth_compare, "YYYY-MM").toDate()}
                    setDate={date => {
                      setRange({
                        ...range,
                        startMonth_compare: moment(date).format("YYYY-MM"),
                      });
                    }}
                  />
                </div>
                <div>
                  <SelectDate
                    date={moment(range.endMonth_compare, "YYYY-MM").toDate()}
                    setDate={date => {
                      setRange({
                        ...range,
                        endMonth_compare: moment(date).format("YYYY-MM"),
                      });
                    }}
                  />
                </div>
              </div>
            </>
          )}
      </div>
    </>
  );
};

const capitalize = str => str.substring(0, 1).toUpperCase() + str.substring(1);

const Home = ({ customer: data, sitemap }) => {
  const customer = data;

  const onlyMonthReports = sitemap.summaries.filter(
    ({ type }) => type === "monthReport"
  );

  const [gaugesMode, setGaugesMode] = useState(
    process.env.NODE_ENV === "development" ? "selected_range" : "this_month"
  );
  const [activeMonthIndex, setActiveMonthIndex] = useState(
    onlyMonthReports.length - 1
  );

  const gaugesFormulaHandler = event => {
    setGaugesMode(event.target.value);
  };

  const [rangeSelectOpen, setRangeSelectOpen] = React.useState(false);
  const [range, setRange] = React.useState(() => ({
    startMonth: `${moment().format("YYYY")}-01`,
    endMonth: moment().format("YYYY-MM"),
  }));

  React.useEffect(() => {
    setRangeSelectOpen(gaugesMode === "selected_range");
  }, [gaugesMode]);

  const globalTeam = useAuthors();
  if (typeof globalTeam === "undefined") return <></>;

  console.log(sitemap);
  return (
    <>
      <section
        css={`
          display: flex;
          background: #bec5d6;
          box-shadow: inset 0px 8px 24px rgba(59, 74, 104, 0.3);
          padding: 58px 24px;
          gap: 20px;
          ${theme.mobile} {
            padding: 38px 10px;
          }
          flex-wrap: wrap;
          > div {
            flex: 1;
          }
          .title {
            display: flex;
            align-items: center;
            h2 {
              font-size: 18px;
            }
            border-bottom: 1px solid rgba(59, 74, 104, 0.2);
          }
          .controls {
            margin-left: auto;
            display: flex;
            button:disabled {
              cursor: default;
              svg path {
                opacity: 0.3;
              }
            }
          }
          .btn {
            font-size: 14px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #b3bacd;
            height: 32px;
          }
          .prev {
            border-radius: 4px 0px 0px 4px;
          }
          .next {
            margin-left: 5px;
            border-radius: 0px 4px 4px 0px;
          }
          .open {
            margin-left: 15px;
            border-radius: 4px;
            padding: 0 10px;
          }
        `}
      >
        <LoadMarkkinointiIndeksi>
          {({ markkinointiIndeksi }) => {
            if (data.markIndex === null) return;
            return (
              <>
                <div
                  css={`
                    display: flex;
                    flex-direction: column;
                    color: #fff;
                  `}
                >
                  <div className="title">
                    <h2>Markkinointi-indeksi</h2>
                  </div>
                  <MarkkinointiIndeksiWidget
                    data={data.markIndex}
                    markIndexDescription={data.markIndexDescription}
                    globalData={markkinointiIndeksi}
                  />
                </div>
              </>
            );
          }}
        </LoadMarkkinointiIndeksi>

        {onlyMonthReports.length > 0 && (
          <div>
            <div className="title">
              <h2>{onlyMonthReports[activeMonthIndex]?.title}</h2>

              <div className="controls">
                <button
                  className="btn prev"
                  disabled={activeMonthIndex === 0}
                  onClick={() => {
                    setActiveMonthIndex(activeMonthIndex - 1);
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.7105 6.70998C14.3205 6.31998 13.6905 6.31998 13.3005 6.70998L8.71047 11.3C8.32047 11.69 8.32047 12.32 8.71047 12.71L13.3005 17.3C13.6905 17.69 14.3205 17.69 14.7105 17.3C15.1005 16.91 15.1005 16.28 14.7105 15.89L10.8305 12L14.7105 8.11998C15.1005 7.72998 15.0905 7.08998 14.7105 6.70998Z"
                      fill="#3B4A68"
                    />
                  </svg>
                </button>
                <button
                  className="btn next"
                  disabled={activeMonthIndex === onlyMonthReports.length - 1}
                  onClick={() => {
                    setActiveMonthIndex(activeMonthIndex + 1);
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.29055 6.70978C8.90055 7.09978 8.90055 7.72978 9.29055 8.11978L13.1705 11.9998L9.29055 15.8798C8.90055 16.2698 8.90055 16.8998 9.29055 17.2898C9.68055 17.6798 10.3105 17.6798 10.7005 17.2898L15.2905 12.6998C15.6805 12.3098 15.6805 11.6798 15.2905 11.2898L10.7005 6.69978C10.3205 6.31978 9.68055 6.31978 9.29055 6.70978Z"
                      fill="#3B4A68"
                    />
                  </svg>
                </button>
                <a
                  href={`/${customer.slug}/${sitemap.summaries[activeMonthIndex]?.slug}`}
                  className="button open"
                  css={`
                    color: #3b4a68 !important;
                    background: #b3bacd;
                    font-size: 14px !important;
                  `}
                >
                  Avaa raportti
                </a>
              </div>
            </div>
            <div
              css={`
                padding: 20px 10px 0;
                form.gaugeMode {
                  margin-top: 10px;
                  label {
                    font-family: hero-new, sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    color: #3b4a68;
                    line-height: 22px;

                    margin-left: 8px;
                    margin-right: 40px;
                  }
                }
                .radioItem {
                  display: inline-flex;
                }
                @media (max-width: 600px) {
                  padding-top: 0;
                  .radioItem {
                    flex-basis: 100%;
                    margin-bottom: 10px;
                  }
                }
              `}
            >
              <Form className="gaugeMode">
                <div className="radioItem">
                  <input
                    id="thisMonth"
                    type="radio"
                    name="showGauges"
                    value="this_month"
                    checked={gaugesMode === "this_month"}
                    onChange={gaugesFormulaHandler}
                  />
                  <label
                    htmlFor="thisMonth"
                    css={`
                      user-select: none;
                    `}
                  >
                    Tämä kuukausi
                  </label>
                </div>
                <div className="radioItem">
                  <input
                    id="allMonths"
                    type="radio"
                    name="showGauges"
                    value="selected_range"
                    checked={gaugesMode === "selected_range"}
                    onChange={gaugesFormulaHandler}
                    onClick={e => {
                      if (gaugesMode === "selected_range")
                        setRangeSelectOpen(rangeSelectOpen => !rangeSelectOpen);
                    }}
                  />
                  <label
                    htmlFor="allMonths"
                    css={`
                      user-select: none;
                    `}
                  >
                    <div
                      css={`
                        display: flex;
                        align-items: center;
                      `}
                    >
                      <span>
                        {(() => {
                          if (range.startMonth === range.endMonth)
                            return capitalize(
                              moment(range.startMonth, "YYYY-MM").format(
                                "MMMM YYYY"
                              )
                            );
                          return (
                            <>
                              {capitalize(
                                moment(range.startMonth, "YYYY-MM").format(
                                  "MMMM YYYY"
                                )
                              )}{" "}
                              -{" "}
                              {capitalize(
                                moment(range.endMonth, "YYYY-MM").format(
                                  "MMMM YYYY"
                                )
                              )}
                            </>
                          );
                        })()}
                      </span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        transform={rangeSelectOpen ? `rotate(180)` : undefined}
                      >
                        <g opacity="0.75">
                          <path
                            d="M15.8805 9.29006L12.0005 13.1701L8.12047 9.29006C7.73047 8.90006 7.10047 8.90006 6.71047 9.29006C6.32047 9.68006 6.32047 10.3101 6.71047 10.7001L11.3005 15.2901C11.6905 15.6801 12.3205 15.6801 12.7105 15.2901L17.3005 10.7001C17.6905 10.3101 17.6905 9.68006 17.3005 9.29006C16.9105 8.91006 16.2705 8.90006 15.8805 9.29006Z"
                            fill="#3B4A68"
                          />
                        </g>
                      </svg>
                    </div>
                  </label>
                </div>
                {rangeSelectOpen && (
                  <Range
                    range={range}
                    setRange={setRange}
                    reportedMonths={onlyMonthReports}
                  />
                )}
              </Form>
            </div>

            <LoadMittaritGlobal>
              {({ mittaritGlobal }) => (
                <div
                  css={`
                    display: flex;
                    margin-top: 20px;
                    flex-wrap: wrap;
                    > div {
                    }
                  `}
                >
                  {sitemap.gauges.map((item, index) => (
                    <>
                      <GaugeHome
                        key={item.id}
                        data={item}
                        gaugesMode={gaugesMode}
                        range={range}
                        globalData={mittaritGlobal.find(
                          v => v.id === item.useGlobalGauge
                        )}
                        selectedMonthData={onlyMonthReports[
                          activeMonthIndex
                        ].gauges.find(({ gaugeId }) => gaugeId === item.id)}
                        allMonthsData={onlyMonthReports.map(
                          month =>
                            month.gauges.find(
                              ({ gaugeId }) => gaugeId === item.id
                            )?.luku
                        )}
                        reportedMonths={onlyMonthReports}
                      />
                    </>
                  ))}
                </div>
              )}
            </LoadMittaritGlobal>
          </div>
        )}
      </section>
      <div
        css={`
          display: flex;
          flex-wrap: wrap;
          color: #3b4a68;
          > div {
            flex: 1;
            flex-basis: 200px;
          }
          .panel {
            margin: 20px;
          }
          .panelHeader {
            background: #f6f6f9;
          }
          .panelContent {
            background: #fff;
            padding: 0;
          }
        `}
      >
        <div>
          {onlyMonthReports.length > 0 && (
            <WithNavigation
              type="agenda"
              onlyMonthReports={onlyMonthReports}
              initial={onlyMonthReports.length - 1}
              customerSlug={sitemap.slug}
            />
          )}
          {onlyMonthReports.length > 0 && (
            <WithNavigation
              type="muistiinpanot"
              onlyMonthReports={onlyMonthReports}
              initial={Math.max(onlyMonthReports.length - 2, 0)}
              customerSlug={sitemap.slug}
            />
          )}
        </div>
        <div>
          <Panel options={{ title: "Raportit" }}>
            <ul
              css={`
                list-style: none;
                padding-left: 0;
                margin: 0;
                font-family: hero-new, sans-serif;
                li {
                  height: 54px;
                  width: 100%;
                }
                a {
                  padding-left: 20px;
                  display: flex;
                  align-items: center;
                  text-decoration: none;
                  width: 100%;
                  height: 100%;
                  &:hover {
                    background: #f6f6f9;
                    transition: 0.2s;
                  }
                }
                span {
                  font-weight: 600;
                  padding-left: 10px;
                  vertical-align: 72%;
                }
              `}
            >
              {sitemap.summaries
                .slice()
                .sort((a, b) => {
                  try {
                    return -a.span.startDate.localeCompare(b.span.startDate);
                  } catch (err) {
                    return 0;
                  }
                })
                .slice(0, 20)
                .map((item, index) => {
                  return (
                    <li key={`/${customer.slug}/${item.slug}`}>
                      <a href={`/${customer.slug}/${item.slug}`}>
                        <span>{item.title}</span>
                      </a>
                    </li>
                  );
                })}
            </ul>
          </Panel>
          <Panel options={{ title: "Linkit" }}>
            <ul
              css={`
                list-style: none;
                padding-left: 0;
                margin: 0;
                font-family: hero-new, sans-serif;
                li {
                  height: 54px;
                }
                a {
                  padding-left: 20px;
                  display: flex;
                  align-items: center;
                  text-decoration: none;
                  width: 100%;
                  height: 100%;
                  &:hover {
                    background: #f6f6f9;
                    transition: 0.2s;
                  }
                }
                span {
                  font-weight: 600;
                  padding-left: 10px;
                  vertical-align: 72%;
                }
              `}
            >
              {customer.menu.map((item, index) => {
                return (
                  <li key={index}>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon name={item.icon} />
                      <span>{item.title}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </Panel>
          <Panel options={{ title: "Tiimi" }}>
            <div
              css={`
                font-family: hero-new, sans-serif;
                font-size: 14px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                .item {
                  flex-basis: 220px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  text-align: center;
                  padding: 0 15px 30px;
                  span {
                    margin: 4px auto;
                  }
                  span:first-of-type {
                    margin-bottom: 6px;
                  }
                  .avatar {
                    margin: 20px auto;
                    width: 72px;
                    height: 72px;
                    border-radius: 50%;
                    overflow: hidden;
                    img {
                      width: 100%;
                    }
                  }
                }
              `}
            >
              {data.team.length > 0 &&
                data.team.map((item, index) => {
                  const personGlobalData = globalTeam.find(
                    person => person.email === item.email
                  );

                  return (
                    <div className="item" key={item.email}>
                      <div className="avatar">
                        {personGlobalData.avatarUrl !== null ? (
                          <UploadedImageKeyToURL
                            Key={personGlobalData.avatarUrl}
                          >
                            {({ url }) => <img src={url} alt="" />}
                          </UploadedImageKeyToURL>
                        ) : (
                          <svg
                            width="72"
                            height="72"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M16 8C16 10.21 14.21 12 12 12C9.79004 12 8 10.21 8 8C8 5.78998 9.79004 4 12 4C14.21 4 16 5.78998 16 8ZM4 18C4 15.34 9.33008 14 12 14C14.6699 14 20 15.34 20 18V20H4V18Z"
                              fill={theme.blue}
                              fillOpacity="0.34"
                            />
                          </svg>
                        )}
                      </div>
                      <span>
                        <strong>{item.title || personGlobalData.title}</strong>
                      </span>
                      <span>{personGlobalData.name}</span>
                      <span>
                        <a href={`mailto:${personGlobalData.email}`}>
                          {personGlobalData.email}
                        </a>
                      </span>
                      <span>
                        <a href={`tel:${personGlobalData.phone}`}>
                          {personGlobalData.phone}
                        </a>
                      </span>
                    </div>
                  );
                })}
              {data.team.length === 0 && (
                <>
                  <p style={{ margin: "40px auto" }}>
                    Nimeä asiakkaalle oma tiimi{" "}
                    <a href={`/${customer.slug}/asetukset`}>asetuksissa</a>
                  </p>
                </>
              )}
            </div>
          </Panel>
        </div>
      </div>
    </>
  );
};

export default Home;
