import React, { useEffect } from "react";
import "styled-components/macro";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import BasicInfo from "./components/BasicInfo";
import Links from "./components/Links";
import Gauges from "./components/Gauges";
import Markkinointitiimi from "./components/Markkinointitiimi";
import ReadPermissions from "../components/ReadPermissions";
import MarkkinointiIndexAdmin from "./components/MarkkinointiIndexAdmin";
import Delete from "../components/Delete";
import deepEqual from "deep-equal";
import { LoadMarkkinointiIndeksi } from "../../account/components/DataLoaders";
import { LoadMittaritGlobal } from "../../account/components/DataLoaders";

const Customer = ({
  customer: initialCustomer,
  SaveCustomer,
  deleteWholeCustomer,
}) => {
  const [customer, setCustomer] = React.useState(() => initialCustomer);

  const inputsAreValid = React.useMemo(
    () =>
      customer.menu.every(i => i.title !== "" && i.url !== "") &&
      customer.gauges.every(
        i => i.otsikko !== "" && i.min !== "" && i.max !== ""
      ),
    [customer]
  );

  // Dont't let user save before inputs are valid

  React.useLayoutEffect(() => {
    setCustomer(initialCustomer);
  }, [initialCustomer]);

  useEffect(() => {
    const onscroll = function () {
      let currentScrollPos = window.pageYOffset;
      const el = document.getElementById("saveBar");

      if (!el) return;

      if (currentScrollPos > 200) {
        el.style.top = "0";
      } else {
        el.style.top = "-62px";
      }
    };
    window.addEventListener("scroll", onscroll);
    return () => {
      window.removeEventListener("scroll", onscroll);
    };
  }, []);

  return (
    <>
      <div
        id="saveBar"
        css={`
          top: -62px;
          background: #fff;
          width: 100%;
          height: 58px;
          position: fixed;
          display: flex;
          z-index: 10;
          transition: top 0.5s;
          color: #3b4a68;
          padding: 20px;
          align-items: center;
          border-bottom: 1px solid rgba(181, 190, 207, 0.5);

          h1 {
            font-weight: 600;
            font-size: 22px;
          }
          .defaultBtn {
            margin-left: auto;
            &.saveBtn {
              background: #3b4a68;
            }
            &.saveBtn:disabled {
              background: #3b4a68;
              opacity: 0.4;
            }
          }
        `}
      >
        <h1>Asiakkaan asetukset</h1>

        <SaveCustomer>
          {({ save, saving, saved }) => (
            <button
              type="button"
              className={`defaultBtn saveBtn${
                saving || saved ? " button--loading" : ""
              }`}
              disabled={
                (deepEqual(initialCustomer, customer) && !saved) ||
                !inputsAreValid
              }
              onClick={e => {
                e.preventDefault();
                save(customer);
              }}
            >
              {deepEqual(initialCustomer, customer) && !saved ? (
                <span className="button__text">Tallennettu</span>
              ) : (
                <span className="button__text">Tallenna</span>
              )}
            </button>
          )}
        </SaveCustomer>
      </div>

      <main
        css={`
          width: 100%;
          padding: 60px 10px 80px;
          min-height: 100vh;
          max-width: 1240px;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          color: #3b4a68;
          z-index: 10;
          #saveBar {
            position: fixed;
          }
          .pageHeader {
            display: flex;
            margin-bottom: 30px;
            align-items: center;
            h1 {
              font-weight: 600;
              font-size: 24px;
            }
            .defaultBtn {
              margin-left: 20px;
              &.saveBtn {
                background: #3b4a68;
              }
              &.saveBtn:disabled {
                background: #3b4a68;
                opacity: 0.4;
              }
            }
            .newReport {
              margin-left: auto;
              font-size: 14px;
              padding: 0 15px;
            }
          }
        `}
      >
        <div className="pageHeader">
          <h1>Asiakkaan asetukset</h1>
          <Link
            to="/dashboard/raportit"
            className="newReport button defaultBtn blueOutlines"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
                fill="white"
              />
            </svg>
            Uusi raportti
          </Link>
          <SaveCustomer>
            {({ save, saving, saved }) => (
              <button
                type="button"
                className={`defaultBtn saveBtn${
                  saving || saved ? " button--loading" : ""
                }`}
                disabled={
                  (deepEqual(initialCustomer, customer) && !saved) ||
                  !inputsAreValid
                }
                onClick={e => {
                  e.preventDefault();
                  save(customer);
                }}
              >
                {deepEqual(initialCustomer, customer) && !saved ? (
                  <span className="button__text">Tallennettu</span>
                ) : (
                  <span className="button__text">Tallenna</span>
                )}
              </button>
            )}
          </SaveCustomer>
        </div>
        <BasicInfo companyProfile={customer} setCompanyProfile={setCustomer} />
        <Links
          companyLinks={customer.menu}
          setCompanyLinks={menu => setCustomer({ ...customer, menu })}
        />
        <LoadMittaritGlobal>
          {({ mittaritGlobal }) => (
            <Gauges
              mittaritGlobal={mittaritGlobal}
              gauges={customer.gauges}
              setGauges={gauges => setCustomer({ ...customer, gauges })}
            />
          )}
        </LoadMittaritGlobal>
        <Markkinointitiimi
          team={customer.team || []}
          setTeam={team => setCustomer({ ...customer, team })}
        />
        <ReadPermissions
          readPermissions={customer.readPermissions}
          setReadPermissions={readPermissions =>
            setCustomer({ ...customer, readPermissions })
          }
        />

        <LoadMarkkinointiIndeksi>
          {({ markkinointiIndeksi }) => (
            <MarkkinointiIndexAdmin
              data={customer.markIndex}
              markIndexDescription={customer.markIndexDescription}
              setMarkIndexDescription={markIndexDescription =>
                setCustomer({ ...customer, markIndexDescription })
              }
              globalData={markkinointiIndeksi}
              setMarkIndex={markIndex =>
                setCustomer({ ...customer, markIndex })
              }
            />
          )}
        </LoadMarkkinointiIndeksi>
        <Delete
          customerSlug={customer.slug}
          deleteWholeCustomer={deleteWholeCustomer}
        />
      </main>

      <Helmet>
        <title>{customer.name}</title>
      </Helmet>
    </>
  );
};

export default Customer;
