import { useState } from "react";
import "styled-components/macro";
import styled from "styled-components";
import theme from "../../../../styles/theme";

const IlmoitusAsiakkailleWidget = ({ data }) => {
  const [hideBySessionStorage, setHideBySessionStorage] = useState(
    sessionStorage.getItem("hideIlmoitusAsiakkaille") === "true"
  );

  const handleClose = e => {
    e.preventDefault();
    setHideBySessionStorage(true);
    sessionStorage.setItem("hideIlmoitusAsiakkaille", "true");
  };

  return (
    <>
      {data.isActive && !hideBySessionStorage && (
        <IlmoitusAsiakkailleBox>
          <div />
          <div className="container">
            <p>{data.content}</p>
            <a
              href={data.linkUrl}
              target="_blank"
              className="ctaBtn"
              rel="noreferrer"
            >
              {data.linkText}
              <svg
                width="16"
                height="8"
                viewBox="0 0 16 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.01 3.00008H1C0.45 3.00008 0 3.45008 0 4.00008C0 4.55008 0.45 5.00008 1 5.00008H12.01V6.79008C12.01 7.24008 12.55 7.46008 12.86 7.14008L15.64 4.35008C15.83 4.15008 15.83 3.84008 15.64 3.64008L12.86 0.850078C12.55 0.530078 12.01 0.760078 12.01 1.20008V3.00008Z"
                  fill="#3B4A68"
                />
              </svg>
            </a>
          </div>
          <div>
            <button className="closeBtn" onClick={e => handleClose(e)}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.2496 4.75843C14.9246 4.43343 14.3996 4.43343 14.0746 4.75843L9.99961 8.8251L5.92461 4.7501C5.59961 4.4251 5.07461 4.4251 4.74961 4.7501C4.42461 5.0751 4.42461 5.6001 4.74961 5.9251L8.82461 10.0001L4.74961 14.0751C4.42461 14.4001 4.42461 14.9251 4.74961 15.2501C5.07461 15.5751 5.59961 15.5751 5.92461 15.2501L9.99961 11.1751L14.0746 15.2501C14.3996 15.5751 14.9246 15.5751 15.2496 15.2501C15.5746 14.9251 15.5746 14.4001 15.2496 14.0751L11.1746 10.0001L15.2496 5.9251C15.5663 5.60843 15.5663 5.0751 15.2496 4.75843Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </IlmoitusAsiakkailleBox>
      )}
    </>
  );
};

export default IlmoitusAsiakkailleWidget;

const IlmoitusAsiakkailleBox = styled.div`
  padding: 20px 10px;
  background: #3b4a68;
  box-shadow: 0px 4px 12px rgba(59, 74, 104, 0.5);
  font-family: hero-new, sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div:first-child,
  > div:last-child {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  ${theme.mobile} {
    > div:first-child {
      display: none !important;
    }
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 900px;
    ${theme.mobile} {
      flex-direction: column;
      align-items: flex-start;

      p {
        padding-bottom: 20px;
      }
    }
    p {
      color: #fff;
      margin: 0;
      padding-right: 10px;
    }
  }
  .ctaBtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: hero-new, sans-serif;
    background: #ffffff;
    padding: 0 15px;
    height: 32px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    color: #3b4a68;
    white-space: nowrap;
    svg {
      margin-left: 10px;
    }
  }
  .closeBtn {
    width: 32px;
    height: 32px;
    background: #677289;
    margin-left: auto;
    border-radius: 50%;
    svg {
      display: flex;
      width: 20px;
      min-width: 20px;
    }
  }
`;
