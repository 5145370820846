import React from "react";
import "styled-components/macro";
import { Form } from "./Form";

export const Card = ({ options, children }) => {
  return (
    <div
      css={`
        h2 {
          font-size: 24px;
          line-height: 35px;
          color: #3b4a68;
        }
        .card {
          background: #ffffff;
          padding: 20px;
          box-shadow: 0px 4px 12px rgba(181, 190, 207, 0.5);
          border-radius: 8px;
        }
      `}
    >
      <h2>{options.title}</h2>
      <div className="card">{children}</div>
    </div>
  );
};

export const MenuDropdown = ({ style, children }) => {
  return (
    <div
      style={style}
      css={`
        width: 30px;
        height: 30px;
        position: relative;

        .box {
          display: none;
          position: absolute;
          top: 0;
          right: 0;
          background: #ffffff;
          box-shadow: 0px 4px 12px rgba(59, 74, 104, 0.3);
          border-radius: 4px;
          overflow: hidden;
        }
        .button:hover .box {
          display: block;
        }
        .box .item:hover {
          background: #3b4a68;
        }
        .box {
          button {
            width: 100%;
            text-align: left;
            display: inline-block;
            white-space: nowrap;
            padding: 15px;
            color: #3b4a68;
            background: #fff;
            font-weight: 600;
          }
          button:hover {
            color: #fff;
            background: #3b4a68;
          }
          button:disabled,
          button[disabled] {
            opacity: 0.3;
            cursor: default;
            &:hover {
              color: #3b4a68;
              background: #fff;
            }
          }
        }
      `}
    >
      <div className="button">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
            fill="#3B4A68"
          />
        </svg>
        <div className="box">{children}</div>
      </div>
    </div>
  );
};
export const Panel = ({ options, style, children, controls }) => {
  return (
    <div
      className="panel"
      style={style}
      css={`
        box-shadow: 0px 4px 12px rgba(181, 190, 207, 0.5);
        border-radius: 8px;
        background: #fff;
        background: #f4f5f8;
        position: relative;
        overflow: hidden;
        > div {
          display: flex;
          padding-left: 15px;
          padding-right: 15px;
        }
        .panelHeader {
          height: 64px;
          background: #fff;
          align-items: center;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          border-bottom: 1px solid rgba(181, 190, 207, 0.5);
          h2 {
            font-size: 18px;
            font-weight: 600;
            flex: 1;
          }
        }
        .panelContent {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          padding: 40px 20px 100px;
          margin: 0 auto;
        }
        .defaultBtn {
          margin-top: 10px;
        }
      `}
    >
      {options.title && (
        <div className="panelHeader">
          <h2>{options.title}</h2>
          {typeof controls !== "undefined" && <div>{controls()}</div>}
          <Form></Form>
        </div>
      )}

      <Form>
        <div className="panelContent">{children}</div>
      </Form>
    </div>
  );
};

export const SectionHeader = ({ title, style, children }) => {
  return (
    <div
      style={style}
      css={`
        display: flex;
        margin-bottom: 30px;
        align-items: center;
        h2 {
          font-weight: 600;
          font-size: 24px;
          margin: 0;
        }
        button {
          margin-left: auto;
        }
      `}
    >
      <h2>{title}</h2>
      {children}
    </div>
  );
};
export const Button = ({ icon, style, children, className, onClick }) => {
  const addIcon = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
        fill="white"
      />
    </svg>
  );
  return (
    <button style={style} className={className} onClick={onClick}>
      {icon === "add" && addIcon}
      {children}
    </button>
  );
};

export const EditableList = ({ children }) => {
  return (
    <Form>
      <div className="cardContent">
        <Form>
          <div
            css={`
              display: flex;
              flex-direction: column;
              border-radius: 8px;
              .row {
                display: flex;
                align-items: center;
                padding-top: 8px;
                line-height: 20px;
                > div {
                  box-sizing: border-box;
                  display: flex;
                }
                > div:nth-child(1) {
                  flex-basis: 20px;
                  svg path {
                    fill: #3b4a68;
                    opacity: 0.7;
                  }
                }
                > div:nth-child(2) {
                  flex: 1;
                }
                > div:nth-child(3) {
                  flex-basis: 80px;
                }
                > div:nth-child(4) {
                  flex: 1;
                }
                > div:nth-child(5) {
                  flex: 1;
                }
                > div:nth-child(6) {
                  flex: 1;
                }
                > div:nth-child(7) {
                  flex-basis: 40px;
                }
              }
              > .row:first-child {
                font-weight: 400;
                border-bottom: 1px solid rgba(59, 74, 104, 0.1);
                padding-bottom: 4px;
                span {
                  font-size: 15px;
                }
              }
              .row > div:first-child {
                padding-left: 0;
              }
              .item {
                padding-top: 8px;
                padding-bottom: 8px;
                > div {
                  padding-left: 5px;
                }
                input {
                  width: 100%;
                  color: #3b4a68;
                }
              }
              .remove {
                width: 34px;
                height: 34px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .remove:hover {
                background: rgba(181, 190, 207, 0.3);
                cursor: pointer;
              }
            `}
          >
            {children}
          </div>
        </Form>
      </div>
    </Form>
  );
};
