import React from "react";
import "styled-components/macro";
import { useDropzone } from "react-dropzone";
import { useMutation, gql } from "@apollo/client";
import { UploadedImageKeyToURL } from "../customer/report/components/EditCard";

const UploadAvatar: React.FC<{
  Key: string;
  onChange: (arg0: string | undefined) => void;
}> = ({ Key, onChange }) => {
  const [local, setLocal] = React.useState<
    undefined | { file: File; preview: string }
  >(undefined);
  const [error, setError] = React.useState<undefined | string>(undefined);
  const [uploadFile] = useMutation(
    gql`
      mutation ($customer: String, $name: String!, $type: String!) {
        uploadFile(customer: $customer, name: $name, type: $type) {
          signedURL
          Key
        }
      }
    `
  );
  React.useLayoutEffect(() => {
    if (typeof local === "undefined") return;
    let cancel = false;
    (async () => {
      const { file } = local;
      const {
        data: {
          uploadFile: { signedURL, Key },
        },
      } = await uploadFile({
        variables: {
          name: file.name,
          type: file.type,
        },
      });
      const uploadResponse = await fetch(signedURL, {
        method: "PUT",
        body: file,
      });
      if (cancel) return;
      if (uploadResponse.status !== 200) {
        setLocal(undefined);
        setError(uploadResponse.statusText);
        return;
      }
      onChange(Key);
      setLocal(undefined);
    })();
    return () => {
      cancel = true;
    };
  }, [local, onChange, setLocal, setError, uploadFile]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".jpg", ".jpeg", ".png"],
    },
    onDrop: acceptedFiles => {
      setError(undefined);
      setLocal({
        preview: URL.createObjectURL(acceptedFiles[0]),
        file: acceptedFiles[0],
      });
    },
  });

  return (
    <>
      <div
        {...getRootProps({
          className: `FormDropzone ${
            typeof Key !== "undefined" && Key !== null && "imgUploaded"
          }`,
        })}
      >
        <input {...getInputProps()} />
        {typeof Key === "undefined" ||
          (Key === null && (
            <>
              <svg
                width="59"
                height="59"
                viewBox="0 0 59 59"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M51.6742 12.2915H46.7083V7.32567C46.7083 5.99817 45.6267 4.9165 44.2992 4.9165H44.2254C42.8733 4.9165 41.7917 5.99817 41.7917 7.32567V12.2915H36.8504C35.5229 12.2915 34.4413 13.3732 34.4167 14.7007V14.7744C34.4167 16.1265 35.4983 17.2082 36.8504 17.2082H41.7917V22.1494C41.7917 23.4769 42.8733 24.5832 44.2254 24.5586H44.2992C45.6267 24.5586 46.7083 23.4769 46.7083 22.1494V17.2082H51.6742C53.0017 17.2082 54.0833 16.1265 54.0833 14.799V14.7007C54.0833 13.3732 53.0017 12.2915 51.6742 12.2915ZM39.3333 22.1494V19.6665H36.8504C35.5475 19.6665 34.3183 19.1503 33.3842 18.2407C32.4746 17.3065 31.9583 16.0773 31.9583 14.7007C31.9583 13.8157 32.2042 13.0044 32.6221 12.2915H12.2917C9.5875 12.2915 7.375 14.504 7.375 17.2082V46.7082C7.375 49.4123 9.5875 51.6248 12.2917 51.6248H41.7917C44.4958 51.6248 46.7083 49.4123 46.7083 46.7082V26.3532C45.9708 26.7711 45.135 27.0415 44.2008 27.0415C41.5213 27.0169 39.3333 24.829 39.3333 22.1494ZM39.235 46.7082H14.75C13.7421 46.7082 13.1521 45.5528 13.7667 44.7415L18.6342 38.2761C19.1504 37.5878 20.1583 37.6369 20.65 38.3253L24.5833 44.2498L30.9996 35.6948C31.4913 35.0557 32.45 35.0311 32.9417 35.6703L40.1938 44.7169C40.8329 45.5282 40.2675 46.7082 39.235 46.7082V46.7082Z"
                  fill="#B5BECF"
                />
              </svg>
              <p>Lisää kuva</p>
            </>
          ))}
        {typeof Key !== "undefined" && Key !== null && (
          <>
            <RoundedSquareChildDiv>
              <UploadedImageKeyToURL Key={Key}>
                {({ url }: any) => (
                  <div
                    css={`
                      background: url(${url});
                      background-size: cover;
                      background-position: 50% 50%;
                      background-repeat: no-repeat;
                    `}
                  />
                )}
              </UploadedImageKeyToURL>
            </RoundedSquareChildDiv>
            <button
              className="removeBtn"
              onClick={e => {
                onChange(undefined);
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              Poista kuva
            </button>
          </>
        )}
        {typeof error !== "undefined" && <p>{error}</p>}
      </div>
    </>
  );
};

export const RoundedSquareChildDiv: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <>
      <div
        css={`
          width: 100%;
          max-width: 160px;
          font-size: 0;
          &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
          }
          position: relative;
          border-radius: 50%;
          overflow: hidden;
          > div {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
          }
        `}
      >
        {children}
      </div>
    </>
  );
};

export default UploadAvatar;
