import styled from "styled-components";
import "styled-components/macro";

/* Checkbox, Switch and Radio: https://css-tricks.com/custom-styling-form-inputs-with-modern-css-features/
TO FIX: Select input border none
 */

const Form = styled.form`

  
    .react-datepicker {
      color: #3b4a68;
      font-family: Roboto, sans-serif;
      border: 1px solid rgba(187,193,225,0.6);

      .react-datepicker__triangle::before {
        border-bottom-color: rgba(187,193,225,0.6);
      }
    .react-datepicker__navigation {
      .react-datepicker__navigation-icon {        
        visibility: hidden;      
      }
      .react-datepicker__navigation-icon:before {
        visibility: visible;
        top: 18px;
      }
      
    }
    .react-datepicker__month-container {
      
      .react-datepicker__header  {
        color: #3b4a68;
        background: rgba(181,190,207,0.15);
        border-bottom: 1px solid rgba(187,193,225,0.6);
        .react-datepicker__current-month,.react-datepicker__day-name {
          color: #3b4a68;
        }
      }

      .react-datepicker__month-text {
        width: 4.4rem;
        margin: 4px;
        padding: 6px;
      }
      .react-datepicker__month--selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__day--in-range{
        background-color: #3b4a68;
        color: #fff !important;
      }
      .react-datepicker__day--in-selecting-range {
        background-color: rgba(59,74,104,1);
        color: #fff !important;
      }
    }
  }
  

  // Select
  .select-container {
    
    .select__control {
      border: 1px solid var(--bc, var(--border));

      .select_value-container {        
      }
      .select_indicators {}
    }
    .select__input input {
      border: none !important;
    }
    .select__menu {
      .select__menu-list {
        select__option
      }
    }
  }

  // Dropzone
  .FormDropzone {
    height: 180px;
    background: var(--greyLight);
    border: 2px dashed var(--greySemi);
    opacity: 0.8;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    p {
      color: var(--blueDark);
      opacity: 0.7;
    }
  }

  .FormThumbsContainer {
    display: flex;
    flexWrap: wrap;
    margin-top: 16px;
    align-items: flex-end;
    .FormThumb {
      position: relative;
      display: inline-flex;
      border-radius: 2px;
      border: 1px solid #eaeaea;
      margin: 8px;
      margin-left: 0;
      width: 120px;
      height: 120px;
      padding: 4px;
      box-sizing: border-box;
      .FormThumbInner {        
        display: flex;
        overflow: hidden;
        justify-content: center;
        align-items: center;
      }
      img {
        display: block;
        width: auto;
        height: 100%;
      }
    }
  }


  // Basics

  label {
    margin-bottom: 5px;
    opacity: 0.8;
  }
  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  textarea {
    font-size: 16px;
    font-size: max(16px, 1em);
    font-family: inherit;
    width: 100%;
    padding: 0.5em 0.5em;
    background-color: #fff;
    border: 1px solid var(--bc, var(--border));
    border-radius: 4px;
    &:focus {
      border-color: #3b4a68;
      box-shadow: 0 0 0 1px #3b4a68;
      transition: 50ms box-shadow ease-in-out;
      outline: 3px solid transparent;
    }
    &:disabled {
      --input-border: #ccc;
      background-color: #eee;
      cursor: not-allowed;
    }
  }
  button.primaryBtn {
    margin: 40px auto 0;
    background: #3b4a68;
    box-shadow: 0px 4px 8px rgba(59, 74, 104, 0.2);
    border-radius: 20px;
    color: #fff;
    height: 40px;
    width: 220px;
  }

  @supports (-webkit-appearance: none) or (-moz-appearance: none) {
    input[type="checkbox"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      height: 21px;
      outline: none;
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin: 0;
      cursor: pointer;
      border: 1px solid var(--bc, var(--border));
      background: var(--b, var(--background));
      transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
      &:after {
        content: "";
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        background: #3B4A68;
        transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
          opacity var(--d-o, 0.2s);
      }
      &:checked {
        --b: var(--active);
        --bc: var(--active);
        --d-o: 0.3s;
        --d-t: 0.6s;
        --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
      }
      &:disabled {
        --b: var(--disabled);
        cursor: not-allowed;
        opacity: 0.9;
        &:checked {
          --b: var(--disabled-inner);
          --bc: var(--border);
        }
        & + label {
          cursor: not-allowed;
        }
      }
      &:hover {
        &:not(:checked) {
          &:not(:disabled) {
            --bc: var(--border-hover);
          }
        }
      }
      &:focus {
        box-shadow: 0 0 0 var(--focus);
      }
      &:not(.switch) {
        width: 21px;
        &:after {
          opacity: var(--o, 0);
        }
        &:checked {
          --o: 1;
        }
      }
      & + label {
        font-size: 14px;
        line-height: 21px;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        margin-left: 4px;
      }
    }
    input[type="checkbox"] {
      height: 32px;
      &:not(.switch) {
        border-radius: 16px;
        &:after {
          width: 5px;
          height: 9px;
          border: 2px solid var(--active-inner);
          border-top: 0;
          border-left: 0;
          left: 7px;
          top: 4px;
          transform: rotate(var(--r, 20deg));
        }
        &:checked {
          --r: 43deg;
        }
      }
      &.switch {
        width: 64px;
        border-radius: 16px;
        &:after {
          left: 2px;
          top: 2px;
          border-radius: 50%;
          width: 26px;
          height: 26px;
          background: var(--ab, var(--border));
          transform: translateX(var(--x, 0));
        }
        &:checked {
          --ab: var(--active-inner);
          --x: 30px;
        }
        &:disabled {
          &:not(:checked) {
            &:after {
              opacity: 0.6;
            }
          }
        }
      }
    }
    @supports (-webkit-appearance: none) or (-moz-appearance: none) {   
      input[type="radio"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 21px;
        outline: none;
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin: 0;
        cursor: pointer;
        border: 1px solid var(--bc, var(--border));
        background: var(--b, var(--background));
        transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
        &:after {
          content: "";
          display: block;
          left: 0;
          top: 0;
          position: absolute;
          background: #3B4A68;
          transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
            opacity var(--d-o, 0.2s);
        }
        &:checked {
          --b: #fff;
          --bc: #fff;
          --d-o: 0.3s;
          --d-t: 0.6s;
          --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
        }
        &:disabled {
          --b: var(--disabled);
          cursor: not-allowed;
          opacity: 0.9;
          &:checked {
            --b: var(--disabled-inner);
            --bc: var(--border);
          }
          & + label {
            cursor: not-allowed;
          }
        }
        &:hover {
          &:not(:checked) {
            &:not(:disabled) {
              --bc: rgba(59,74,104,0.8);
            }
          }
        }
        &:focus {
          box-shadow: 0 0 0 var(--focus);
        }
        &:not(.switch) {
          width: 21px;
          &:after {
            opacity: var(--o, 0);
          }
          &:checked {
            --o: 1;
          }
        }
        & + label {
          font-size: 14px;
          line-height: 21px;
          display: inline-block;
          vertical-align: top;
          cursor: pointer;
          margin-left: 4px;
        }
      }
    input[type="radio"] {
      border-radius: 50%;
      &:after {
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background: #3B4A68;
        border: 2px solid #3B4A68;
        opacity: 0;
        transform: scale(var(--s, 0.7));
      }
      &:checked {
        --s: 0.5;
      }
    }
  }
`;

const Button = ({
  id,
  label,
  type,
  value,
  autoFocus,
  onChange,
  placeholder,
}) => {
  return (
    <>
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        value={value}
        type={type}
        autoFocus={autoFocus}
        onChange={onChange}
        placeholder={placeholder}
      ></input>
    </>
  );
};

export { Form, Button };
