import React from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { useLocation } from "react-router-dom";

type SaveButtonComponent<T> = React.FC<{
  children: (arg0: {
    save: (arg0: T) => void;
    saving: boolean;
    saved: boolean;
    error: undefined | string;
  }) => JSX.Element;
}>;

interface User {
  name: string;
  title: string;
  avatarUrl: string;
  email: string;
  company: string;
}

interface Mittari {
  id: string;
  title: string;
  type: string;
  secondary_type: string | null;
  unit: string;
}

export const LoadMittaritGlobal: React.FC<{
  children: (arg0: {
    mittaritGlobal: Array<Mittari>;
    setMittaritGlobal: (arg0: Array<Mittari>) => void;
    isSaving: boolean;
    isSaved: boolean;
  }) => JSX.Element;
}> = ({ children }) => {
  const { error, data, refetch } = useQuery(
    gql`
      query {
        mittarit {
          id
          title
          type
          secondary_type
          unit
        }
      }
    `,
    {
      variables: {},
    }
  );

  const mittaritGlobal = React.useMemo(
    () =>
      typeof data !== "undefined"
        ? data.mittarit.map(({ __typename, ...rest }: any) => rest)
        : undefined,
    [data]
  );
  const [save, saveResult] = useMutation(
    gql`
      mutation ($data: [SaveMittaritInput!]!) {
        saveMittarit(data: $data) {
          id
        }
      }
    `
  );
  const setMittaritGlobal = React.useCallback(
    (data: any) => {
      (async () => {
        try {
          await save({
            variables: {
              data,
            },
          });
        } catch (err) {
          alert((err as any).message);
        }
        refetch();
      })();
    },
    [refetch, save]
  );

  const [isSaved, setIsSaved] = React.useState(false);
  React.useLayoutEffect(() => {
    if (typeof saveResult.data === "undefined") return;
    setIsSaved(true);
    const tid = setTimeout(() => {
      setIsSaved(false);
    }, 2000);
    return () => {
      clearTimeout(tid);
    };
  }, [saveResult.data]);

  if (typeof error !== "undefined")
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  if (typeof mittaritGlobal === "undefined") return <></>;
  return (
    <>
      {children({
        mittaritGlobal,
        setMittaritGlobal,
        isSaving: saveResult.loading,
        isSaved,
      })}
    </>
  );
};

export const LoadMarkkinointiIndeksi: React.FC<{
  children: (arg0: {
    markkinointiIndeksi: any;
    setMarkkinointiIndeksi: (arg0: any) => void;
    isSaving: boolean;
  }) => JSX.Element;
}> = ({ children }) => {
  const { error, data, refetch } = useQuery(
    gql`
      query {
        markkinointiIndeksi
      }
    `,
    {
      variables: {},
    }
  );
  const markkinointiIndeksi = React.useMemo(
    () => (typeof data !== "undefined" ? data.markkinointiIndeksi : undefined),
    [data]
  );
  const [save, saveResult] = useMutation(
    gql`
      mutation ($data: Object!) {
        saveMarkkinointiIndeksi(data: $data)
      }
    `
  );
  const setMarkkinointiIndeksi = React.useCallback(
    (data: any) => {
      (async () => {
        await save({
          variables: {
            data,
          },
        });
        refetch();
      })();
    },
    [refetch, save]
  );
  if (typeof error !== "undefined")
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  if (typeof markkinointiIndeksi === "undefined") return <></>;
  return (
    <>
      {children({
        markkinointiIndeksi,
        setMarkkinointiIndeksi,
        isSaving: saveResult.loading,
      })}
    </>
  );
};

export const LoadGlobaaliIlmoitus: React.FC<{
  children: (arg0: {
    globaaliIlmoitus: any;
    setGlobaaliIlmoitus: (arg0: any) => void;
    isSaving: boolean;
  }) => JSX.Element;
}> = ({ children }) => {
  const { error, data, refetch } = useQuery(
    gql`
      query {
        globaaliIlmoitus
      }
    `,
    {
      variables: {},
    }
  );
  const globaaliIlmoitus = React.useMemo(
    () => (typeof data !== "undefined" ? data.globaaliIlmoitus : undefined),
    [data]
  );
  const [save, saveResult] = useMutation(
    gql`
      mutation ($data: Object!) {
        saveGlobaaliIlmoitus(data: $data)
      }
    `
  );
  const setGlobaaliIlmoitus = React.useCallback(
    (data: any) => {
      (async () => {
        await save({
          variables: {
            data,
          },
        });
        refetch();
      })();
    },
    [refetch, save]
  );
  if (typeof error !== "undefined")
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  if (typeof globaaliIlmoitus === "undefined") return <></>;
  return (
    <>
      {children({
        globaaliIlmoitus,
        setGlobaaliIlmoitus,
        isSaving: saveResult.loading,
      })}
    </>
  );
};

export const LoadProfile: React.FC<{
  children: (arg0: {
    user: User;
    setUser: (arg0: User) => void;
  }) => JSX.Element;
}> = ({ children }) => {
  const { error, data, refetch } = useQuery(
    gql`
      query {
        me {
          name
          title
          phone
          avatarUrl
          email
          company
        }
      }
    `,
    {
      variables: {},
    }
  );
  const user = React.useMemo(
    () => (typeof data !== "undefined" ? data.me : undefined),
    [data]
  );
  const [save] = useMutation(
    gql`
      mutation ($data: Object!) {
        saveMe(data: $data) {
          name
          title
          phone
          avatarUrl
          email
        }
      }
    `
  );
  const setUser = React.useCallback(
    (data: any) => {
      (async () => {
        await save({
          variables: {
            data,
          },
        });
        refetch();
      })();
    },
    [refetch, save]
  );
  if (typeof error !== "undefined")
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  if (typeof user === "undefined") return <></>;
  return <>{children({ user, setUser })}</>;
};

export const LoadAllUsers: React.FC<{
  children: (arg0: {
    allUsers: Array<User>;
    addUser: (arg0: User) => void;
  }) => JSX.Element;
}> = ({ children }) => {
  const { error, data, refetch } = useQuery(
    gql`
      query {
        allUsers {
          name
          title
          avatarUrl
          email
          company
        }
      }
    `,
    {
      variables: {},
    }
  );
  const allUsers = React.useMemo(
    () => (typeof data !== "undefined" ? data.allUsers : undefined),
    [data]
  );
  const [save] = useMutation(
    gql`
      mutation ($data: Object!) {
        addUser(data: $data) {
          name
          email
        }
      }
    `
  );
  const addUser = React.useCallback(
    (data: any) => {
      (async () => {
        await save({
          variables: {
            data,
          },
        });
        refetch();
      })();
    },
    [save, refetch]
  );
  if (typeof error !== "undefined")
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  if (typeof allUsers === "undefined") return <></>;
  return <>{children({ allUsers, addUser })}</>;
};

interface Customer {
  name: string;
  slug: string;
}
export const LoadAllCustomers: React.FC<{
  children: (arg0: {
    allCustomers: Array<Customer>;
    addCustomer: (arg0: Customer) => void;
  }) => JSX.Element;
}> = ({ children }) => {
  const { pathname } = useLocation();
  const { error, data, refetch } = useQuery(
    gql`
      query {
        allCustomers {
          name
          slug
          logoUrl
          logoUrlSigned
          created
          updated
          summaries {
            slug
          }
        }
      }
    `,
    {
      variables: {
        _: pathname,
      },
    }
  );
  const allCustomers = React.useMemo(
    () => (typeof data !== "undefined" ? data.allCustomers : undefined),
    [data]
  );
  const [save] = useMutation(
    gql`
      mutation ($data: Object!) {
        addCustomer(data: $data) {
          name
          slug
        }
      }
    `
  );
  const addCustomer = React.useCallback(
    (data: any) => {
      (async () => {
        await save({
          variables: {
            data,
          },
        });
        refetch();
      })();
    },
    [save, refetch]
  );
  if (typeof error !== "undefined")
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  if (typeof allCustomers === "undefined") return <></>;
  return <>{children({ allCustomers, addCustomer })}</>;
};

export const LoadCustomer: React.FC<{
  slug: string;
  children: (arg0: {
    customer: Customer;
    SaveCustomer: SaveButtonComponent<Customer>;
    deleteWholeCustomer: () => void;
  }) => JSX.Element;
}> = ({ slug, children }) => {
  const { error, data, refetch } = useQuery(
    gql`
      query ($slug: String!) {
        customer(slug: $slug) {
          name
          slug
          logoUrl
          linkMarkkinointistrategia
          created
          updated
          markIndex
          markIndexDescription
          globaaliIlmoitus
          menu {
            url
            icon
            title
          }
          gauges {
            id
            useGlobalGauge
            min
            max
            otsikko
            alateksti
          }
          readPermissions {
            email
          }
          team {
            email
            title
          }
          customerManager
        }
      }
    `,
    {
      variables: { slug },
    }
  );
  const customer = React.useMemo(
    () => (typeof data !== "undefined" ? data.customer : undefined),
    [data]
  );
  const [save, saveResult] = useMutation(
    gql`
      mutation ($slug: String!, $data: Object!) {
        saveCustomer(slug: $slug, data: $data) {
          name
          slug
        }
      }
    `
  );
  const saveCustomer = React.useCallback(
    (data: any) => {
      (async () => {
        await save({
          variables: {
            slug,
            data,
          },
        });
        refetch();
      })();
    },
    [slug, save, refetch]
  );
  const [delete2] = useMutation(
    gql`
      mutation ($customerSlug: String!) {
        deleteCustomer(customerSlug: $customerSlug)
      }
    `
  );
  const deleteWholeCustomer = React.useCallback(() => {
    (async () => {
      await delete2({
        variables: {
          customerSlug: slug,
        },
      });
    })();
  }, [slug, delete2]);
  const [saved, setSaved] = React.useState(false);
  React.useLayoutEffect(() => {
    if (typeof saveResult.data === "undefined") return;
    setSaved(true);
    const tid = setTimeout(() => {
      setSaved(false);
    }, 500);
    return () => {
      clearTimeout(tid);
    };
  }, [saveResult.data]);
  if (typeof error !== "undefined")
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  if (typeof customer === "undefined") return <></>;
  return (
    <>
      {children({
        customer,
        SaveCustomer: ({ children }) => (
          <>
            {children({
              save: saveCustomer,
              saving: saveResult.loading,
              saved,
              error: saveResult.error as any,
            })}
          </>
        ),
        deleteWholeCustomer,
      })}
    </>
  );
};

export const LoadCompleteCustomerObject: React.FC<{
  slug: string;
  children: (arg0: {
    customer: {
      slug: string;
      summaries: Array<{ slug: string; visible: boolean }>;
    };
  }) => JSX.Element;
}> = ({ slug, children }) => {
  const { error, data } = useQuery(
    gql`
      query ($slug: String!) {
        dataV2(customer: $slug)
      }
    `,
    {
      variables: { slug },
    }
  );
  const customer = React.useMemo(
    () => (typeof data !== "undefined" ? data.dataV2 : undefined),
    [data]
  );
  if (typeof error !== "undefined")
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  if (typeof customer === "undefined") return <></>;
  return (
    <>
      {children({
        customer,
      })}
    </>
  );
};

export const LoadAllReports: React.FC<{
  children: (arg0: {
    allReports: Array<Customer>;
    addReport: (arg0: any) => void;
    updateReport: (arg0: any) => void;
    setReportVisibility: (arg0: {
      customerSlug: string;
      reportSlug: string;
      visible: boolean;
    }) => void;
    deleteReport: (arg0: { customerSlug: string; reportSlug: string }) => void;
  }) => JSX.Element;
}> = ({ children }) => {
  const { error, data, refetch } = useQuery(
    gql`
      query {
        allReports {
          company
          name
          updated
          visible
          slug
          companySlug
          type
          span {
            startDate
            endDate
          }
        }
      }
    `,
    {
      variables: {},
    }
  );
  const allReports = React.useMemo(
    () => (typeof data !== "undefined" ? data.allReports : undefined),
    [data]
  );
  const [save] = useMutation(
    gql`
      mutation ($customerSlug: String!, $data: Object!) {
        addReport(customerSlug: $customerSlug, data: $data) {
          title
        }
      }
    `
  );
  const addReport = React.useCallback(
    ({ customerSlug, data }: { customerSlug: string; data: any }) => {
      (async () => {
        await save({
          variables: {
            customerSlug,
            data,
          },
        });
        refetch();
      })();
    },
    [save, refetch]
  );
  const [save2] = useMutation(
    gql`
      mutation (
        $customerSlug: String!
        $reportSlug: String!
        $visible: Boolean!
      ) {
        setReportVisibility(
          customerSlug: $customerSlug
          reportSlug: $reportSlug
          visible: $visible
        )
      }
    `
  );
  const setReportVisibility = React.useCallback(
    async ({
      customerSlug,
      reportSlug,
      visible,
    }: {
      customerSlug: string;
      reportSlug: string;
      visible: boolean;
    }) => {
      await save2({
        variables: {
          customerSlug,
          reportSlug,
          visible,
        },
      });
      await refetch();
    },
    [save2, refetch]
  );
  const [save3] = useMutation(
    gql`
      mutation ($customerSlug: String!, $reportSlug: String!) {
        deleteReport(customerSlug: $customerSlug, reportSlug: $reportSlug)
      }
    `
  );
  const deleteReport = React.useCallback(
    async ({
      customerSlug,
      reportSlug,
    }: {
      customerSlug: string;
      reportSlug: string;
    }) => {
      await save3({
        variables: {
          customerSlug,
          reportSlug,
        },
      });
      refetch();
    },
    [save3, refetch]
  );
  const [save4] = useMutation(
    gql`
      mutation (
        $customerSlug: String!
        $originalReportSlug: String!
        $data: Object!
      ) {
        updateReport(
          customerSlug: $customerSlug
          originalReportSlug: $originalReportSlug
          data: $data
        ) {
          title
        }
      }
    `
  );
  const updateReport = React.useCallback(
    ({
      customerSlug,
      originalReportSlug,
      data,
    }: {
      customerSlug: string;
      originalReportSlug: string;
      data: any;
    }) => {
      (async () => {
        await save4({
          variables: {
            customerSlug,
            originalReportSlug,
            data,
          },
        });
        refetch();
      })();
    },
    [save4, refetch]
  );
  if (typeof error !== "undefined")
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  if (typeof allReports === "undefined") return <></>;
  return (
    <>
      {children({
        allReports,
        addReport,
        updateReport,
        setReportVisibility,
        deleteReport,
      })}
    </>
  );
};

export const LoadAuthor: React.FC<{
  authorEmail: string;
  customerSlug: string;
  children: (arg0: {
    name: string;
    title: string;
    avatarUrl: string | null;
  }) => JSX.Element;
}> = ({ authorEmail, customerSlug, children }) => {
  const { loading, error, data } = useQuery(
    gql`
      query ($customerSlug: String!) {
        allAuthors {
          email
          name
          title
          avatarUrl
        }
        customer(slug: $customerSlug) {
          team {
            email
            title
          }
        }
      }
    `,
    {
      variables: {
        customerSlug,
      },
    }
  );
  if (loading) return <></>;
  if (error)
    return (
      <>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </>
    );
  const author = data.allAuthors.find((v: any) => v.email === authorEmail);
  if (typeof author === "undefined")
    return <>{children({ name: authorEmail, title: "", avatarUrl: null })}</>;
  (data.customer.team as Array<{ email: string; title: string }>).forEach(
    ({ email, title }) => {
      if (title === null) return;
      if (email === authorEmail) author.title = title;
    }
  );
  return <>{children(author)}</>;
};

type Message = { email: string; message: string; createdAt: number };
export const LoadDiscussion: React.FC<{
  customerSlug: string;
  id: string;
  children: (arg0: {
    discussion: Array<Message>;
    setDiscussion: (arg0: Array<Message>) => void;
    isSaving: boolean;
  }) => JSX.Element;
}> = ({ customerSlug, id, children }) => {
  const { error, data, refetch } = useQuery(
    gql`
      query ($customerSlug: String!, $id: String!) {
        discussion(customerSlug: $customerSlug, id: $id)
      }
    `,
    {
      variables: {
        customerSlug,
        id,
      },
    }
  );
  const discussion = React.useMemo(
    () => (typeof data !== "undefined" ? data.discussion : undefined),
    [data]
  );
  const [save, saveResult] = useMutation(
    gql`
      mutation (
        $customerSlug: String!
        $id: String!
        $data: Object!
        $oldData: Object!
      ) {
        saveDiscussion(
          customerSlug: $customerSlug
          id: $id
          data: $data
          oldData: $oldData
        )
      }
    `
  );
  const setDiscussion = React.useCallback(
    (data: any) => {
      (async () => {
        await save({
          variables: {
            customerSlug,
            id,
            data,
            oldData: discussion,
          },
        });
        refetch();
      })();
    },
    [customerSlug, id, discussion, refetch, save]
  );
  if (typeof error !== "undefined")
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  if (typeof discussion === "undefined") return <></>;
  return (
    <>
      {children({
        discussion,
        setDiscussion,
        isSaving: saveResult.loading,
      })}
    </>
  );
};
